import React from "react";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import Typography from "@mui/material/Typography";

const VerificationCheck = ({
  checked,
  onChange,
  label,
  checkboxColor,
  statusCheckBox,
  handleCheckboxChange,
  isDisabledCheckbox,
}) => {
  return (
    <Box
      sx={{
        display: "flex",
        backgroundColor: checked ? "rgba(0, 157, 15, 0.20)" : "#EAEAEA",
      }}
    >
      <Checkbox
        checked={checked}
        disabled={isDisabledCheckbox}
        color="success"
        onChange={(e) => {
          const obj = {
            ...statusCheckBox,
            basicDetailsCheck: e.target.checked,
          };

          if (handleCheckboxChange(obj, "basicDetailsCheck")) return;

          onChange(e);
        }}
        sx={{ color: "#009D0F" }}
        inputProps={{ "aria-label": "controlled" }}
      />
      <Typography gutterBottom variant="h6" component="div" mt="0.5rem">
        {label}
      </Typography>
    </Box>
  );
};

export default VerificationCheck;
