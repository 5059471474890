import React, { useEffect, useRef, useState } from "react";
import filterEmptyPropertiesRecursively from "../utils/filterEmptyPropertiesRecursively";
import { BootstrapTooltip } from "./DetailsSidePanelChart";
import { Box, Button, Typography } from "@mui/material";
import DataTable from "./DataTable";

async function updateInfoWindowContent(marker, infoWindow, map, data) {
  if (data) {
    const filterData = filterEmptyPropertiesRecursively(data);
    console.log(filterData, ":filterdata");
    const showContent = `
      <div style="display: flex; width: 281px; box-sizing: border-box; flex-direction: column; align-items: flex-start; gap: 10px; margin: 0; padding: 0">
        <p style="color: var(--text-dark, #000); font-family: Poppins; font-size: 14px; font-style: normal; font-weight: 400; line-height: normal; margin: 0; padding: 0">
        Full Name : ${filterData?.full_name ?? ""}
        </p>
        <p style="color: var(--text-dark, #000); font-family: Poppins; font-size: 14px; font-style: normal; font-weight: 400; line-height: normal; margin: 0; padding: 0">
        Mobile Number : ${filterData?.phone?.slice(3) ?? ""}
        </p>
    </div>
      `;

    infoWindow.setContent(showContent);
    infoWindow.open(map, marker);
  }
}

const SosMap = ({
  config,
  sosData,
  CustomerSosData,
  handleId,
  toggleCustomerDrawer,
  toggleDetilsDrawer,
  handlePaginationModel,
  tableHeadButtonStatus,
  tableHeadButtonConfig,
}) => {
  const [map, setMap] = useState(null);
  const [markers, setMarkers] = useState({});
  const [infoWindow, setInfoWindow] = useState(null);
  const scrollBottomRef = useRef(null);
  const [newMarker, setNewMarker] = useState(null);
  const createMarker = (id, lat, lng, data) => {
    // Create a new marker
    if (newMarker) {
      console.log("clear marker");
      newMarker?.setMap(null);
    }

    const position = {
      lat,
      lng,
    };
    const marker = new window.google.maps.Marker({
      position,
      map,
      id: id,
    });
    // Common logic for both cases (click, mouseover, mouseout)
    marker.addListener("click", async () => {
      updateInfoWindowContent(marker, infoWindow, map, data);
    });

    marker.addListener("mouseover", () => {
      updateInfoWindowContent(marker, infoWindow, map, data);
      infoWindow.open(map, marker);
    });

    marker.addListener("mouseout", () => {
      infoWindow.close();
    });

    setNewMarker(marker);
    ZoomOnMap(lat, lng);
    scrollToBottom();
  };

  const ZoomOnMap = (lat, lng) => {
    const bounds = new window.google.maps.LatLngBounds();
    bounds.extend(new window.google.maps.LatLng(lat, lng));
    console.log(map);
    map.fitBounds(bounds);
  };

  // Function to scroll down using the ref
  const scrollToBottom = () => {
    scrollBottomRef.current.scrollIntoView({
      behavior: "smooth",
      block: "end",
    });
  };

  // Function to smoothly move the marker to a new location with direction
  const moveMarkerToWithDirection = (marker, newLat, newLng) => {
    const startLat = marker.getPosition().lat();
    const startLng = marker.getPosition().lng();
    // const bearing = calculateBearing(
    //   { lat: startLat, lng: startLng },
    //   { lat: newLat, lng: newLng }
    // );
    const startTime = new Date().getTime();
    const duration = 1000; // Animation duration in milliseconds

    function animate() {
      const currentTime = new Date().getTime();
      const progress = (currentTime - startTime) / duration;

      if (progress < 1) {
        const lat = startLat + progress * (newLat - startLat);
        const lng = startLng + progress * (newLng - startLng);
        marker.setPosition({ lat: lat, lng: lng });

        // Rotate the marker based on the bearing
        marker.setOptions({
          icon: {
            ...marker.getIcon(),
          },
        });

        requestAnimationFrame(animate);
      } else {
        marker.setPosition({ lat: newLat, lng: newLng });
      }
    }

    animate();
  };

  // Initialize map
  const initializeMap = async () => {
    const initialCenter = {
      lat: +process.env.REACT_APP_MAP_CENTER_LAT,
      lng: +process.env.REACT_APP_MAP_CENTER_LNG,
    };
    const mapOptions = {
      center: initialCenter,
      zoom: 12,
    };
    const newMap = await new window.google.maps.Map(
      document.getElementById("sosmap"),
      mapOptions
    );

    return newMap;
  };
  const columns = [
    {
      field: "ride_detail.booking_id",
      headerName: "Booking ID",
      flex: 0.6,
      renderCell: (param) => {
        if (!param?.row?.ride_details?.booking_id) {
          return "--";
        }

        return (
          <Button
            variant="text"
            onClick={(e) => {
              handleId("detailsId", param?.row?.ride_details?.id);
              toggleDetilsDrawer("right", true)(e);
            }}
          >
            <Typography
              sx={{
                color: "#2d5a9b",
                fontFamily: "Proxima Nova",
                fontSize: "13px",
                fontStyle: "normal",
                fontWeight: 400,
                lineHeight: "normal",
                letterSpacing: "0.26px",
                textTransform: "capitalize",
              }}
            >
              {param?.row?.ride_details?.booking_id}
            </Typography>
          </Button>
        );
      },
    },
    {
      field: "ride_detail.rider_details.full_name",
      headerName: "Customer",
      flex: 0.6,
      renderCell: (param) => {
        if (!param?.row?.ride_details?.rider_details?.full_name) {
          return "--";
        }

        return (
          <BootstrapTooltip
            title={param?.row?.ride_details?.rider_details?.full_name}
          >
            <Button
              variant="text"
              sx={{
                display: "flex",
                justifyContent: "flex-start",
              }}
              onClick={(e) => {
                handleId(
                  "customerId",
                  param?.row?.ride_details?.rider_details?.id
                );
                toggleCustomerDrawer("right", true)(e);
              }}
            >
              <Typography
                sx={{
                  color: "#2d5a9b",
                  fontFamily: "Proxima Nova",
                  fontSize: "13px",
                  fontStyle: "normal",
                  fontWeight: 400,
                  lineHeight: "normal",
                  letterSpacing: "0.26px",
                  textTransform: "capitalize",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  display: "block",
                  width: "100%",
                }}
              >
                {param?.row?.ride_details?.rider_details?.full_name}
              </Typography>
            </Button>
          </BootstrapTooltip>
        );
      },
    },
    {
      field: "ride_detail.rider_details.phone",
      headerName: "Phone Number",
      flex: 0.6,
      renderCell: (param) => {
        if (!param?.row?.ride_details?.rider_details?.phone) {
          return "--";
        }

        let phoneNumber = param?.row?.ride_details?.rider_details?.phone;
        let number = phoneNumber.slice(0, 3) + " " + phoneNumber.slice(3);

        return (
          <Typography
            sx={{
              color: "#000",
              fontFamily: "Proxima Nova",
              fontSize: "13px",
              fontStyle: "normal",
              fontWeight: 400,
              lineHeight: "normal",
              letterSpacing: "0.26px",
              textTransform: "capitalize",
            }}
          >
            {number}
          </Typography>
        );
      },
    },
    {
      field: "ride_details.pickup_location",
      headerName: "Pickup Location",
      flex: 1.2,
      renderCell: (param) => {
        if (!param?.row?.ride_details?.pickup_location?.address_text) {
          return "--";
        }

        return (
          <BootstrapTooltip
            title={param?.row?.ride_details?.pickup_location?.address_text}
          >
            <Typography
              sx={{
                color: "#000",
                fontFamily: "Proxima Nova",
                fontSize: "13px",
                fontStyle: "normal",
                fontWeight: 400,
                lineHeight: "normal",
                letterSpacing: "0.26px",
                textTransform: "capitalize",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                display: "block",
                width: "100%",
              }}
            >
              {param?.row?.ride_details?.pickup_location?.address_text}
            </Typography>
          </BootstrapTooltip>
        );
      },
    },
    {
      field: "ride_details.dropoff_location",
      headerName: "DropOff Location",
      flex: 1,
      renderCell: (param) => {
        if (!param?.row?.ride_details?.dropoff_location?.address_text) {
          return "--";
        }

        return (
          <BootstrapTooltip
            title={param?.row?.ride_details?.dropoff_location?.address_text}
          >
            <Typography
              sx={{
                color: "#000",
                fontFamily: "Proxima Nova",
                fontSize: "13px",
                fontStyle: "normal",
                fontWeight: 400,
                lineHeight: "normal",
                letterSpacing: "0.26px",
                textTransform: "capitalize",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                display: "block",
                width: "100%",
              }}
            >
              {param?.row?.ride_details?.dropoff_location?.address_text}
            </Typography>
          </BootstrapTooltip>
        );
      },
    },
    {
      field: "id",
      headerName: "Track Driver",
      renderCell: (param) => {
        return (
          <Button
            variant="text"
            onClick={(e) => {
              let id = param.id;
              const riderData = sosData[id]?.data;
              if (riderData) {
                const lat = riderData.rider_location.latitude;
                const lng = riderData.rider_location.longitude;
                ZoomOnMap(lat, lng);
                scrollToBottom();
              } else {
                const lat = param.row.rider_location.latitude;
                const lng = param.row.rider_location.longitude;
                const riderDetails =
                  param?.row?.ride_details?.rider_details ?? {};
                createMarker(param.id, lat, lng, riderDetails);
              }
            }}
          >
            <Typography
              sx={{
                color: "#2d5a9b",
                fontFamily: "Proxima Nova",
                fontSize: "13px",
                fontStyle: "normal",
                fontWeight: 400,
                lineHeight: "normal",
                letterSpacing: "0.26px",
                textTransform: "capitalize",
              }}
            >
              {"Track"}
            </Typography>
          </Button>
        );
      },
    },
  ];

  /*initialize the map and info window at a mounting face and store them 
   in state. It can persist the map, preventing it from rendering every time. */
  useEffect(() => {
    const checkmap = async () => {
      try {
        const map = await initializeMap();

        if (map) {
          const infoWindow = new window.google.maps.InfoWindow();

          setMap(map);
          setInfoWindow(infoWindow);
        }
      } catch (error) {
        console.log(error);
      }
    };

    checkmap();
  }, []);

  /* here we are getting sosData from redux and mapping the marker on map  */
  useEffect(() => {
    // checking if map exist or not
    if (!map) return;

    /* createOrUpdateMarker is used to check marker is exist alredy
     in map or else we will create and store the marker data to the markers state */
    const createOrUpdateMarker = (data, markers, map, infoWindow) => {
      let checkIsNew = false;
      const position = {
        lat: parseFloat(data?.data?.rider_location?.latitude ?? 0),
        lng: parseFloat(data?.data?.rider_location?.longitude ?? 0),
      };

      let marker = markers[data.data.id];

      if (marker) {
        // Update existing marker

        if (newMarker && marker.id === newMarker.id) {
          setNewMarker(null);
        }
        moveMarkerToWithDirection(marker, position.lat, position.lng);
        console.log(`Marker ${marker.id} is Updated`);
      } else {
        // Create a new marker
        marker = new window.google.maps.Marker({
          position,
          map,
          id: data.data.id,
        });

        checkIsNew = true;

        console.log(`Marker ${marker.id} created`);
      }

      // Common logic for both cases (click, mouseover, mouseout)
      marker.addListener("click", async () => {
        updateInfoWindowContent(
          marker,
          infoWindow,
          map,
          data.data.rider_details
        );
      });

      marker.addListener("mouseover", () => {
        updateInfoWindowContent(
          marker,
          infoWindow,
          map,
          data.data.rider_details
        );
        infoWindow.open(map, marker);
      });

      marker.addListener("mouseout", () => {
        infoWindow.close();
      });

      return { isNew: checkIsNew, marker };
    };

    const updatedMarkers = Object.values(sosData).reduce((acc, data) => {
      let sosData = createOrUpdateMarker(data, markers, map, infoWindow);
      if (sosData.isNew) {
        acc[data.data.id] = sosData.marker;
      } else {
        acc[data.data.id] = sosData.marker;
      }
      return acc;
    }, {});

    Object.entries(markers).forEach((res) => {
      const [id, value] = res;
      if (!sosData[id]) {
        value.setMap(null);
        console.log(`Marker removed for id: ${id}`);
      }
    });
    // Update state with the new markers
    setMarkers({ ...updatedMarkers });
  }, [sosData, map, infoWindow]);

  return (
    <div ref={scrollBottomRef}>
      <Box
        sx={{
          mt: "15px",
          display: "flex",
          gap: "0.25rem",
        }}
      >
        {tableHeadButtonConfig.length > 0 &&
          tableHeadButtonConfig.map((res) => (
            <Button
              key={res.label}
              sx={{
                display: "flex",
                width: "142px",
                padding: "10px",
                justifyContent: "center",
                alignItems: "center",
                gap: "10px",
                marginTop: "25px",
                borderRadius: "10px 10px 0 0",
                border: "1px solid #929EAE",
              }}
              style={{
                background:
                  tableHeadButtonStatus === res.value ? "lightgray" : "#FFF",
              }}
              onClick={() => {
                res.handleAccountStatus(res.value);
              }}
            >
              <Typography
                variant="h4"
                sx={{
                  fontSize: "14px",
                  fontFamily: "Proxima Nova",
                  textAlign: "right",
                  fontWeight: "600",
                  lineHeight: "normal",
                  letterSpacing: "0.28px",
                  textTransform: "capitalize",
                  color: "#000",
                }}
              >
                {res.label}
              </Typography>

              {tableHeadButtonStatus === res.value && res.totalcount > 0 && (
                <Box
                  sx={{
                    display: "inline-flex",
                    padding: "2px 5px",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: "10px",
                    background: "#000",
                  }}
                >
                  <Typography
                    sx={{
                      color: "#fff",
                      textAlign: "right",
                      fontFamily: "Proxima Nov",
                      fontSize: "14px",
                      lineHeight: "normal",
                      fontWeight: "600px",
                      letterSpacing: "0.28px",
                      textTransform: "capitalize",
                    }}
                  >
                    {tableHeadButtonStatus === res.value ? res.totalcount : 0}
                  </Typography>
                </Box>
              )}
            </Button>
          ))}
      </Box>
      <DataTable
        row={CustomerSosData?.data ?? []}
        columns={columns || []}
        paginationModel={CustomerSosData.paginationModal}
        setPaginationModel={handlePaginationModel}
        totalCount={CustomerSosData?.totalCount || 0}
      />

      <div
        id="sosmap"
        style={{ width: "100%", height: "600px", marginTop: "5px" }}
      ></div>
    </div>
  );
};

export default SosMap;
