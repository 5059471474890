import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import axiosInstance from "../utils/axiosConfig";
import { showErrorToast } from "../utils/toastUtils";
import SearchActionComponent from "./reusableComponents/SearchActionComponent";
import SwipeableDrawerComponent from "./SwipeableDrawerComponent ";
import DetailsSidePanel from "./DetailsSidePanel";
import CustomerDetails from "./CustomerDetails";
import { useLocation } from "react-router-dom";
import useDebounce from "../hooks/useDebounce";
import SosMap from "./SosMap";
import { useSelector } from "react-redux";

// main component
const CustomerSos = () => {
  // hooks
  const location = useLocation();

  const config = useSelector(
    (store) => store.config.configValues.google_api_key
  );

  const sos = useSelector((store) => store.sos.dataMap);

  // State
  const [CustomerSosData, setCustomerSosData] = useState({
    data: [],
    totalCount: 0,
    paginationModal: {
      page: 0,
      pageSize: 10,
    },
  });

  const [id, setId] = useState({
    detailsId: "",
    customerId: "",
  });

  const [showDrawer, setShowDrawer] = useState({
    detailsDrawer: {
      right: false,
    },
    customerDrawer: {
      right: false,
    },
  });

  const [search, setSearch] = useState("");

  const debounceSearch = useDebounce(search, 500);

  const [tableHeadButtonStatus, setTableHeadButtonStatus] = useState("All");

  // Funtion

  const handleAccountStatus = (status) => {
    if (status) {
      setTableHeadButtonStatus(status);
    }
  };

  const handleId = (name, value) => {
    setId((prevstate) => ({
      ...prevstate,
      [name]: value,
    }));
  };

  const handlePaginationModel = (paginationState) => {
    setCustomerSosData((prev) => ({
      ...prev,
      paginationModal: paginationState,
    }));
  };

  const toggleDetilsDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setShowDrawer((prev) => ({
      ...prev,
      detailsDrawer: { ...prev.detailsDrawer, [anchor]: open },
    }));
  };

  const toggleCustomerDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setShowDrawer((prev) => ({
      ...prev,
      customerDrawer: { ...prev.customerDrawer, [anchor]: open },
    }));
  };

  // Config
  const tableHeadButtonConfig = [
    {
      label: "All",
      value: "All",
      totalcount: CustomerSosData.totalCount,
      handleAccountStatus,
    },
  ];

  useEffect(() => {
    const buildStringQuery = () => {
      const queryParameter = [];

      if (search && debounceSearch) {
        queryParameter.push(`search=${debounceSearch}`);
      }

      if (location?.state?.sos_id) {
        queryParameter.push(`id=${location?.state?.sos_id}`);
      }

      let query =
        queryParameter.length > 1
          ? queryParameter.join("&")
          : queryParameter.join("");

      return query;
    };

    const getCustomerSos = async () => {
      const query = buildStringQuery();

      const url = `/crm/rider/sos/?page=${
        CustomerSosData?.paginationModal?.page + 1
      }&limit=${CustomerSosData?.paginationModal?.pageSize}&${query}`;
      try {
        let response = await axiosInstance.get(url);

        if (response) {
          const data = response?.data?.data ?? {};
          setCustomerSosData((prev) => ({
            ...prev,
            data: data?.records ?? [],
            totalCount: data?.total_count ?? 0,
          }));
        }
      } catch (error) {
        showErrorToast(error);
      }
    };

    getCustomerSos();
  }, [debounceSearch, location.state, CustomerSosData?.paginationModal]);

  return (
    <Box sx={{ m: "1.5rem 1.5rem" }}>
      <SearchActionComponent
        value={search}
        setState={setSearch}
        require={{ search: true, filter: false }}
      />
      <SwipeableDrawerComponent
        isOpen={showDrawer["detailsDrawer"]["right"]}
        anchor="right"
        onClose={toggleDetilsDrawer("right", false)}
      >
        <DetailsSidePanel
          DId={id?.detailsId ?? ""}
          anchor={"right"}
          role="customer"
          specialFilter={"customer"}
          toggleDrawer={toggleDetilsDrawer}
          setDriverId={(value) => {
            handleId("detailsId", value);
          }}
        />
      </SwipeableDrawerComponent>
      <SwipeableDrawerComponent
        isOpen={showDrawer["customerDrawer"]["right"]}
        anchor="right"
        onClose={toggleCustomerDrawer("right", false)}
      >
        <CustomerDetails
          riderId={id?.customerId ?? ""}
          setRiderId={(value) => {
            handleAccountStatus("customerId", value);
          }}
          toggleDriverDrawer={toggleCustomerDrawer}
          anchor={"right"}
        />
      </SwipeableDrawerComponent>

      <div
        style={{
          marginTop: "20px",
          padding: "25px , 0",
          boxSizing: "border-box",
        }}
      >
        <SosMap
          config={config}
          sosData={sos}
          CustomerSosData={CustomerSosData}
          handleId={handleId}
          toggleCustomerDrawer={toggleCustomerDrawer}
          toggleDetilsDrawer={toggleDetilsDrawer}
          handlePaginationModel={handlePaginationModel}
          tableHeadButtonStatus={tableHeadButtonStatus}
          tableHeadButtonConfig={tableHeadButtonConfig}
        />
      </div>
    </Box>
  );
};

export default CustomerSos;
