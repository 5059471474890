import {
  Box,
  Button,
  IconButton,
  MenuItem,
  Modal,
  Select,
  Stack,
  TextField,
  TextareaAutosize,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import ReusableTimePicker from "./reusableComponents/DateTimePickers/ReusableTimePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers";
import CloseIcon from "@mui/icons-material/Close";
import { showErrorToast, showSuccessToast } from "../utils/toastUtils";
import axiosInstance from "../utils/axiosConfig";

const style = {
  position: "absolute",
  top: "50%",
  display: "flex",
  flexDirection: "column",
  gap: "18px",
  width: "580px",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "#fff",
  boxSizing: "border-box",
  p: 3,
  borderRadius: "12px",
  height: "620px",
};

const getNextSlot = (currentSlot) => {
  const slots = ["MORNING", "AFTERNOON"];
  const currentIndex = slots.indexOf(currentSlot);

  if (currentIndex === -1) {
    // Invalid slot, return default or handle accordingly
    return "MORNING";
  }

  const nextIndex = (currentIndex + 1) % slots.length;
  return slots[nextIndex];
};

const initialSlotData = {
  MORNING: {
    render_name: "Morning",
    // default_capacity: "",
    training_center_time_from: "",
    training_center_time_to: "",
    order: 1,
  },
  AFTERNOON: {
    render_name: "Afternoon",
    // default_capacity: "",
    training_center_time_from: "",
    training_center_time_to: "",
    order: 2,
  },
};

const TrainingCenterModal = ({
  open,
  setOpen,
  handleSubmit,
  headerLabelText,
  submitButtonText,
  activeModalName,
  handleChange,
  trainingCenter = {},
  setTrainingCenter,
}) => {
  const handleClose = () =>
    setOpen((prev) => ({ ...prev, [activeModalName]: false }));

  const [slotOptions, setSlotOptions] = useState([
    { value: "MORNING", label: "Morning", filled: true },
    { value: "AFTERNOON", label: "Afternoon", filled: false },
  ]);

  const [selectedSlot, setSelectedSlot] = useState("");

  const [slotData, setSlotData] = useState(initialSlotData);

  function validateSlots(slotData) {
    for (const [slotName, slotInfo] of Object.entries(slotData)) {
      // Check if both start and end time values exist
      if (
        !slotInfo.training_center_time_from ||
        !slotInfo.training_center_time_to
      ) {
        console.error(`Error: Slot "${slotName}" has missing time values.`);
        return true; // Indicate error and stop further checks
      }

      const startTime = new Date(slotInfo.training_center_time_from);
      const endTime = new Date(slotInfo.training_center_time_to);

      for (const [otherSlotName, otherSlotInfo] of Object.entries(slotData)) {
        if (slotName !== otherSlotName) {
          // Check if other slot also has both time values
          if (
            !otherSlotInfo.training_center_time_from ||
            !otherSlotInfo.training_center_time_to
          ) {
            console.error(
              `Error: Slot "${otherSlotName}" has missing time values.`
            );
            return true; // Indicate error and stop further checks
          }

          const otherStartTime = new Date(
            otherSlotInfo.training_center_time_from
          );
          const otherEndTime = new Date(otherSlotInfo.training_center_time_to);

          // Check for overlap
          if (startTime < otherEndTime && endTime > otherStartTime) {
            return false; // Overlap found
          }
        }
      }
    }

    return true; // No overlaps found
  }

  function validateSlotData(slotData) {
    const requiredProperties = [
      "render_name",
      "training_center_time_from",
      "training_center_time_to",
      "render_name",
      "order",
    ];

    return Object.values(slotData).every((obj) =>
      requiredProperties.every(
        (prop) =>
          obj[prop] !== null && obj[prop] !== undefined && obj[prop] !== ""
      )
    );
  }

  const checkIsObjectIsFilled = (obj) => {
    if (!obj) return;

    return Object.values(obj).every((res) => res);
  };

  const handleSelectedSlot = (e) => {
    const value = e.target.value;

    const isFilled = checkIsObjectIsFilled(slotData[value]);

    if (isFilled) {
      const nextSlot = getNextSlot(value);
      setSlotOptions((prev) => {
        return [...prev].map((res) =>
          res.value === nextSlot ? { ...res, filled: true } : res
        );
      });
    }
    setSelectedSlot(value);
  };
  function formatTimeFromISOString(dateString) {
    const dateObject = new Date(dateString);

    const hours = dateObject.getHours();
    const minutes = dateObject.getMinutes();

    // Format the time in 12-hour format with AM/PM
    const formattedTime = `${hours % 12 || 12}:${
      minutes < 10 ? "0" : ""
    }${minutes}${hours < 12 ? "AM" : "PM"}`;

    return formattedTime;
  }

  const handleFromStartTime = (name, value) => {
    if (!value) return;
    const toTime = slotData[selectedSlot]?.training_center_time_to;

    const isFilled = checkIsObjectIsFilled(slotData[selectedSlot]);
    if (isFilled) {
      const nextSlot = getNextSlot(selectedSlot);
      setSlotOptions((prev) => {
        return [...prev].map((res) =>
          res.value === nextSlot ? { ...res, filled: true } : res
        );
      });
    }

    if (toTime) {
      const fromTime = new Date(value);
      const totime = new Date(toTime);

      if (fromTime >= totime) {
        showErrorToast("To should be greater than From");
        return;
      }
    }

    setSlotData((prev) => ({
      ...prev,
      [selectedSlot]: {
        ...prev[selectedSlot],
        [name]: value,
      },
    }));
  };

  const handleToEndTime = (name, value) => {
    if (!value) return;
    const fromTime = slotData[selectedSlot]?.training_center_time_from;

    const isFilled = checkIsObjectIsFilled(slotData[selectedSlot]);
    if (isFilled) {
      const nextSlot = getNextSlot(selectedSlot);
      setSlotOptions((prev) => {
        return [...prev].map((res) =>
          res.value === nextSlot ? { ...res, filled: true } : res
        );
      });
    }

    if (fromTime) {
      const from = new Date(fromTime);
      const to = new Date(value);

      if (from >= to) {
        showErrorToast("To should be greater than From");
        return;
      }
    }

    setSlotData((prev) => ({
      ...prev,
      [selectedSlot]: {
        ...prev[selectedSlot],
        [name]: value,
      },
    }));
  };

  const createSlot = async () => {
    const url = "/crm/training-center-v2/";

    const isEverythingFilled = () => {
      return Object.entries(slotData).every(([slotName, slotInfo]) => {
        const requiredProperties = [
          "render_name",
          "training_center_time_from",
          "training_center_time_to",
        ];

        return requiredProperties.every((property) => {
          return !!slotInfo[property]; // Check if property exists and has a truthy value
        });
      });
    };

    const isToGreterThenFrom = () => {
      return Object.values(slotData).every((res) => {
        const FromTime = new Date(res["training_center_time_from"]);
        const ToTime = new Date(res["training_center_time_to"]);

        return FromTime <= ToTime;
      });
    };

    if (
      !Object.values(trainingCenter).every(
        (res) => res !== null && res !== undefined && res !== ""
      ) &&
      !validateSlotData(slotData)
    ) {
      showErrorToast("Please fill all the details");
      return;
    }

    if (!isToGreterThenFrom()) {
      showErrorToast("To should be greater than From");
      return;
    }

    if (!validateSlots(slotData)) {
      showErrorToast("Slots are overlapping. Please set them properly.");
      return;
    }

    const slot_name_and_default_capacity = Object.entries(slotData).reduce(
      (acc, [key, obj]) => {
        acc[key.toUpperCase()] = {
          render_name: obj?.render_name ?? "",
          order: obj?.order,
          render_time: `${formatTimeFromISOString(
            obj?.training_center_time_from
          )}-${formatTimeFromISOString(obj?.training_center_time_to)}`,
          start_time: formatTimeFromISOString(obj?.training_center_time_from),
          end_time: formatTimeFromISOString(obj?.training_center_time_to),
        };
        return acc;
      },
      {}
    );
    const body = {
      training_center_name: trainingCenter?.training_center_name,
      training_center_address: trainingCenter?.training_center_address,
      latitude: trainingCenter?.training_center_latitude,
      longitude: trainingCenter?.training_center_longitude,
      center_availability_in_days: trainingCenter?.center_availability_in_days,
      slot_name_and_default_capacity,
    };

    try {
      const response = await axiosInstance.post(url, body);

      setSlotData(initialSlotData);
      setTrainingCenter({
        training_center_name: "",
        training_center_latitude: "",
        training_center_longitude: "",
        training_center_time_from: "",
        training_center_time_to: "",
        training_center_address: "",
        center_availability_in_days: "",
      });
      handleClose();
      showSuccessToast("Slot Created Successfully!");
    } catch (error) {
      showErrorToast(error);
    }
  };

  const flexStyle = {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  };

  const closeIconCss = {
    fill: "#000",
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Box sx={{ ...flexStyle, justifyContent: "space-between" }}>
          <Typography
            sx={{
              fontFamily: "Proxima Nova",
              color: "#000",
              fontSize: "20px",
              fontWeight: "600",
              fontStyle: "normal",
              letterSpacing: "0.4px",
            }}
            variant="h2"
          >
            {headerLabelText}
          </Typography>
          <IconButton onClick={handleClose}>
            <CloseIcon sx={closeIconCss} />
          </IconButton>
        </Box>

        <Box>
          <TextField
            fullWidth
            label="Training Center Name"
            name="training_center_name"
            value={trainingCenter?.training_center_name}
            onChange={handleChange}
          />
        </Box>

        <TextareaAutosize
          style={{
            width: "100%",
            height: "100px",
            boxSizing: "border-box",
            padding: "10px",
            border: "1px solid gray",
            outline: "none",
            resize: "none",
          }}
          placeholder="Training Center Address"
          name="training_center_address"
          value={trainingCenter?.training_center_address}
          onChange={handleChange}
        />

        <Box sx={{ ...flexStyle, gap: "10px" }}>
          <TextField
            fullWidth
            type="number"
            label="Latitude"
            name="training_center_latitude"
            value={trainingCenter?.training_center_latitude}
            onChange={handleChange}
          />
          <TextField
            fullWidth
            type="number"
            label="Longitude"
            name="training_center_longitude"
            value={trainingCenter?.training_center_longitude}
            onChange={handleChange}
          />
        </Box>

        <Stack
          direction="row"
          spacing="auto"
          sx={{
            boxSizing: "border-box",
            width: "580px",
            position: "absolute",
            left: "0",
            bottom: "25px",
            paddingInline: 3,
          }}
        >
          <Button
            variant="outlined"
            onClick={() => {
              handleClose();
            }}
            sx={{
              width: "230px",
              height: "50px",
              boxSizing: "border-box",
              padding: "13px 12px",
              border: "1.5px solid #000",
              color: "#000",
            }}
          >
            Cancel
          </Button>
          <Button
            sx={{
              width: "230px",
              height: "50px",
              boxSizing: "border-box",
              padding: "13px 12px",
              border: "1.5px solid #000",
              background: "#000",
              color: "#fff",
              "&:hover": {
                background: "#000",
              },
            }}
            variant="contained"
            onClick={createSlot}
          >
            {submitButtonText}
          </Button>
        </Stack>

        {slotOptions.length > 0 && (
          <Stack direction={"row"} gap={"10px"}>
            <TextField
              select
              fullWidth
              label="Select Slots"
              value={selectedSlot}
              onChange={handleSelectedSlot}
            >
              {slotOptions.map((res) => (
                <MenuItem
                  key={res.label}
                  value={res.value}
                  disabled={!res.filled}
                >
                  {res.label}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              fullWidth
              label="Center Availability"
              name="center_availability_in_days"
              type="number"
              value={trainingCenter?.center_availability_in_days}
              onChange={handleChange}
            />
          </Stack>
        )}
      </Box>
    </Modal>
  );
};

export default TrainingCenterModal;
