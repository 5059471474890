import { Box, Button, Divider, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";

import { useNavigate, useSearchParams } from "react-router-dom";
import axiosInstance from "../utils/axiosConfig";

import RideDetails from "./RideDetails";
import CustomAccordion from "./reusableComponents/Filter/CustomAccordion";
import ChangeStatusBox from "./ChangeStatusBox";
import DetailsSidePanelChart from "./DetailsSidePanelChart";
import { showErrorToast } from "../utils/toastUtils";
import { useSettings } from "../hooks/useSettings";
import { removeQuery } from "../utils/queryHelpers";

const TicketsSidePanel = ({
  DId = "",
  ticketId = "",
  anchor,
  toggleDrawer,
  specialFilter,
  role = "",
  ticketsRole = "",
  fetchFunction = () => {},
  setDriverId = () => {},
}) => {
  const [sidePanelData, setSidePanelData] = useState({});
  const [riderId, setRiderId] = useState("");

  const [_, setSearchParams] = useSearchParams();
  const [priceBreakupObj, setPriceBreakupObj] = useState({});

  const [ticket, setTicket] = useState([]);

  const navigate = useNavigate();

  const handleNavigate = () => {
    let driverId = sidePanelData?.driver_details?.id ?? "";
    if (driverId) {
      navigate(`/driver/${driverId}`);
    }
  };

  const { formatDate, timezone } = useSettings();

  function convertTimestampToCustomFormat(timestamp) {
    if (!timestamp) {
      return;
    }
    const date = new Date(timestamp);

    const day = date.getUTCDate();
    const month = date.getUTCMonth() + 1; // Month is 0-indexed, so add 1.
    const year = date.getUTCFullYear().toString().slice(-2); // Get the last two digits of the year.

    const hours = date.getUTCHours();
    const minutes = date.getUTCMinutes();

    const ampm = hours >= 12 ? "pm" : "am";
    const formattedHours = hours % 12 || 12; // Convert to 12-hour format.

    const formattedDate = `${day}/${month < 10 ? "0" : ""}${month}/${year}`;
    const formattedTime = `${formattedHours}:${
      minutes < 10 ? "0" : ""
    }${minutes} ${ampm}`;

    return `${formattedDate}, ${formattedTime}`;
  }

  const getTickets = async () => {
    const url = `/crm/${ticketsRole}/ticket/${ticketId}/`;
    try {
      let response = await axiosInstance.get(url);
      setTicket(response?.data?.data ?? []);
    } catch (error) {
      showErrorToast(error);
    }
  };

  // Fetching driver Details
  const getDriverDetails = async () => {
    let url = `/crm/ride/${DId}/`;

    if (specialFilter) {
      url += `?special_filter=${specialFilter}`;
    }
    // passing token in headers

    await axiosInstance
      .get(url)
      .then((res) => {
        setSidePanelData(res?.data?.data ?? {});
        let resData = res?.data?.data ?? {};
        setPriceBreakupObj({
          bookfare: resData?.price_breakup?.booking_fee ?? "",
          baseFare: resData?.price_breakup?.base_fare ?? "",
          distance_fee_per_km:
            resData?.price_breakup?.distance_fee_per_km ?? "",
          ride_time_fee_per_min:
            resData?.price_breakup?.ride_time_fee_per_min ?? "",
          surge_fee: resData?.price_breakup?.surge_fee ?? "",
          coupon_discount_amount:
            resData?.price_breakup?.coupon_discount_amount ?? "",
        });
      })
      .catch((error) => {
        console.error("error while getting driver details by id", error);
      });
  };

  // Fetching Ticket Details

  useEffect(() => {
    if (DId) {
      getDriverDetails();
      getTickets();
    }
  }, [DId]);

  return (
    <Box
      sx={{ padding: "10px 20px", boxSizing: "border-box", height: "100vh" }}
    >
      <Box
        sx={{
          width: "auto",
          margin: "0 auto 15px auto",
          backgroundColor: "#fff",
          height: "70px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography
            sx={{
              color: "#000",
              fontFamily: "Proxima Nova",
              fontStyle: "normal",
              fontWeight: "500",
              lineHeight: "normal",
              letterSpacing: "0.32px",
              fontSize: "16px",
            }}
          >{`Booking ID :${sidePanelData?.booking_id ?? ""}`}</Typography>
          <Button
            sx={{
              width: "28px",
              height: "28px",
              color: "#000",
              flexShrink: "0",
            }}
            onClick={(e) => {
              e.stopPropagation();
              let handleEvent = toggleDrawer("right", false);
              setSidePanelData({});

              handleEvent(e);
            }}
          >
            <CloseIcon />
          </Button>
        </Box>

        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography
            sx={{
              color: "#000",
              fontFamily: "Proxima Nova",
              fontStyle: "normal",
              fontWeight: "500",
              lineHeight: "normal",
              letterSpacing: "0.32px",
              fontSize: "16px",
            }}
          >{`Ticket ID :${ticket?.ticket_id ?? ""}`}</Typography>
        </Box>
        <Typography
          sx={{
            fontFamily: "Proxima Nova",
            fontStyle: "normal",
            fontWeight: "500",
            fontSize: "16px",
            color: "#6F747E",
          }}
        >
          {formatDate(
            sidePanelData?.created_at ?? "",
            timezone,
            "MM/DD/YY, hh:mm a"
          )}
        </Typography>
      </Box>
      <Divider
        sx={{
          width: "auto",
          margin: "10px 10px",
        }}
      />
      <Box
        sx={{
          display: "flex",
          boxSizing: "border-box",
        }}
      >
        <Box
          sx={{
            width: "456px",
            boxSizing: "border-box",
            height: "85dvh",
            overflowY: "scroll",
            scrollbarWidth: "thin",
            scrollbarColor: "transparent transparent", // for Firefox
            "&::-webkit-scrollbar": {
              width: "0px", // width of the scrollbar
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "transparent", // color of the thumb
            },
            "&::-webkit-scrollbar-track": {
              backgroundColor: "transparent", // color of the track
            },
          }}
        >
          <ChangeStatusBox
            status={ticket?.status ?? ""}
            DId={DId}
            role={ticketsRole}
            ticketId={ticketId}
            getTickets={getTickets}
            chartoptions={ticket?.ticket_status_tracker}
            data={ticket?.ticket_status_tracker}
          />
          <CustomAccordion summary={"Ride Details"}>
            <RideDetails
              sidePanelData={sidePanelData}
              handleNavigate={handleNavigate}
              role={role}
              setRiderId={setRiderId}
              priceBreakupObj={priceBreakupObj}
              toggleDrawer={toggleDrawer}
              fetchFunction={fetchFunction}
              riderId={riderId}
              anchor={anchor}
              showRating={false}
              specialFilter={specialFilter}
              DId={DId}
            />
          </CustomAccordion>
        </Box>
        <Box
          sx={{
            width: "500px",
            boxSizing: "border-box",
          }}
        >
          {ticket?.rating?.id && (
            <Box
              sx={{
                width: "414px",
                margin: "10px auto",
                display: "flex",
                flexDirection: "column",
                borderBottom: "1px solid rgba(0, 0, 0, 0.14)",
                alignItems: "flex-start",
                paddingBottom: "10px",
                gap: "19px",
              }}
            >
              {ticket?.rating?.rating && (
                <>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      width: "100%",
                    }}
                  >
                    <Typography
                      sx={{
                        color: "rgba(0, 0, 0, 0.70)",
                        fontFamily: "Proxima Nova",
                        fontSize: "16pxpx",
                        letterSpacing: "0.32px",
                        lineHeight: "normal",
                        fontWeight: "400",
                        textAlign: "start",
                        textTransform: "capitalize",
                      }}
                    >
                      Rating & review
                    </Typography>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "flex-end",
                          alignItems: "center",
                          gap: "8px",
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="15"
                          height="15"
                          viewBox="0 0 15 15"
                          fill="none"
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M6.17521 0.799611C6.50776 0.365328 6.91879 0 7.5 0C8.08121 0 8.49224 0.365328 8.82479 0.79961C9.15155 1.22633 9.49805 1.84799 9.92153 2.60777L10.1717 3.05658C10.4456 3.54787 10.5156 3.65224 10.6035 3.71895C10.6881 3.78316 10.7972 3.81897 11.3298 3.93949L11.8182 4.04999C12.6383 4.23554 13.314 4.38841 13.8061 4.58235C14.3169 4.78366 14.7639 5.08038 14.936 5.63379C15.1063 6.18151 14.9149 6.68541 14.6191 7.15536C14.3317 7.61197 13.8726 8.14881 13.3118 8.80459L12.9807 9.19172C12.62 9.61352 12.5447 9.71673 12.5093 9.83063C12.4732 9.94676 12.4766 10.081 12.5311 10.6433L12.581 11.1583C12.6659 12.0346 12.7351 12.7492 12.7109 13.2934C12.6862 13.8473 12.5604 14.3793 12.1033 14.7264C11.6376 15.0799 11.0957 15.0401 10.5727 14.8902C10.0655 14.7449 9.43319 14.4537 8.66541 14.1001L8.208 13.8895C7.70755 13.6591 7.60041 13.6221 7.5 13.6221C7.39959 13.6221 7.29245 13.6591 6.792 13.8895L6.33459 14.1001C5.56681 14.4537 4.93452 14.7449 4.42733 14.8902C3.90425 15.0401 3.36242 15.0799 2.89673 14.7264C2.43958 14.3793 2.31377 13.8473 2.28909 13.2934C2.26485 12.7492 2.33411 12.0346 2.41904 11.1582L2.46894 10.6433C2.52343 10.081 2.52683 9.94676 2.49072 9.83063C2.4553 9.71673 2.37995 9.61352 2.01927 9.19172L1.68825 8.80461C1.12745 8.14883 0.668352 7.61197 0.380922 7.15536C0.0850999 6.68541 -0.106302 6.18151 0.0640111 5.63379C0.236098 5.08038 0.683099 4.78366 1.1939 4.58235C1.68599 4.38841 2.36168 4.23554 3.18185 4.04999L3.22626 4.03994L3.67021 3.93949C4.20281 3.81897 4.31193 3.78316 4.39651 3.71895C4.48438 3.65224 4.55444 3.54787 4.82829 3.05658L5.07847 2.60776C5.50195 1.84799 5.84845 1.22633 6.17521 0.799611Z"
                            fill="#FFC940"
                          />
                        </svg>
                        <Typography>{`(${ticket?.rating?.rating} Star)`}</Typography>
                      </Box>
                    </Box>
                  </Box>
                  <Typography
                    sx={{
                      color: "#0F1828",
                      width: "414px",
                      fontFamily: "Proxima Nova",
                      fontSize: "16px",
                      lineHeight: "130%",
                      fontStyle: "normal",
                      fontWeight: "400",
                    }}
                  >
                    {ticket?.rating?.review}
                  </Typography>
                </>
              )}
            </Box>
          )}
          <DetailsSidePanelChart
            chartoptions={ticket?.ticket_status_tracker}
            userDetails={
              ticket[
                ticketsRole === "driver" ? "driver_details" : "rider_details"
              ]
            }
            ticket={ticket}
            ticketsRole={ticketsRole}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default TicketsSidePanel;
