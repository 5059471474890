import React from "react";

const FileUploadInput = ({ id, onFileChange }) => {
  const handleChange = (e) => {
    const file = e.target.files[0];
    onFileChange(file);
  };

  return (
    <input
      type="file"
      id={id}
      onChange={handleChange}
      style={{ display: "none" }}
    />
  );
};

export default FileUploadInput;
