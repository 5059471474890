function convertTimestampToCustomFormat(timestamp) {
  if (!timestamp) {
    return "";
  }
  const date = new Date(timestamp);

  const day = date.getUTCDate();
  const month = date.getUTCMonth() + 1; // Month is 0-indexed, so add 1.
  const year = date.getUTCFullYear().toString().slice(-2); // Get the last two digits of the year.

  const hours = date.getUTCHours();
  const minutes = date.getUTCMinutes();

  const ampm = hours >= 12 ? "pm" : "am";
  const formattedHours = hours % 12 || 12; // Convert to 12-hour format.

  const formattedDate = `${day}/${month < 10 ? "0" : ""}${month}/${year}`;
  const formattedTime = `${formattedHours}:${
    minutes < 10 ? "0" : ""
  }${minutes} ${ampm}`;

  return `${formattedDate}, ${formattedTime}`;
}

export default convertTimestampToCustomFormat;
