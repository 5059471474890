import { configureStore, createSlice } from "@reduxjs/toolkit";

const initialState = {
  user: null,
  token: null,
  refreshToken: null,
  is_superuser: false,
  full_name: "",
  profilepic_url: "",
  websocket_room_id: "",
  roles: [],
  role: "",
  opertor_id: "",
  admin_sos_websocket_room_id: null,
  showDrawer : true
};

export const globalSlice = createSlice({
  name: "global",
  initialState,
  reducers: {
    setMode: (state) => {
      console.log(state);
    },
    setShowDrawer : (state , action) => {
      state.showDrawer = action.payload
    } ,
    setLogin: (state, action) => {
      state.user = action.payload.user;
      state.token = action.payload.token;
      state.refreshToken = action.payload.refreshToken;
      state.is_superuser = action.payload.is_superuser;
      state.full_name = action.payload.full_name;
      state.profilepic_url = action.payload.profilepic_url;
      state.websocket_room_id = action.payload.websocket_room_id;
      state.roles = action.payload.roles;
      state.opertor_id = action.payload.opertor_id;
      state.role = action.payload.role;
      state.admin_sos_websocket_room_id =
        action.payload.admin_sos_websocket_room_id;
    },
    setLogout: (state) => {
      state.user = null;
      state.token = null;
      state.refreshToken = null;
      state.refreshToken = null;
      state.is_superuser = "";
      state.opertor_id = null;
      state.full_name = "";
      state.profilepic_url = "";
      state.websocket_room_id = null;
      state.roles = null;
      state.role = null;
      state.admin_sos_websocket_room_id = null;
    },
  },
});

export const { setMode, setLogin, setLogout  , setShowDrawer} = globalSlice.actions;

export default globalSlice.reducer;
