import { MenuItem, Select } from "@mui/base";
import { IconButton, Typography } from "@mui/material";
import { Box, Stack } from "@mui/system";
import React, { useState } from "react";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";

// common div wrapper with width 50%
const EqualWidthDivWrapper = ({ children }) => {
  return <div style={{ width: "50%" }}>{children}</div>;
};

// component for label and input
const InputWithLabel = ({
  type = "text",
  value,
  handleChange,
  name,
  labeltext,
  placeholdertext,
  selectoptions = [],
}) => {
  return (
    <Stack
      sx={{
        gap: "10px",
        mb: "10px",
      }}
    >
      <label
        style={{
          color: "var(--Font-sub-text, #6F747E)",
          fontFamily: "Proxima Nova",
          fontSize: "14px",
          fontStyle: "normal",
          fontWeight: 600,
          lineHeight: "normal",
        }}
        htmlFor=""
      >
        {" "}
        {labeltext}
      </label>
      {type === ("text" || "number") ? (
        <input
          type={type}
          name={name}
          value={value}
          style={{
            width: "auto",
            height: "50px",
            boxSizing: "border-box",
            padding: "10px 10px 10px 16px",
            borderRadius: "8px",
            outline: "none",
            border: "1px solid #E3E7EF",
            background: "#fff",
            "&input::placeholder": {
              color: "rgba(15, 24, 40, 0.20)",
              fontSize: "1px",
              lineHeight: "130%",
              fontFamily: "Proxima Nova",
              fontWeight: 400,
            },
          }}
          placeholder={placeholdertext}
          onChange={(e) => {
            const { name, value } = e.target;

            if (name === "operatorNumber") {
              if (e.target.value.length <= 10) {
                handleChange(name, value);
              }
              return;
            } else {
              handleChange(name, value);
            }
          }}
        />
      ) : (
        selectoptions.length > 0 && (
          <Select
            name={name}
            displayEmpty
            value={value}
            onChange={(e) => {
              const { name, value } = e.target;
              handleChange(name, value);
            }}
            sx={{
              width: "auto",
              height: "50px",
              boxSizing: "border-box",
              padding: "10px 10px 10px 16px",
              borderRadius: "8px",
              color: "#998f9b",
              fontSize: "16px",
              fontWeight: "400",
              lineHeight: "130%",
              outline: "none",
              //   border: "1px solid #E3E7EF",
              background: "#fff",
            }}
          >
            <MenuItem value="" disabled>
              --Select--
            </MenuItem>
            {selectoptions.map(({ label, value }, index) => (
              <MenuItem key={index} value={value}>
                {label}
              </MenuItem>
            ))}
          </Select>
        )
      )}
    </Stack>
  );
};

const OperatorDetailsForm = ({
  passwordEnable = false,
  operatorDetails,
  handleOperatorDetailsChange,
  addressConfig,
  handleAddressChange,
}) => {
  const commonFlexStyle = {
    display: "flex",
    // alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
    gap: "20px",
    boxSizing: "border-box",
    width: "100%",
    mb: "10px",
  };

  const inputStyle = {
    width: "auto",
    height: "50px",
    boxSizing: "border-box",
    padding: "10px 10px 10px 16px",
    borderRadius: "8px",
    outline: "none",
    border: "1px solid #E3E7EF",
    background: "#fff",
    "&input::placeholder": {
      color: "rgba(15, 24, 40, 0.20)",
      fontSize: "1px",
      lineHeight: "130%",
      fontFamily: "Proxima Nova",
      fontWeight: 400,
    },
  };

  const [showPassword, setShowPassword] = useState(false);

  return (
    <Box sx={{ marginTop: "20px" }}>
      <Stack sx={commonFlexStyle}>
        <EqualWidthDivWrapper>
          <InputWithLabel
            labeltext={"Operator Name"}
            placeholdertext={"Enter name"}
            name={"operatorName"}
            value={operatorDetails.operatorName}
            handleChange={handleOperatorDetailsChange}
          />
        </EqualWidthDivWrapper>

        <EqualWidthDivWrapper>
          <InputWithLabel
            labeltext={"Phone"}
            placeholdertext={"Enter phone no"}
            name={"operatorNumber"}
            value={operatorDetails.operatorNumber}
            handleChange={handleOperatorDetailsChange}
          />
        </EqualWidthDivWrapper>
      </Stack>
      <Stack sx={commonFlexStyle}>
        <Stack width={"50%"}>
          <label
            style={{
              color: "var(--Font-sub-text, #6F747E)",
              fontFamily: "Proxima Nova",
              fontSize: "14px",
              fontStyle: "normal",
              fontWeight: 600,
              lineHeight: "normal",
              display: "block",
            }}
          >
            {"Address"}
          </label>

          {addressConfig?.name?.length > 0 &&
            addressConfig.name.map((res, index) => (
              <>
                <input
                  key={addressConfig.name[index]}
                  value={addressConfig.value[addressConfig.name[index]]}
                  type={addressConfig.type[addressConfig.name[index]]}
                  placeholder={addressConfig.placeholder[index]}
                  style={{ ...inputStyle, marginTop: "13.5px" }}
                  name={addressConfig.name[index]}
                  onChange={(e) => {
                    const { name, value } = e.target;
                    handleAddressChange(name, value);
                  }}
                />
              </>
            ))}
        </Stack>

        <Stack width={"50%"} gap={"10px"}>
          <InputWithLabel
            labeltext={"Email"}
            placeholdertext={"Enter email id"}
            name={"operatorEmail"}
            value={operatorDetails.operatorEmail}
            handleChange={handleOperatorDetailsChange}
          />

          {passwordEnable && (
            <Stack
              sx={{
                gap: "10px",
              }}
            >
              <label
                style={{
                  color: "var(--Font-sub-text, #6F747E)",
                  fontFamily: "Proxima Nova",
                  fontSize: "14px",
                  fontStyle: "normal",
                  fontWeight: 600,
                  lineHeight: "normal",
                }}
                htmlFor=""
              >
                {" "}
                {"Password"}
              </label>
              <Box
                sx={{
                  border: "1px solid #E3E7EF",
                  borderRadius: "8px",
                  background: "#fff",
                }}
              >
                <input
                  type={showPassword ? "text" : "password"}
                  name={"password"}
                  value={operatorDetails?.password}
                  style={{
                    width: "90%",
                    height: "50px",
                    boxSizing: "border-box",
                    padding: "10px 10px 10px 16px",
                    borderRadius: "8px",
                    border: "none",
                    outline: "none",
                    "&input::placeholder": {
                      color: "rgba(15, 24, 40, 0.20)",
                      fontSize: "1px",
                      lineHeight: "130%",
                      fontFamily: "Proxima Nova",
                      fontWeight: 400,
                    },
                  }}
                  placeholder={"Enter Password"}
                  onChange={(e) => {
                    const { name, value } = e.target;
                    handleOperatorDetailsChange(name, value);
                  }}
                />

                <IconButton
                  sx={{
                    alignItems: "flex-end",
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                    setShowPassword((prev) => !prev);
                  }}
                >
                  {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                </IconButton>
              </Box>
            </Stack>
          )}
        </Stack>
      </Stack>

      <Stack direction={"row"}></Stack>
    </Box>
  );
};

export default OperatorDetailsForm;
