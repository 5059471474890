import { Box, Typography } from "@mui/material";
import React from "react";

const CustomerAddressCard = ({ name, address }) => {
  return (
    <Box
      sx={{
        display: "flex",
        width: "414px",
        flexDirection: "column",
        alignItems: "flex-start",
        gap: "8px",
      }}
    >
      <Typography
        sx={{
          fontFamily: "Proxima Nova",
          fontSize: "16px",
          fontStyle: "normal",
          fontWeight: "600",
          lineHeight: "normal",
          letterSpacing: "0.32px",
          textTransform: "capitalize",
          color: "#000",
        }}
        variant="h4"
      >
        {name ?? ""}
      </Typography>

      <Typography>{address ?? ""}</Typography>
    </Box>
  );
};

export default CustomerAddressCard;
