import { Box, Button, Divider, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import convertTimestampToCustomFormat from "../utils/convertTimestampToCustomFormat";
import { useNavigate, useSearchParams } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import CustomerDetails from "./CustomerDetails";
import SwipeableDrawerComponent from "./SwipeableDrawerComponent ";
import axiosInstance from "../utils/axiosConfig";
import { showErrorToast, showSuccessToast } from "../utils/toastUtils";
import convertTimestampTo12HourFormat from "../utils/convertTimestampTo12HourFormat";
import { removeQuery } from "../utils/queryHelpers";

const InitiateRefundSidePanel = ({
  toggleDrawer,
  trancationId,
  permissions,
}) => {
  const [searchParams, setSearchParams] = useSearchParams();

  // State to track the starting position of a swipeable component mui
  const [showCustomerDetails, setShowCustomerDetails] = useState({
    right: false,
  });

  const priceBreakFareName = [
    "Booking fee",
    "Base fare",
    "Distance (km)",
    "Ride time (mins)",
    "Surge fee",
    "Discount Coupon",
  ];

  const [riderId, setRiderId] = useState("");

  const navigate = useNavigate();

  //
  const [sidePanelData, setSidePanelData] = useState({});

  const [priceBreakupObj, setPriceBreakupObj] = useState({});

  // ----- funtion ------

  // Mui SlideDrawer Funtion
  const toggleCustomerDrawer = (anchor, open) => (event) => {
    if (riderId) {
      setRiderId("");
    }
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setShowCustomerDetails({ ...showCustomerDetails, [anchor]: open });
  };

  // function fetch side panel Details
  const getSidePanelDetails = async () => {
    let url = `/crm/payment/${trancationId || ""}/`;
    try {
      let response = await axiosInstance(url);

      if (response) {
        setSidePanelData(response?.data?.data || {});
        let resData = response?.data?.data ?? {};
        setPriceBreakupObj({
          bookfare: resData?.price_breakup?.booking_fee ?? "",
          baseFare: resData?.price_breakup?.base_fare ?? "",
          distance_fee_per_km:
            resData?.price_breakup?.distance_fee_per_km ?? "",
          ride_time_fee_per_min:
            resData?.price_breakup?.ride_time_fee_per_min ?? "",
          surge_fee: resData?.price_breakup?.surge_fee ?? "",
          coupon_discount_amount:
            resData?.price_breakup?.coupon_discount_amount ?? "",
        });
      }
    } catch (error) {
      showErrorToast(error);
    }
  };

  // funtion initiate refund
  const initiateRefund = async (trancationId) => {
    let url = `/crm/payment/${trancationId}/`;

    const initiateRefundAmount = {
      refund_amount: sidePanelData.amount ?? "",
    };
    try {
      let response = await axiosInstance.patch(url, initiateRefundAmount);

      if (response) {
        // setSidePanelData(response?.data?.data?.records || []);
        console.log(response, "ressponse");
        showSuccessToast("Refund initiated successfully!");
      }
    } catch (error) {
      showErrorToast(error);
    }
  };

  // -- css ---
  const conatinerStyle = {
    width: "446px",
    height: "1099px",
    background: "#FFF",
    padding: "10px",
    boxSizing: "border-box",
    boxShadow: "-4px 4px 18px 0px rgba(0, 0, 0, 0.07)",
  };

  const DetailsStyleContainer = {
    display: "inline-flex",
    flexDirection: "column",
    alignItems: "flex-start",
    width: "414px",
    margin: "5px auto",
    gap: "11px",
    paddingLeft: "10px",
  };

  const DetailsSideInnerConatiner = {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
  };

  useEffect(() => {
    if (trancationId) {
      getSidePanelDetails();
    }
  }, [trancationId]);

  return (
    <Box sx={conatinerStyle}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            width: "414px",
            margin: "0 auto 15px auto",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography
              sx={{
                color: "#000",
                fontFamily: "Proxima Nova",
                fontStyle: "normal",
                fontWeight: "500",
                lineHeight: "normal",
                letterSpacing: "0.32px",
                fontSize: "16px",
              }}
            >{`Booking ID :${sidePanelData?.booking_id ?? ""}`}</Typography>
            <Button
              sx={{
                width: "28px",
                height: "28px",
                color: "#000",
                flexShrink: "0",
              }}
              onClick={(e) => {
                e.stopPropagation();
                removeQuery("transactionDrawer", setSearchParams)
                removeQuery("id", setSearchParams)
                let handleEvevnt = toggleDrawer("right", false);
                setSidePanelData({});
                handleEvevnt(e);
              }}
            >
              <CloseIcon />
            </Button>
          </Box>
          <Typography
            sx={{
              fontFamily: "Proxima Nova",
              fontStyle: "normal",
              fontWeight: "500",
              fontSize: "16px",
              color: "#6F747E",
            }}
          >
            {convertTimestampToCustomFormat(sidePanelData?.created_at ?? "")}
          </Typography>
        </Box>
        <Divider
          sx={{
            width: "414px",
            margin: "20px auto",
          }}
        />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Divider
            sx={{
              width: "414px",
              margin: "20px auto",
              order: 2,
            }}
          />
          <Box
            sx={{
              ...DetailsStyleContainer,
            }}
          >
            <Box sx={DetailsSideInnerConatiner}>
              <Typography
                sx={{
                  fontFamily: "Proxima Nova",
                  fontStyle: "normal",
                  fontWeight: "400",
                  fontSize: "16px",
                  lineHeight: "normal",
                  letterSpacing: "0.32px",
                  textTransform: "capitalize",
                }}
              >{`Customer details`}</Typography>

              <Button
                sx={{
                  fontFamily: "Proxima Nova",
                  fontStyle: "normal",
                  fontWeight: "500",
                  fontSize: "14px",
                  color: "#043A87",
                  lineHeight: "130%",
                }}
                onClick={(e) => {
                  if (sidePanelData?.rider_details?.id) {
                    setRiderId(sidePanelData?.rider_details?.id ?? "");
                  }
                  toggleCustomerDrawer("right", true)(e);
                }}
              >
                view more details
              </Button>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "flex-start",
                gap: "6px",
              }}
            >
              <Typography
                sx={{
                  fontFamily: " Proxima Nova",
                  fontSize: "18px",
                  fontWeight: "500",
                  lineHeight: "normal",
                  letterSpacing: "0.36px",
                  textTransform: "capitalize",
                  color: "#000",
                }}
              >
                {sidePanelData?.rider_details?.full_name ?? ""}
              </Typography>
              <Typography
                sx={{
                  fontFamily: "Proxima Nova",
                  fontSize: "16px",
                  fontWeight: "400",
                  lineHeight: "normal",
                  letterSpacing: "0.32px",
                  textTransform: "capitalize",
                  color: "rgba(0, 0, 0, 0.70))",
                }}
              >
                {sidePanelData?.rider_details?.phone ?? ""}
              </Typography>
              <Typography
                sx={{
                  fontFamily: "Proxima Nova",
                  fontSize: "16px",
                  fontWeight: "400",
                  lineHeight: "normal",
                  letterSpacing: "0.32px",
                  textTransform: "capitalize",
                  color: "rgba(0, 0, 0, 0.70))",
                }}
              >
                {sidePanelData?.rider_details?.email ?? ""}
              </Typography>
            </Box>
          </Box>
        </Box>

        <Box
          sx={{
            display: "flex",
            padding: "18px 14px",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "flex-start",
            gap: "12px",
            borderRadius: "12px",
            border: `1px solid #DBDBDB`, // Use theme.palette.divider to get the default border color
          }}
        >
          <Typography
            sx={{
              color: "var(--text-dark, #000)",
              fontFamily: "Proxima Nova",
              fontSize: 16,
              fontStyle: "normal",
              fontWeight: 500,
              lineHeight: "normal",
              letterSpacing: "0.32px",
              textTransform: "capitalize",
            }}
          >
            Transaction ID : {sidePanelData?.transaction_id ?? ""}
          </Typography>

          <Stack width={"100%"} gap={"5px"}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <Typography
                sx={{
                  color: "var(--text-70, rgba(0, 0, 0, 0.70))",
                  textAlign: "center",
                  fontFamily: "Proxima Nova",
                  fontSize: 16,
                  fontStyle: "normal",
                  fontWeight: 500,
                  lineHeight: "normal",
                  letterSpacing: "0.32px",
                  textTransform: "capitalize",
                }}
              >
                Paid
              </Typography>
              <Typography
                sx={{
                  color: "var(--font-dark, #0F1828)",
                  textAlign: "right",
                  fontFamily: "Proxima Nova",
                  fontSize: 18,
                  fontStyle: "normal",
                  fontWeight: 500,
                  lineHeight: "22px", // You can use '110%' if needed
                  letterSpacing: "-0.408px",
                }}
              >
                {`₱ ${sidePanelData?.amount ?? ""}`}
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <Typography
                sx={{
                  color: "var(--text-70, rgba(0, 0, 0, 0.70))",
                  textAlign: "center",
                  fontFamily: "Proxima Nova",
                  fontSize: 16,
                  fontStyle: "normal",
                  fontWeight: 500,
                  lineHeight: "normal",
                  letterSpacing: "0.32px",
                  textTransform: "capitalize",
                }}
              >
                Payment Mode
              </Typography>
              <Typography
                sx={{
                  color: "var(--font-dark, #0F1828)",
                  textAlign: "right",
                  fontFamily: "Proxima Nova",
                  fontSize: 18,
                  fontStyle: "normal",
                  fontWeight: 500,
                  lineHeight: "22px", // You can use '110%' if needed
                  letterSpacing: "-0.408px",
                }}
              >
                {sidePanelData?.payment_method ?? ""}
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <Typography
                sx={{
                  color: "var(--text-70, rgba(0, 0, 0, 0.70))",
                  textAlign: "center",
                  fontFamily: "Proxima Nova",
                  fontSize: 16,
                  fontStyle: "normal",
                  fontWeight: 500,
                  lineHeight: "normal",
                  letterSpacing: "0.32px",
                  textTransform: "capitalize",
                }}
              >
                Time
              </Typography>
              <Typography
                sx={{
                  color: "var(--font-dark, #0F1828)",
                  textAlign: "right",
                  fontFamily: "Proxima Nova",
                  fontSize: 18,
                  fontStyle: "normal",
                  fontWeight: 500,
                  lineHeight: "22px", // You can use '110%' if needed
                  letterSpacing: "-0.408px",
                }}
              >
                {convertTimestampTo12HourFormat(
                  sidePanelData?.created_at ?? ""
                )}
              </Typography>
            </Box>
          </Stack>
        </Box>

        <Box
          sx={{
            display: "flex",
            padding: "24px 16px",
            justifyContent: "center",
            alignItems: "center",
            boxSizing: "border-box",
            width: "414px",
            margin: "20px auto",
            background: "rgba(111, 116, 126, 0.09)",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              padding: "0px",
              gap: "18px",
              width: "100%",
              boxSizing: "border-box",
            }}
          >
            <Typography
              sx={{
                color: "#000",
                padding: "0px",
                fontFamily: "Proxima Nova",
                fontSize: "16px",
                textAlign: "start",
                fontWeight: "600",
                letterSpacing: "0.32px",
                textTransform: "capitalize",
              }}
            >
              Fare Breakup
            </Typography>
            <Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  gap: "8px",
                }}
              >
                {Object.entries(priceBreakupObj).length > 0 &&
                  Object.entries(priceBreakupObj).map(([value, res], index) => {
                    return (
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "flex-start",
                          width: "100%",
                        }}
                        key={value + index}
                      >
                        <Typography
                          sx={{
                            color: "rgba(15, 24, 40, 0.70)",
                            fontFamily: "Proxima Nova",
                            fontSize: "16px",
                            width: "215px",
                            letterSpacing: "0.32px",
                            lineHeight: "130%",
                            textAlign: "start",
                            textTransform: "capitalize",
                          }}
                        >
                          {priceBreakFareName[index]}
                        </Typography>

                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "flex-end",
                            alignItems: "center",
                            width: "100%",
                            gap: "3px",
                          }}
                        >
                          <Typography
                            sx={{
                              color: "#0F1828",
                              fontFamily: "Proxima Nova",
                              fontSize: "20px",
                              letterSpacing: "-0.408px",
                              lineHeight: "22px",
                              fontWeight: "400",
                              textAlign: "start",
                              textTransform: "capitalize",
                            }}
                          >
                            {value === "coupon_discount_amount" ? "-₱" : "₱"}
                          </Typography>
                          <Typography
                            sx={{
                              color: "#0F1828",
                              fontFamily: "Proxima Nova",
                              fontSize: "20px",
                              letterSpacing: "-0.408px",
                              lineHeight: "22px",
                              fontWeight: "400",
                              textAlign: "start",
                              textTransform: "capitalize",
                            }}
                          >
                            {res}
                          </Typography>
                        </Box>
                      </Box>
                    );
                  })}
              </Box>
              <Divider
                sx={{ background: "rgba(0, 0, 0, 0.30)", margin: "10px 0" }}
              />
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "flex-start",
                }}
              >
                <Typography
                  sx={{
                    color: "$000",
                    fontFamily: "Proxima Nova",
                    fontSize: "16px",
                    letterSpacing: "0.32px",
                    lineHeight: "130%",
                    fontWeight: "600",
                    textAlign: "start",
                    textTransform: "capitalize",
                  }}
                >
                  Total
                </Typography>

                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                    gap: "3px",
                  }}
                >
                  <Typography
                    sx={{
                      color: "#0F1828",
                      fontFamily: "Proxima Nova",
                      fontSize: "20px",
                      letterSpacing: "-0.408px",
                      lineHeight: "22px",
                      fontWeight: "400",
                      textAlign: "start",
                      textTransform: "capitalize",
                    }}
                  >
                    ₱
                  </Typography>
                  <Typography
                    sx={{
                      color: "#0F1828",
                      fontFamily: "Proxima Nova",
                      fontSize: "20px",
                      letterSpacing: "-0.408px",
                      lineHeight: "22px",
                      fontWeight: "400",
                      textAlign: "start",
                      textTransform: "capitalize",
                    }}
                  >
                    {sidePanelData?.price_breakup?.total_fare ??
                      0 -
                        sidePanelData?.price_breakup?.coupon_discount_amount ??
                      0}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>

        {permissions?.UPDATE && (
          <Button
            variant="contained"
            sx={{
              borderRadius: "4px",
              background: "var(--text-dark, #000)",
              color: "#fff",
              cursor:
                sidePanelData.payment_method === "CASH"
                  ? "not-allowed"
                  : "pointer",
              "&:hover": {
                background: "var(--text-dark, #000)",
              },
            }}
            onClick={() => {
              // initiateRefund(sidePanelData?.id);
            }}
          >
            initiate refund
          </Button>
        )}
      </Box>
      <SwipeableDrawerComponent
        anchor="right"
        isOpen={showCustomerDetails["right"]}
        onClose={toggleCustomerDrawer("right", false)}
      >
        <CustomerDetails
          anchor={"right"}
          riderId={riderId}
          setRiderId={setRiderId}
          toggleDrawer={toggleCustomerDrawer}
          toggleDriverDrawer={toggleDrawer}
          // fetchFunction={fetchFunction}
        />
      </SwipeableDrawerComponent>
    </Box>
  );
};

export default InitiateRefundSidePanel;
