import axiosInstance from "../utils/axiosConfig";
import { showErrorToast, showSuccessToast } from "../utils/toastUtils";


// funtion  to fetch flag data
export const fetchFlag = async (setisLoading,setState , defaultValue = [] ,paginationModel, querys, ) => {
  setisLoading(true)
  const endpoint = !querys  ? `/crm/flag/?page=${paginationModel?.page + 1}&limit=${
      paginationModel?.pageSize
    }` : `/crm/flag/?page=${paginationModel?.page + 1}&limit=${
      paginationModel?.pageSize
    }&{${querys}}`
    try {

        const response = await axiosInstance.get(endpoint);
        setState(prev => (
         { ...prev , data : response?.data?.data?.records ?? defaultValue , total :  response?.data?.data?.total_count ?? 0}
        ))
      
        if (response.status !== 200) {
          throw new Error('Network response was not ok');
        }
        
    } catch (error) {
        showErrorToast(error);
    } finally{
       setisLoading(false);
    }
} 

// function sends a PATCH request to update the flag
export const updateFlagStatus = async (id, flag = false ) => {
    const is_flagged = !flag;

    const endpoint = `/crm/rider/${id}/`;
    const toastMessage = flag ? 'User Successfully unflagged' : 'User Successfully flagged';
  
    try {
      const response = await axiosInstance.patch(endpoint, { is_flagged });
      
      if (response.status !== 200) {
        throw new Error('Network response was not ok');
      }
  
      showSuccessToast(toastMessage);
  
    } catch (error) {
      console.error('There was a problem with the fetch operation:', error);
      showErrorToast('An error occurred while updating the flag status.');
    }
  };
  