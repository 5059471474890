import React, { useEffect, useState } from "react";
import { Menu as MenuIcon, ArrowDropDownOutlined } from "@mui/icons-material";
import FlexBetween from "./FlexBetween";
import {
  AppBar,
  Button,
  Box,
  Typography,
  Toolbar,
  Menu,
  MenuItem,
} from "@mui/material";
import profile from "../assets/user-profile.png";
import NotificationsNoneOutlinedIcon from "@mui/icons-material/NotificationsNoneOutlined";
import Header from "./Header";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setLogout, setShowDrawer } from "../state";
import MuiIconButton from "./uicomponents/MuiIconButton";

const Navbar = ({ isMobileView }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const isOpen = Boolean(anchorEl);
  const handleClick = (event) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const config = useSelector((store) => store.auth);
  const showDrawer = useSelector((store) => store?.auth?.showDrawer);

  useEffect(() => {
    if (!isMobileView) {
      dispatch(setShowDrawer(true));
    } else {
      if (showDrawer) {
        dispatch(setShowDrawer(false));
      }
    }
  }, [isMobileView]);

  return (
    <AppBar
      sx={{
        position: "static",
        background: "none",
        boxShadow: "none",
        backgroundColor: "white",
      }}
    >
      <Toolbar sx={{ justifyContent: "space-between" }}>
        {/* LEFT SIDE */}
        <FlexBetween>
          <Header title={pathname.substring(1)} />
        </FlexBetween>

        {/* RIGHT SIDE */}
        <FlexBetween gap="1.5rem">
          {/* <MuiIconButton>
            <NotificationsNoneOutlinedIcon sx={{ fontSize: "25px" }} />
          </MuiIconButton> */}

          <FlexBetween>
            <div
              className="border"
              style={{
                border: "1px solid var(--text-30, rgba(0, 0, 0, 0.30)",
                borderRadius: "100px",
              }}
            >
              <Button
                onClick={handleClick}
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  textTransform: "none",
                  gap: "1rem",
                }}
              >
                {config.profilepic_url ? (
                  <Box
                    component="img"
                    alt="profile"
                    src={
                      config.profilepic_url === ""
                        ? profile
                        : config.profilepic_url
                    }
                    height="32px"
                    width="32px"
                    borderRadius="50%"
                    sx={{ objectFit: "cover" }}
                  />
                ) : (
                  <Box
                    component="img"
                    alt="profile"
                    src={
                      config.profilepic_url === ""
                        ? profile
                        : config.profilepic_url
                    }
                    height="30px"
                    width="30px"
                    borderRadius="50%"
                    sx={{ objectFit: "cover" }}
                  />
                )}

                <Box textAlign="left">
                  <Typography
                    fontWeight="bold"
                    fontSize="0.85rem"
                    sx={{ color: "black" }}
                  >
                    {config.full_name === ""
                      ? "PeekUp Admin"
                      : config.full_name}
                  </Typography>
                </Box>
                <ArrowDropDownOutlined
                  sx={{ color: "black", fontSize: "25px" }}
                />
              </Button>
              <Menu
                anchorEl={anchorEl}
                open={isOpen}
                onClose={handleClose}
                anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
              >
                <MenuItem
                  onClick={() => {
                    localStorage.clear();
                    dispatch(setLogout());
                  }}
                >
                  Log Out
                </MenuItem>
              </Menu>
            </div>
          </FlexBetween>

          {isMobileView && (
            <MuiIconButton
              onClick={() => {
                dispatch(setShowDrawer(!showDrawer));
              }}
            >
              <MenuIcon
                sx={{
                  color: "#000",
                }}
              />
            </MuiIconButton>
          )}
        </FlexBetween>
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;
