import React from "react";
import FilterWrapper from "./FilterWrapper";
import FilterHeader from "./FilterHeader";
import CustomAccordion from "./CustomAccordion";
import { Button } from "@mui/material";
import FilterClearDoneButton from "./FilterClearDoneButton";
import { useDispatch } from "react-redux";
import DynamicFilter from "./DynamicFilter";
import ReusableCheckboxGroup from "./ReusableCheckboxGroup";
import useQueryParams from "../../../hooks/useQueryParams ";

const CustomFilter = ({
  toggleDrawer = () => {},
  filterOptions,
  handleClear,
}) => {
  const dispatch = useDispatch();
  const { setSearchParams, removeQuery, removeMultipleQueries } =
    useQueryParams();

  if (!filterOptions) return "--";
  return (
    <FilterWrapper>
      <FilterHeader
        onToggleDrawer={(anchor, bool, e) => {
          toggleDrawer(anchor, bool)(e);
        }}
      />

      {filterOptions.length > 0 &&
        filterOptions.map(
          (
            {
              state,
              options,
              setState,
              summryText,
              date,
              time,
              parentDispatch,
              type,
              conditionalState,
              inputlabel,
              inputstate,
              inputSetState,
              ignorefilter,
              filterName,
              inputOptionsFilterName,
            },
            index
          ) =>
            ignorefilter ? null : (
              <CustomAccordion summary={summryText} key={index}>
                <DynamicFilter
                  type={type}
                  filterName={filterName}
                  state={state}
                  dispatch={dispatch}
                  parentDispatch={parentDispatch}
                  setState={setState}
                  options={options}
                  date={date}
                  time={time}
                  conditionalState={conditionalState}
                  inputlabel={inputlabel}
                  inputstate={inputstate}
                  inputOptionsFilterName={inputOptionsFilterName}
                  inputSetState={inputSetState}
                />
                <Button
                  sx={{
                    color: "#000",
                    marginTop: "10px",
                    cursor: "pointer",
                  }}
                  onClick={(e) => {
                    e.stopPropagation();

                    if (type === "input radio") {
                      inputSetState("");
                    }

                    if (filterName || date?.filterName || time?.filterName) {
                      const options = [];

                      if (filterName && state) {
                        options.push({
                          filterName,
                        });
                      }

                      if (inputOptionsFilterName) {
                        options.push({
                          inputOptionsFilterName,
                        });
                      }

                      if (date?.filterName) {
                        options?.push({ date });
                      }

                      if (time?.filterName) {
                        options?.push({ time });
                      }

                      removeMultipleQueries(options);
                    }

                    if (state) {
                      parentDispatch ? dispatch(setState("")) : setState("");

                      if (conditionalState?.state) {
                        conditionalState?.conditionIsDispatch
                          ? dispatch(conditionalState?.setState(""))
                          : conditionalState?.setState("");
                      }
                    }
                    if (date && date?.customDateState) {
                      const dateState = {};

                      Object.entries(date?.customDateState ?? {}).forEach(
                        ([key, _]) => {
                          dateState[key] = null;
                        }
                      );

                      date?.isDispatch
                        ? dispatch(date.setState(dateState))
                        : date.setState(dateState);
                    }

                    if (time && typeof time === "object") {
                      time?.setState((prev) => {
                        const newState = {};
                        Object.entries(prev).forEach(([key, _]) => {
                          newState[key] = null;
                        });
                        return newState;
                      });
                    }
                  }}
                >
                  Clear
                </Button>
              </CustomAccordion>
            )
        )}

      <FilterClearDoneButton
        onDone={toggleDrawer("right", false)}
        onClear={handleClear}
        filterOptions={filterOptions}
      />
    </FilterWrapper>
  );
};

export const SortPanel = ({
  toggleDrawer = () => {},
  sortOptions,
  handleClear = () => {},
  onSortChange = () => {},
  onCLose = () => {},
  setState,
}) => {
  const { removeQuery, removeMultipleQueries } = useQueryParams();
  if (!sortOptions) return "--";

  const handleFilterState = (
    name,
    value,
    checked,
    status,
    isSingleSelect = true
  ) => {
    if (!setState) {
      console.error("Please provide a setter function");
      return;
    }

    if (status === "clear") {
      setState((prev) => ({
        ...prev,
        [name]: isSingleSelect ? "" : [],
      }));
      return;
    }

    if (isSingleSelect) {
      // If single select, the value is a string
      if (checked) {
        setState((prev) => ({
          ...prev,
          [name]: value, // Replace with the selected value (string)
        }));
      } else {
        setState((prev) => ({
          ...prev,
          [name]: "", // Unchecking clears the selection (set to empty string)
        }));
      }
    } else {
      // For multiple select, the value is an array
      if (checked) {
        setState((prev) => ({
          ...prev,
          [name]: [...prev[name], value], // Add the new value to the array
        }));
      } else {
        setState((prev) => ({
          ...prev,
          [name]: prev[name].filter((res) => res !== value), // Remove the unchecked value from the array
        }));
      }
    }
  };

  return (
    <FilterWrapper>
      <FilterHeader
        text="Sort By"
        onToggleDrawer={(anchor, bool, e) => {
          toggleDrawer(anchor, bool)(e);
        }}
      />

      {sortOptions.length > 0 &&
        sortOptions?.map(
          (
            {
              state,
              name,
              options,
              summaryText,
              date,
              time,
              isSingleSelect = true,
              filterName,
            },
            index
          ) => (
            <CustomAccordion summary={summaryText} key={index}>
              <ReusableCheckboxGroup
                value={state}
                name={name}
                onChange={handleFilterState}
                options={options}
                date={date}
                filterName={filterName}
                time={time}
                state={state}
                // isDispatch={isDispatchRadio}
              />
              <Button
                variant="text"
                sx={{
                  color: "#000",
                  marginTop: "10px",
                  cursor: "pointer",
                  fontFamily: "Proxima Nova A",
                  fontSize: "12px",
                  fontWeight: 400,
                  lineHeight: "12px",
                  letterSpacing: "0em",
                  textAlign: "left",
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  if (date && date?.customDateState) {
                    if (
                      date.customDateState.startDate ||
                      date.customDateState.endDate
                    ) {
                      date.setState({ startDate: "", endDate: "" });
                    }
                  }

                  if (time && time.timeState) {
                    if (time.timeState.startTime || time.timeState.endTime) {
                      time.setState({ startTime: "", endTime: "" });
                    }
                  }
                  if (state) {
                    removeQuery(filterName);
                    handleFilterState(
                      name,
                      isSingleSelect ? "" : [],
                      false,
                      "clear"
                    );
                  }
                }}
              >
                Clear
              </Button>
            </CustomAccordion>
          )
        )}

      <FilterClearDoneButton
        onDone={toggleDrawer("right", false)}
        onClear={() => {
          if (setState) {
            removeMultipleQueries(sortOptions);
            setState((prev) => {
              return Object.entries(prev)?.reduce((acc, [key, value]) => {
                acc[key] = typeof value === "string" ? "" : [];
                return acc;
              }, {});
            });
          }
        }}
      />
    </FilterWrapper>
  );
};

export default CustomFilter;
