import React, { useMemo } from "react";
import { Typography, Box } from "@mui/material";

const Header = ({ title }) => {
  function removeHyphensAndUnderscores(inputString) {
    // Use a regular expression to replace hyphens and underscores with an empty string
    return inputString.replace(/[-_]/g, " ");
  }

  const CleanString = useMemo(
    () => removeHyphensAndUnderscores(title),
    [title]
  );
  return (
    <Typography
      variant="h5"
      fontWeight="bold"
      textTransform="capitalize"
      sx={{ color: "black" }}
    >
      {CleanString ?? ""}
    </Typography>
  );
};

export default Header;
