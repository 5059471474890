import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import InfoIcon from "@mui/icons-material/Info";

const PreviewMessage = () => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "flex-start",
        backgroundColor: "#F3F4F7",
        padding: "12px 10px 12px 10px",
        borderRadius: "8px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "flex-start",
          height: "100%",
        }}
      >
        <InfoIcon
          sx={{ marginRight: "10px", fontSize: "15px", color: "#000" }}
        />
      </Box>
      <Typography
        sx={{
          fontSize: "14px",
          fontWeight: "12px",
          lineHeight: "15.6px",
          color: "#000",
          fontFamily: "Proxima Nova",
        }}
      >
        This preview provides a general idea of how your message will appear on
        a mobile device. Actual message rendering will vary depending on the
        device. Test with a real device for actual results.
      </Typography>
    </Box>
  );
};

export default PreviewMessage;
