import { createSlice } from "@reduxjs/toolkit";

// Define initial state
const initialState = {
  dateformat : "MM/DD/YY",
  timezone : "Asia/Manila",
  timeFormat : "hh:mm A"
};

// Create a slice
const dateSlice = createSlice({
  name: "date",
  initialState,
  reducers: {
    setDateFormat: (state, action) => {
      state.dateformat = action.payload;
    },
    setTimezone: (state, action) => {
      state.dateformat = action.payload;
    },
  },
});

// Export the actions and reducer
export const {
  setTimezone,
  setDateFormat
} = dateSlice.actions;
export default dateSlice.reducer;
