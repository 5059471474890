import filterEmptyPropertiesRecursively from "./filterEmptyPropertiesRecursively";

export async function updateInfoWindowContent(marker, infoWindow, map, data) {
    if (data) {
      const filterData = filterEmptyPropertiesRecursively(data);
      const showContent = `
      <div style="display: flex; width: 281px; box-sizing: border-box; flex-direction: column; align-items: flex-start; gap: 10px; margin: 0; padding: 0">
        <p style="color: var(--text-dark, #000); font-family: Poppins; font-size: 14px; font-style: normal; font-weight: 400; line-height: normal; margin: 0; padding: 0">
        Full Name : ${filterData?.full_name ?? ""}
        </p>
        <p style="color: var(--text-dark, #000); font-family: Poppins; font-size: 14px; font-style: normal; font-weight: 400; line-height: normal; margin: 0; padding: 0">
        Mobile Number : ${filterData?.phone.slice(3) ?? ""}
        </p>
        <p style="color: var(--text-dark, #000); font-family: Poppins; font-size: 14px; font-style: normal; font-weight: 400; line-height: normal; margin: 0; padding: 0">
         Cab Type : ${filterData?.cab_type ?? ""}
        </p>
        <p style="color: var(--text-dark, #000); font-family: Poppins; font-size: 14px; font-style: normal; font-weight: 400; line-height: normal; margin: 0; padding: 0">
        Status : ${
          (filterData?.status ?? "") === "VACANT" ? "On Halt" : "Ongoing"
        }
       </p>
       ${
         filterData.status === "OCCUPIED"
           ? `   <p style="color: var(--text-dark, #000); font-family: Poppins; font-size: 14px; font-style: normal; font-weight: 400; line-height: normal; margin: 0; padding: 0">
       Booking Id: ${filterData?.booking_id ?? ""}
      </p>`
           : ""
       }
    </div>
      `;
  
      infoWindow.setContent(showContent);
      infoWindow.open(map, marker);
    }
  }
  
  export async function ShowCustomerDetails(marker, infoWindow, map, data) {
    const filterData = data;
    const showContent = `
    <div style="display: flex; width: 281px; box-sizing: border-box; flex-direction: column; align-items: flex-start; gap: 10px; margin: 0; padding: 0">
      <p style="color: var(--text-dark, #000); font-family: Poppins; font-size: 14px; font-style: normal; font-weight: 400; line-height: normal; margin: 0; padding: 0">
      Full Name : ${filterData?.rider_name ?? ""}
      </p>
      <p style="color: var(--text-dark, #000); font-family: Poppins; font-size: 14px; font-style: normal; font-weight: 400; line-height: normal; margin: 0; padding: 0">
      Mobile Number : ${filterData?.phone.slice(3) ?? ""}
      </p>
  </div>
    `;
  
    infoWindow.setContent(showContent);
    infoWindow.open(map, marker);
  }