import { useEffect, useRef, useState } from "react";

// CSS styles
const inputStyle = {
  width: "100%",
  maxWidth: "472px",
  height: "35px",
  padding: "10px 10px 10px 16px",
  gap: "10px",
  borderRadius: "12px",
  border: "1px solid #E3E7EF",
  outline: "none",
};

const AutocompleteComponent = ({
  suggestions,
  onSelect,
  placeholder,
  multiline,
}) => {
  const [inputValue, setInputValue] = useState("");
  const [filteredSuggestions, setFilteredSuggestions] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [activeSuggestionIndex, setActiveSuggestionIndex] = useState(-1);
  const suggestionsRef = useRef(null);
  const inputRef = useRef(null);
  const isClickingSuggestion = useRef(false);

  const handleInputChange = (event) => {
    event?.stopPropagation();
    const input = event.target.value;
    setInputValue(input);
    onSelect(input);

    const filtered = suggestions?.filter((suggestion) =>
      suggestion.text.toLowerCase().includes(input.toLowerCase())
    );
    setFilteredSuggestions(filtered);
    setActiveSuggestionIndex(-1);
    setShowSuggestions(filtered?.length > 0);
  };

  const handleInputFocus = (event) => {
    event.stopPropagation();
    if (filteredSuggestions?.length > 0) {
      setShowSuggestions(true);
    }
  };

  const handleInputBlur = (event) => {
    event.stopPropagation();
    if (!isClickingSuggestion.current) {
      setShowSuggestions(false);
    }
  };

  const handleSuggestionClick = (suggestion) => {
    setInputValue(suggestion.text);
    onSelect(suggestion.text);
    setFilteredSuggestions([]);
    setShowSuggestions(false);
  };

  const handleKeyDown = (event) => {
    event?.stopPropagation();
    if (event.key === "ArrowDown") {
      event.preventDefault();
      setActiveSuggestionIndex((prevIndex) =>
        prevIndex < filteredSuggestions?.length - 1 ? prevIndex + 1 : prevIndex
      );
    } else if (event.key === "ArrowUp") {
      event.preventDefault();
      setActiveSuggestionIndex((prevIndex) =>
        prevIndex > 0 ? prevIndex - 1 : prevIndex
      );
    } else if (event.key === "Enter") {
      event.preventDefault();
      if (activeSuggestionIndex >= 0) {
        handleSuggestionClick(filteredSuggestions[activeSuggestionIndex]);
      }
    }
  };

  const handleMouseDown = () => {
    isClickingSuggestion.current = true;
  };

  const handleMouseUp = () => {
    isClickingSuggestion.current = false;
  };

  useEffect(() => {
    if (suggestionsRef.current && activeSuggestionIndex !== -1) {
      const activeSuggestion =
        suggestionsRef.current.children[activeSuggestionIndex];
      if (activeSuggestion) {
        activeSuggestion.scrollIntoView({
          block: "nearest",
          inline: "nearest",
        });
      }
    }
  }, [activeSuggestionIndex]);

  useEffect(() => {
    setActiveSuggestionIndex(-1);
  }, [filteredSuggestions]);

  return (
    <div className="autocomplete">
      {multiline ? (
        <textarea
          ref={inputRef}
          value={inputValue}
          onChange={handleInputChange}
          onFocus={handleInputFocus}
          onBlur={handleInputBlur}
          onKeyDown={handleKeyDown}
          placeholder={placeholder}
          style={{
            ...inputStyle,
            height: "90px",
            resize: "none",
          }}
        />
      ) : (
        <input
          ref={inputRef}
          value={inputValue}
          onChange={handleInputChange}
          onFocus={handleInputFocus}
          onBlur={handleInputBlur}
          onKeyDown={handleKeyDown}
          style={inputStyle}
          type="text"
          placeholder={placeholder}
        />
      )}
      {showSuggestions && inputValue && (
        <div className="suggestions-container">
          <div className="arrow"></div>
          <ul
            className="suggestions"
            ref={suggestionsRef}
            onMouseDown={handleMouseDown}
            onMouseUp={handleMouseUp}
          >
            {filteredSuggestions?.map((suggestion, index) => (
              <li
                key={index}
                onClick={(e) => {
                  e.stopPropagation();
                  handleSuggestionClick(suggestion);
                }}
                onMouseEnter={(e) => {
                  e.stopPropagation();
                  setActiveSuggestionIndex(index);
                }}
                className={index === activeSuggestionIndex ? "active" : ""}
              >
                {suggestion.text}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default AutocompleteComponent;
