import { createSlice } from "@reduxjs/toolkit";

const initialRolePermission = {
  allowed_sections: [],
  allowed_permissions: {},
};

const rolePermissionSlice = createSlice({
  name: "rolePermission",
  initialState: initialRolePermission,
  reducers: {
    setRolePermission: (state, action) => {
      return {
        ...state,
        ...action.payload,
      };
    },
  },
});

export const { setRolePermission } = rolePermissionSlice.actions;
export default rolePermissionSlice.reducer;
