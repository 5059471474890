import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import {
  Area,
  AreaChart,
  CartesianGrid,
  ResponsiveContainer,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
} from "recharts";
import { commonXGraphProp, commonXaxisProp } from "../../const/analytics";
import { options } from "../Summary";
import { Brush } from "recharts";

const CustomAreaChart = ({
  legend,
  showShadow = true,
  data,
  options,
  backgroundColor = "#eafaea",
  labels,
  enbaleOfferDistribution,
}) => {
  const areaMap = options?.map((res) => {
    return (
      <Area
        type="linear"
        dataKey={res?.datakey}
        stroke={res?.stroke}
        strokeWidth={res?.strokeWidth}
        fill={`url(#color${res?.backgroundColor})`}
      />
    );
  });

  const defsTag = options?.map((res) => (
    <defs>
      <linearGradient
        id={`color${res?.backgroundColor}`}
        x1="0"
        y1="0"
        x2="0"
        y2="1"
      >
        <stop
          offset="0%"
          stopColor={res?.backgroundColor}
          stopOpacity={0.4}
        ></stop>
        <stop
          offset="100%"
          stopColor={res?.backgroundColor}
          stopOpacity={0.05}
        ></stop>
      </linearGradient>
    </defs>
  ));
  return (
    <Box
      sx={{
        width: "100%",
        height: "350px",
        borderRadius: "8px",
        padding: "10px 0px",
        ...(showShadow && { boxShadow: "0px 4px 20px 0px #0000001A" }),
        backgroundColor: "#fff",
        boxSizing: "border-box",
      }}
    >
      <Stack
        direction={"row"}
        justifyContent={"space-between"}
        padding={"2px 0 16px 2px"}
        // marginLeft={"18px"}
      >
        <Typography
          sx={{
            fontFamily: "Proxima Nova",
            fontSize: "18px",
            paddingLeft: "20px",
            paddingTop: "5px",
            fontWeight: 600,
            lineHeight: "26px",
            letterSpacing: "-0.02em",
            textAlign: "left",
          }}
        >
          {legend}
        </Typography>
      </Stack>
      <ResponsiveContainer width="100%" height={"100%"}>
        <AreaChart data={data}>
          <CartesianGrid
            strokeDasharray="3 3"
            vertical={false}
            horizontal={false}
          />
          {defsTag}

          <XAxis
            dataKey="date"
            // axisLine={false}
            tickLine={false}
            interval={0}
            {...commonXaxisProp}
          />
          <YAxis
            tick={{ stroke: "#000" }}
            fontFamily="Proxima Nova"
            fontSize={"11px"}
            fontWeight={500}
            letterSpacing={"1px"}
            axisLine={false}
            tickLine={false}
          />
          {areaMap}

          <Brush
            dataKey="date"
            padding={"10px 0px 0px 0px"}
            height={28}
            stroke="#8884d8"
          />

          <Legend
            layout="vertical"
            // verticalAlign="top"
            // align="right"
            wrapperStyle={{
              top: "-35px",
              gap: "12px",
              right: "5px",
            }}
          />
          <Tooltip />
        </AreaChart>
      </ResponsiveContainer>
    </Box>
  );
};

export default CustomAreaChart;
