import {
  Avatar,
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import ClearIcon from "@mui/icons-material/Clear";
import BlockIcon from "@mui/icons-material/Block";
import chevronRightIcon from "../assets/svg/rightArrow.svg";
import axiosInstance from "../utils/axiosConfig";
import filterEmptyPropertiesRecursively from "../utils/filterEmptyPropertiesRecursively";
import { showErrorToast, showSuccessToast } from "../utils/toastUtils";
import CustomConfirmationDialog from "./CustomConfirmationDialog";
import { toast } from "react-toastify";
import CustomerAddressCard from "./CustomerAddressCard";
import { useNavigate, useSearchParams } from "react-router-dom";
import usePermission from "../hooks/usePermission";
import { useSettings } from "../hooks/useSettings";
import { removeQuery } from "../utils/queryHelpers";

const updateInitialState = {
  full_name: "",
  gender: "",
  dob: "",
};

const detailsInitialState = {
  fullName: "",
  gender: "",
  email: "",
  phoneNumber: "",
  DOB: "",
  created_at: "",
  cancelled_trips_count: 0,
  total_trips_count: 0,
  total_tickets_count: 0,
};

const commonButtonStyles = {
  width: "25px",
  height: "25px",
  color: "#465466",
  mr: "10px",
};

// Component for Edit button
const EditButton = ({ setIsEditable, permissions, isEditable }) =>
  permissions?.UPDATE &&
  !isEditable && (
    <IconButton
      sx={commonButtonStyles}
      onClick={() => setIsEditable((prev) => !prev)}
    >
      <EditIcon />
    </IconButton>
  );

// Component for Block button
const BlockButton = ({
  setAccountStatus,
  setBlockModel,
  isBlocked,
  permissions,
}) =>
  permissions?.UPDATE && (
    <Button
      sx={commonButtonStyles}
      variant="text"
      onClick={(e) => {
        e.stopPropagation();
        setAccountStatus(isBlocked ? "ACTIVE" : "BLOCKED");
        setBlockModel(true);
      }}
    >
      <Stack direction="row" alignItems="center" gap="4px">
        <BlockIcon />
        <Typography color={"#000"}>
          {isBlocked ? "Unblock" : "Block"}
        </Typography>
      </Stack>
    </Button>
  );

// Component for Delete button
const DeleteButton = ({ setOpen, permissions }) =>
  permissions?.DELETE && (
    <IconButton
      sx={commonButtonStyles}
      onClick={(e) => {
        e.stopPropagation();
        setOpen(true);
      }}
    >
      <DeleteIcon />
    </IconButton>
  );

const InfoCard = ({ value, label, onClick }) => {
  const rightArrowIcon = (
    <img
      style={{
        width: "8px",
        height: "8px",
      }}
      src={chevronRightIcon}
      alt="right arrow icon"
    />
  );

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "133px",
        padding: "8px",
        borderRadius: "4px",
        gap: "5px",
        border: "1px solid #B3B3B3",
        background: "#FFF",
        textAlign: "center", // Ensures text alignment within the box
      }}
    >
      <Typography
        variant="h5"
        sx={{
          color: "#000",
          fontFamily: "Proxima Nov",
          fontSize: "20px",
          fontWeight: "600",
          lineHeight: "130%",
        }}
      >
        {value}
      </Typography>
      <Button
        sx={{
          padding: "0",
          textTransform: "none",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          minHeight: "24px",
        }}
        onClick={() => {
          if (value === 0 || !value) return;
          onClick();
        }}
      >
        <Typography
          sx={{
            color: "#000",
            fontFamily: "Proxima Nov",
            fontSize: "14px",
            fontWeight: "500",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            lineHeight: "17.05px",
          }}
        >
          {label}
          {rightArrowIcon}
        </Typography>
      </Button>
    </Box>
  );
};

const CustomerDetails = ({
  anchor,
  toggleDrawer,
  riderId = "",
 fetchFunction = () => {},
  setRiderId = () => {},
  toggleDriverDrawer = () => {},
}) => {
  const [isEditable, setIsEditable] = useState(false);
  const navigate = useNavigate();
  const permissions = usePermission("/customer");
  const [Address, setAdress] = useState({
    houseNumber: "",
    barangay: "",
    city: "",
    postalCode: "",
  });

  const [favouritePlace, setFavouratePlace] = useState([]);

  const [updateDetails, setUpdateDetails] = useState();

  // Conformation Model State
  const [open, setOpen] = useState(false);
  const [blockModel, setBlockModel] = useState(false);

  const [details, setDetails] = useState({
    fullName: "",
    gender: "",
    email: "",
    nickName: "",
    phoneNumber: "",
    DOB: "",
    createdAt: "",
    cancelled_trips_count: 0,
    total_trips_count: 0,
    total_tickets_count: 0,
    profile_pic: "",
  });

  const [gender, setGender] = useState({ gender: "" });

  const [accountStatus, setAccountStatus] = useState("");

  const handleDetailsChange = (e) => {
    const { name, value } = e.target;
    let Name = name === "DOB" ? "dob" : name;
    setUpdateDetails((prev) => ({ prev, [Name]: value }));
    setDetails((prev) => ({ ...prev, [name]: value }));
  };

  const { dateformat, timezone, formatDate } = useSettings();

  const [isBlocked, setisBlocked] = useState(false);

  const totalCardsData = [
    {
      value: details?.total_trips_count || 0,
      label: "Total Created Trips",
      onClick: () => {
        handleNavigate("/customer-trips", {
          state: { rider_id: details.id },
        });
      },
    },
    {
      value: details?.total_tickets_count || 0,
      label: "Total Created Tickets",
      onClick: () => {
        handleNavigate("/customer-tickets", {
          state: { id: details.id },
        });
      },
    },
    {
      value: details?.cancelled_trips_count || 0,
      label: "Total Cancelled Trips By Rider",
      onClick: () => {
        handleNavigate("/customer-trips", {
          state: { rider_id: details.id, status: "CANCELLED" },
        });
      },
    },
  ];

  const todayCardsData = [
    {
      value: details?.today_created_trips_count || 0,
      label: "Today Created Trips",
      onClick: () => {
        handleNavigate("/customer-trips", {
          state: { rider_id: details.id, range: "today" },
        });
      },
    },
    {
      value: details?.today_created_tickets_count || 0,
      label: "Today Created Tickets",
      onClick: () => {
        handleNavigate("/customer-tickets", {
          state: { id: details.id, range: "today" },
        });
      },
    },
    {
      value: details?.today_cancelled_trips_count || 0,
      label: "Today Cancelled Trips By Rider",
      onClick: () => {
        handleNavigate("/customer-trips", {
          state: {
            rider_id: details.id,
            status: "CANCELLED",
            range: "today",
          },
        });
      },
    },
  ];

  const handleAddressChanges = (e) => {
    const { name, value } = e.target;
    setAdress((prev) => ({ prev, [name]: value }));
  };
  // Function To Fetch Customer Details
  const getCustomerDetails = async () => {
    if (!riderId) {
      return;
    }
    try {
      axiosInstance.get(`/crm/rider/${riderId}/`).then((res) => {
        setDetails({
          id: res?.data?.data?.id,
          fullName: res?.data?.data?.full_name ?? "",
          email: res?.data?.data?.email ?? "",
          gender: res?.data?.data?.gender ?? "",
          phoneNumber: res?.data?.data?.phone ?? "",
          nickName: res?.data?.nickName ?? "",
          DOB: res?.data?.data?.dob ?? "",
          createdAt: formatDate(
            res?.data?.data?.created_at,
            timezone,
            dateformat
          ),
          cancelled_trips_count: res?.data?.data?.cancelled_trips_count ?? 0,
          total_trips_count: res?.data?.data?.total_trips_count ?? 0,
          total_tickets_count: res?.data?.data?.total_tickets_count ?? 0,
          profile_pic: res?.data?.data?.profile_pic_url ?? "",
        });

        const checkUserIsBlock =
          (res?.data?.data?.account_status ?? "") === "BLOCKED" ? true : false;
        setisBlocked(checkUserIsBlock);

        setGender((prev) => ({
          ...prev,
          gender: res?.data?.data?.gender ?? "",
        }));

        setFavouratePlace(res?.data?.data?.favourite_place ?? []);
      });
    } catch (error) {
      console.log(error);
    }
  };
  const [searchParams, setSearchParams] = useSearchParams();
  // Function To Close Customer Side Panel
  const handleClear = (e) => {
    // once we clear all data should reset
    setDetails(detailsInitialState);
    setUpdateDetails(updateInitialState);
    setAccountStatus("");
    setRiderId("");

    // The toggleDrawer Funtion Will Return Callback Funtion
    removeQuery("customerdrawer", setSearchParams);
    removeQuery("id", setSearchParams);
    let callback = toggleDrawer(anchor, false);
    // require to pass event on CallBack
    callback(e);
  };

  // Created Function to Handle Navigate
  const handleNavigate = (path, state) => {
    if (!path) return;

    navigate(path, state);
  };

  // Funtion To Block Rider By Id
  const handleBlock = () => {
    let updateData = filterEmptyPropertiesRecursively({
      account_status: isBlocked ? "ACTIVE" : "BLOCKED",
    });
    try {
      axiosInstance.patch(`/crm/rider/${riderId}/`, updateData).then((_) => {
        getCustomerDetails();
        fetchFunction();
        toast.success(
          `The account has been successfully ${
            isBlocked ? "unblocked" : "blocked"
          }!`
        );
      });
    } catch (error) {
      console.log(error, "Error while updating customer");
    }
  };

  // Function To update The Rider Details
  const updateCustomerDetails = async () => {
    let updateData = filterEmptyPropertiesRecursively({
      full_name: updateDetails?.fullName ?? "",
      gender: gender?.gender ?? "",
      dob: updateDetails?.dob ?? "",
      account_status: accountStatus || "",
    });
    try {
      axiosInstance.patch(`/crm/rider/${riderId}/`, updateData).then((res) => {
        getCustomerDetails();
        setIsEditable((prev) => !prev);
        fetchFunction();
        if (accountStatus) {
          toast.success("The account has been successfully blocked!");
        } else {
          toast.success("Update Successful");
        }
      });
    } catch (error) {
      console.log(error, "Error while updating customer");
    }
  };

  // Funtion To Delete The Rider By ID

  const DeleteRiderById = async (e) => {
    try {
      await axiosInstance.delete(`/crm/rider/${riderId}/`).then((_) => {
        showSuccessToast("rider account has been deleted successfully!");
        // The toggleDrawer Funtion Will Return Callback Funtion
        let callback = toggleDrawer(anchor, false);
        // require to pass event on CallBack
        callback(e);

        let toggleCallBack = toggleDriverDrawer(anchor, false);
        if (toggleCallBack) {
          toggleCallBack(e);
        }

        fetchFunction();
      });
    } catch (error) {
      showErrorToast(error);
      console.log("Error while Delete Rider by id", error);
    }
  };

  useEffect(() => {
    // Fetching Customer Details Data at mount phase
    getCustomerDetails();

    return () => {};
  }, [riderId]);

  return (
    <>
      <Box
        sx={{
          width: "470px",
          height: "100vh",
          overflowY: "scroll",
          background: "#fff",
          boxSizing: "border-box",
          padding: "18px 16px",
          display: "flex",
          flexDirection: "column",
          gap: "12px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              gap: "20px",
            }}
          >
            <EditButton
              isEditable={isEditable}
              setIsEditable={setIsEditable}
              permissions={permissions}
            />
            <BlockButton
              setAccountStatus={setAccountStatus}
              setBlockModel={setBlockModel}
              isBlocked={isBlocked}
              permissions={permissions}
            />
            <DeleteButton setOpen={setOpen} permissions={permissions} />
          </Box>

          <Button
            sx={{
              width: "28px",
              height: "28px",
              color: "#000",
            }}
            onClick={handleClear}
          >
            <ClearIcon />
          </Button>
        </Box>

        <Box
          sx={{
            display: "flex",
            alignItems: "flex-start",
            gap: "18px",
            width: "100%",
            marginTop: "20px",
          }}
        >
          {totalCardsData?.map((res) => (
            <InfoCard
              value={res?.value}
              label={res?.label}
              onClick={res?.onClick}
            />
          ))}
        </Box>

        <Box
          sx={{
            display: "flex",
            alignItems: "flex-start",
            gap: "18px",
            width: "100%",
            marginTop: "10px",
          }}
        >
          {todayCardsData?.map((res) => (
            <InfoCard
              value={res?.value}
              label={res?.label}
              onClick={res?.onClick}
            />
          ))}
        </Box>

        <Box
          sx={{
            marginTop: "25px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "12px",
            }}
          >
            <Typography
              sx={{
                fontFamily: "Proxima Nova",
                fontSize: "16px",
                fontStyle: "normal",
                fontWeight: "600",
                lineHeight: "normal",
                letterSpacing: "0.32px",
                textTransform: "capitalize",
                color: "#000",
              }}
            >
              Basic Details
            </Typography>
            <Typography
              sx={{
                fontFamily: "Proxima Nova",
                fontSize: "16px",
                fontStyle: "normal",
                fontWeight: "500",
                lineHeight: "normal",
                color: "#6F747E",
              }}
            >
              Joined on {`${details?.createdAt}`}
            </Typography>
            <Avatar
              sx={{
                width: "150px",
                height: "150px",
                borderRadius: "none",
              }}
              src={details?.profile_pic ?? ""}
            ></Avatar>
          </Box>

          {!isEditable ? (
            <Box sx={{ width: "100%", marginTop: "20px" }}>
              <Grid
                container
                rowSpacing={4}
                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
              >
                <Grid item xs={6}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "8px",
                    }}
                  >
                    <Typography
                      sx={{
                        color: "rgba(0, 0, 0, 0.50)",
                        fontFamily: "Proxima Nova",
                        fontSize: "16px",
                        fontStyle: "normal",
                        fontWeight: "400",
                        lineHeight: "normal",
                        letterSpacing: "0.32px",
                        textTransform: "capitalize",
                      }}
                    >
                      Name
                    </Typography>
                    <Typography
                      sx={{
                        color: "#000",
                        fontFamily: "Proxima Nova",
                        fontSize: "16px",
                        fontStyle: "normal",
                        fontWeight: "400",
                        lineHeight: "normal",
                      }}
                    >
                      {details.fullName}
                    </Typography>
                  </Box>
                </Grid>

                {details?.nickName && (
                  <Grid item xs={6}>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "8px",
                      }}
                    >
                      <Typography
                        sx={{
                          color: "rgba(0, 0, 0, 0.50)",
                          fontFamily: "Proxima Nova",
                          fontSize: "16px",
                          fontStyle: "normal",
                          fontWeight: "400",
                          lineHeight: "normal",
                          letterSpacing: "0.32px",
                          textTransform: "capitalize",
                        }}
                      >
                        NickName
                      </Typography>
                      <Typography
                        sx={{
                          color: "#000",
                          fontFamily: "Proxima Nova",
                          fontSize: "16px",
                          fontStyle: "normal",
                          fontWeight: "400",
                          lineHeight: "normal",
                        }}
                      >
                        {details.gender}
                      </Typography>
                    </Box>
                  </Grid>
                )}

                <Grid item xs={6}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "8px",
                    }}
                  >
                    <Typography
                      sx={{
                        color: "rgba(0, 0, 0, 0.50)",
                        fontFamily: "Proxima Nova",
                        fontSize: "16px",
                        fontStyle: "normal",
                        fontWeight: "400",
                        lineHeight: "normal",
                        letterSpacing: "0.32px",
                        textTransform: "capitalize",
                      }}
                    >
                      Gender
                    </Typography>
                    <Typography
                      sx={{
                        color: "#000",
                        fontFamily: "Proxima Nova",
                        fontSize: "16px",
                        fontStyle: "normal",
                        fontWeight: "400",
                        lineHeight: "normal",
                      }}
                    >
                      {details.gender}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "8px",
                    }}
                  >
                    <Typography
                      sx={{
                        color: "rgba(0, 0, 0, 0.50)",
                        fontFamily: "Proxima Nova",
                        fontSize: "16px",
                        fontStyle: "normal",
                        fontWeight: "400",
                        lineHeight: "normal",
                        letterSpacing: "0.32px",
                        textTransform: "capitalize",
                      }}
                    >
                      Email Id
                    </Typography>
                    <Typography
                      sx={{
                        color: "#000",
                        fontFamily: "Proxima Nova",
                        fontSize: "16px",
                        fontStyle: "normal",
                        fontWeight: "400",
                        lineHeight: "normal",
                      }}
                    >
                      {details.email}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "8px",
                    }}
                  >
                    <Typography
                      sx={{
                        color: "rgba(0, 0, 0, 0.50)",
                        fontFamily: "Proxima Nova",
                        fontSize: "16px",
                        fontStyle: "normal",
                        fontWeight: "400",
                        lineHeight: "normal",
                        letterSpacing: "0.32px",
                        textTransform: "capitalize",
                      }}
                    >
                      Phone Number
                    </Typography>
                    <Typography
                      sx={{
                        color: "#000",
                        fontFamily: "Proxima Nova",
                        fontSize: "16px",
                        fontStyle: "normal",
                        fontWeight: "400",
                        lineHeight: "normal",
                      }}
                    >
                      {details.phoneNumber}
                    </Typography>
                  </Box>
                </Grid>
                {/* <Grid item xs={6}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "8px",
                    }}
                  >
                    <Typography
                      sx={{
                        color: "rgba(0, 0, 0, 0.50)",
                        fontFamily: "Proxima Nova",
                        fontSize: "16px",
                        fontStyle: "normal",
                        fontWeight: "400",
                        lineHeight: "normal",
                        letterSpacing: "0.32px",
                        textTransform: "capitalize",
                      }}
                    >
                      Address
                    </Typography>
                    <Typography
                      sx={{
                        color: "#000",
                        fontFamily: "Proxima Nova",
                        fontSize: "16px",
                        fontStyle: "normal",
                        fontWeight: "400",
                        lineHeight: "normal",
                        width: "208px",
                      }}
                    >
                      649 R. Hidalgo Street, Barangay 307 Quiapo, City of
                      Manila, Metro Manila 1001
                    </Typography>
                  </Box>
                </Grid> */}
                <Grid item xs={6}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "8px",
                    }}
                  >
                    <Typography
                      sx={{
                        color: "rgba(0, 0, 0, 0.50)",
                        fontFamily: "Proxima Nova",
                        fontSize: "16px",
                        fontStyle: "normal",
                        fontWeight: "400",
                        lineHeight: "normal",
                        letterSpacing: "0.32px",
                        textTransform: "capitalize",
                      }}
                    >
                      DOB
                    </Typography>
                    <Typography
                      sx={{
                        color: "#000",
                        fontFamily: "Proxima Nova",
                        fontSize: "16px",
                        fontStyle: "normal",
                        fontWeight: "400",
                        lineHeight: "normal",
                      }}
                    >
                      {details.DOB}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>

              {favouritePlace.length > 0 && (
                <>
                  <Divider sx={{ marginTop: "24px" }} />
                  <Box
                    sx={{
                      marginTop: "24px",
                      display: "flex",
                      flexDirection: "column",
                      gap: "24px",
                    }}
                  >
                    <Typography
                      sx={{
                        color: "#000",
                        fontSize: "16px",
                        fontStyle: "normal",
                        fontWeight: "500",
                        lineHeight: "normal",
                        letterSpacing: "0.32px",
                        textTransform: "capitalize",
                      }}
                    >
                      Saved Address
                    </Typography>
                    {favouritePlace.map((res) => {
                      return (
                        <CustomerAddressCard
                          key={res.id}
                          name={res?.name ?? ""}
                          address={res?.location?.address_text ?? ""}
                        />
                      );
                    })}
                  </Box>
                </>
              )}
            </Box>
          ) : (
            <Box
              sx={{
                marginTop: "24px",
                width: "100%",
              }}
            >
              <Grid
                container
                rowSpacing={4}
                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
              >
                <Grid item xs={6}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "8px",
                    }}
                  >
                    <Typography
                      sx={{
                        color: "rgba(0, 0, 0, 0.50)",
                        fontFamily: "Proxima Nova",
                        fontSize: "16px",
                        fontStyle: "normal",
                        fontWeight: "400",
                        lineHeight: "normal",
                        letterSpacing: "0.32px",
                        textTransform: "capitalize",
                      }}
                    >
                      Name
                    </Typography>
                    <TextField
                      type="text"
                      size="small"
                      name="fullName"
                      value={details.fullName}
                      onChange={handleDetailsChange}
                      sx={{
                        height: "40px",
                      }}
                    />
                  </Box>
                </Grid>
                {details?.nickName && (
                  <Grid item xs={6}>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "8px",
                      }}
                    >
                      <Typography
                        sx={{
                          color: "rgba(0, 0, 0, 0.50)",
                          fontFamily: "Proxima Nova",
                          fontSize: "16px",
                          fontStyle: "normal",
                          fontWeight: "400",
                          lineHeight: "normal",
                          letterSpacing: "0.32px",
                          textTransform: "capitalize",
                        }}
                      >
                        NickName
                      </Typography>
                      <TextField
                        type="text"
                        size="small"
                        name="fullName"
                        value={details.nickName}
                        onChange={handleDetailsChange}
                        sx={{
                          height: "40px",
                        }}
                      />
                    </Box>
                  </Grid>
                )}

                <Grid item xs={6}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "8px",
                    }}
                  >
                    <Typography
                      sx={{
                        color: "rgba(0, 0, 0, 0.50)",
                        fontFamily: "Proxima Nova",
                        fontSize: "16px",
                        fontStyle: "normal",
                        fontWeight: "400",
                        lineHeight: "normal",
                        letterSpacing: "0.32px",
                        textTransform: "capitalize",
                      }}
                    >
                      Gender
                    </Typography>
                    <Select
                      name="gender"
                      size="small"
                      MenuProps={{
                        style: { zIndex: 2000 },
                      }}
                      onChange={(e) => {
                        setGender((prev) => ({
                          ...prev,
                          gender: e?.target?.value ?? "",
                        }));
                      }}
                      value={gender.gender}
                    >
                      <MenuItem value="MALE">Male</MenuItem>
                      <MenuItem
                        style={{
                          zIndex: 9999,
                        }}
                        value="FEMALE"
                      >
                        Female
                      </MenuItem>
                    </Select>
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "8px",
                    }}
                  >
                    <Typography
                      sx={{
                        color: "rgba(0, 0, 0, 0.50)",
                        fontFamily: "Proxima Nova",
                        fontSize: "16px",
                        fontStyle: "normal",
                        fontWeight: "400",
                        lineHeight: "normal",
                        letterSpacing: "0.32px",
                        textTransform: "capitalize",
                      }}
                    >
                      Email Id
                    </Typography>
                    <TextField
                      type="email"
                      size="small"
                      name="email"
                      value={details.email}
                      onChange={handleDetailsChange}
                    />
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "8px",
                    }}
                  >
                    <Typography
                      sx={{
                        color: "rgba(0, 0, 0, 0.50)",
                        fontFamily: "Proxima Nova",
                        fontSize: "16px",
                        fontStyle: "normal",
                        fontWeight: "400",
                        lineHeight: "normal",
                        letterSpacing: "0.32px",
                        textTransform: "capitalize",
                      }}
                    >
                      Phone Number
                    </Typography>
                    <TextField
                      type="text"
                      name="phoneNumber"
                      onChange={handleDetailsChange}
                      size="small"
                      value={details.phoneNumber}
                    />
                  </Box>
                </Grid>
                {/* <Grid item xs={6}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "8px",
                  }}
                >
                  <Typography
                    sx={{
                      color: "rgba(0, 0, 0, 0.50)",
                      fontFamily: "Proxima Nova",
                      fontSize: "16px",
                      fontStyle: "normal",
                      fontWeight: "400",
                      lineHeight: "normal",
                      letterSpacing: "0.32px",
                      textTransform: "capitalize",
                    }}
                  >
                    Address
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "15px",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "10px",
                      }}
                    >
                      <TextField
                        type="text"
                        name="houseNumber"
                        size="small"
                        label="House Number"
                        value={Address.houseNumber}
                        onChange={handleAddressChanges}
                      />
                      <TextField
                        type="text"
                        name="barangay"
                        label="Barangay"
                        size="small"
                        value={Address.barangay}
                        onChange={handleAddressChanges}
                      />
                      <TextField
                        type="text"
                        name="city"
                        size="small"
                        label="City"
                        value={Address.city}
                        onChange={handleAddressChanges}
                      />
                      <TextField
                        type="text"
                        name="postalCode"
                        label="Postal Code"
                        size="small"
                        value={Address.postalCode}
                        onChange={handleAddressChanges}
                      />
                    </Box>
                  </Box>
                </Box>
              </Grid> */}
                <Grid item xs={6}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "8px",
                    }}
                  >
                    <Typography
                      sx={{
                        color: "rgba(0, 0, 0, 0.50)",
                        fontFamily: "Proxima Nova",
                        fontSize: "16px",
                        fontStyle: "normal",
                        fontWeight: "400",
                        lineHeight: "normal",
                        letterSpacing: "0.32px",
                        textTransform: "capitalize",
                      }}
                    >
                      DOB
                    </Typography>
                    <TextField
                      type="date"
                      size="small"
                      value={details.DOB}
                      name="DOB"
                      onChange={handleDetailsChange}
                    />
                  </Box>
                </Grid>
              </Grid>

              <Box
                sx={{
                  marginTop: "25px",
                  position: "fixed",
                  bottom: "18px",
                  display: "flex",
                  gap: "12px",
                }}
              >
                <Button
                  sx={{
                    width: "162px",
                    height: "32px",
                    borderRadius: "4px",
                    border: "1px solid #000",
                    background: "#fff",
                    color: "#000",
                    fontSize: "14px",
                    fontWeight: "600",
                    lineHeight: "normal",
                    letterSpacing: "0.28px",
                  }}
                  onClick={(e) => setIsEditable((prev) => !prev)}
                >
                  Cancel
                </Button>
                <Button
                  sx={{
                    width: "162px",
                    height: "32px",
                    borderRadius: "4px",
                    border: "1px solid #000",
                    background: "#000",
                    color: "#fff",
                    fontSize: "14px",
                    fontWeight: "600",
                    lineHeight: "normal",
                    letterSpacing: "0.28px",
                    "&:hover": {
                      background: "#000",
                    },
                  }}
                  onClick={updateCustomerDetails}
                >
                  Update
                </Button>
              </Box>
            </Box>
          )}
        </Box>

        <CustomConfirmationDialog
          open={open}
          message={`Are you sure you want to delete ${details.fullName}?`}
          status="delete"
          deleteById={DeleteRiderById}
          riderId={riderId}
          setOpen={setOpen}
        />

        <CustomConfirmationDialog
          open={blockModel}
          message={`Are you Sure you Want to ${
            isBlocked ? "unblock" : "block"
          } ${details.fullName}?`}
          status="block"
          blockById={handleBlock}
          riderId={riderId}
          setOpen={setBlockModel}
        />
      </Box>
    </>
  );
};

export default CustomerDetails;
