import CustomerAnalytics from "./CustomerAnalytics";
import DriverAnalytics from "./DriverAnalytics";
import DriverOnMaps from "./DriverOnMaps";
import GeoFence from "./GeoFence";
import Summary from "./Summary";

const SwitchablePanel = ({ selectedOption, create, setCreate }) => {
  switch (selectedOption) {
    case "Summary":
      return <Summary />;
    case "Customer":
      return <CustomerAnalytics />;
    case "Driver":
      return <DriverAnalytics />;
    case "Drivers on Map":
      return <DriverOnMaps />;
    case "Geo Fence":
      return <GeoFence create={create} setCreate={setCreate} />;
    default:
      return null;
  }
};

export default SwitchablePanel;
