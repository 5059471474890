import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import React from "react"; // Adjust the path as needed
import { toast } from "react-toastify";

const CustomDatePicker = ({
  label,
  value,
  onChange,
  name,
  isDisabled = false,
  setState,
  style = {},
  minDate,
  maxDate,
  ...rest
}) => {
  return (
    <DatePicker
      minDate={minDate}
      maxDate={maxDate}
      format="DD/MM/YYYY"
      disabled={isDisabled}
      sx={{ ...style, color: "#d9d9da" }}
      label={label}
      slotProps={{ textField: { size: "small" } }}
      value={value || null}
      onChange={(targetValue) => {
        const date = dayjs(targetValue);
        if (date.isValid()) {
          onChange(name, date, setState);
        } else {
          toast.error("Please select a valid date!");
        }
      }}
      {...rest}
    />
  );
};

export default CustomDatePicker;
