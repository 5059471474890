// SwipeableDrawerComponent.jsx
import React from "react";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import { Paper } from "@mui/material";

const SwipeableDrawerComponent = ({
  isOpen,
  onClose,
  children,
  anchor = "",
  ModalProps,
}) => {
  return (
    <SwipeableDrawer
      open={isOpen}
      onClose={onClose}
      anchor={anchor}
      ModalProps={ModalProps ?? {}}
    >
      {isOpen && (
        <Paper
          sx={{
            backgroundColor: "transparent",
            overflow: "auto",
            scrollbarWidth: "thin",
            scrollbarColor: "transparent transparent", // for Firefox
            "&::-webkit-scrollbar": {
              width: "0px", // width of the scrollbar
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "transparent", // color of the thumb
            },
            "&::-webkit-scrollbar-track": {
              backgroundColor: "transparent", // color of the track
            },
          }}
        >
          {children}
        </Paper>
      )}
    </SwipeableDrawer>
  );
};

export default SwipeableDrawerComponent;
