import { Autocomplete, Box, Stack, TextField } from "@mui/material";
import React from "react";
import ReusableRadioGroup from "./ReusableRadioGroup";
import { setQuery } from "../../../utils/queryHelpers";
import { useSearchParams } from "react-router-dom";
import useQueryParams from "../../../hooks/useQueryParams ";
const getOptionSelected = (option, selectedValue) => {
  return option.value === selectedValue;
};

const DynamicFilter = ({
  type,
  state,
  dispatch,
  parentDispatch,
  setState,
  options,
  date,
  time,
  conditionalState,
  inputstate,
  filterName,
  inputSetState,
  inputlabel = "Start From Id",
  inputOptionsFilterName,
}) => {
  const { setQuery } = useQueryParams();
  switch (type) {
    case "input":
      return (
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            mt: 1,
          }}
        >
          <TextField
            placeholder="Start From Id"
            label="Start From Id"
            type="number"
            fullWidth
            value={state}
            onChange={(e) => {
              e.stopPropagation();
              const { value } = e.target;
              console.log(filterName);
              if (filterName) {
                setQuery(filterName, value);
              }
              parentDispatch ? dispatch(setState(value)) : setState(value);
            }}
          />
        </Box>
      );

    case "select":
      return (
        <Autocomplete
          sx={{ my: "12px" }}
          value={state}
          options={options || []}
          getOptionLabel={(option) => option?.label}
          isOptionEqualToValue={getOptionSelected}
          onChange={(e, newValue) => {
            e.stopPropagation();
            setState(e, newValue);
            if (filterName) {
              setQuery(filterName, newValue?.value);
            }
          }}
          placeholder="select a country"
          renderInput={(params) => (
            <TextField {...params} label="select a country" />
          )}
        />
      );
    case "input radio":
      return (
        <Stack gap={"5px"}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              mt: 1,
            }}
          >
            <TextField
              placeholder={inputlabel}
              label={inputlabel}
              type="number"
              fullWidth
              value={inputstate}
              onChange={(e) => {
                e.stopPropagation();
                const { value } = e.target;
                if (filterName) {
                  setQuery(filterName, value);
                }
                parentDispatch
                  ? dispatch(setState(value))
                  : inputSetState(value);
              }}
            />
          </Box>
          <ReusableRadioGroup
            type={type}
            value={state}
            onChange={setState}
            options={options}
            date={date}
            time={time}
            state={state}
            disable={!inputstate}
            parentDisaptch={parentDispatch}
            condition={conditionalState}
            inputOptionsFilterName={inputOptionsFilterName}
          />
        </Stack>
      );

    default:
      return (
        <ReusableRadioGroup
          type={type}
          value={state}
          filterName={filterName}
          onChange={setState}
          options={options}
          date={date}
          time={time}
          state={state}
          parentDisaptch={parentDispatch}
          condition={conditionalState}
        />
      );
  }
};

export default DynamicFilter;
