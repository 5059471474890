import { Box, Stack, Typography } from "@mui/material";
import destinationSvg from "../../assets/svg/destination.svg";
import { color } from "framer-motion";

//css

const containerStyle = {
  display: "flex",
  gap: "9px",
  boxSizing: "border-box",
  width: "100%",
};

const imgContainer = {
  width: "52px",
  height: "52px",
  //   border: "1px solid red",
};

const detailsContainer = {
  gap: "9px",
  width: "calc(100% - 60px)",
  //   border: "1px solid green",
};

const addressTextStyle = {
  fontFamily: "Proxima Nova",
  fontSize: "15px",
  fontWeight: 400,
  lineHeight: "19px",
  color: "#0F1828B2",
};

const Destination = ({ dropOffTime, stops = [], dropOfAddress }) => {
  const dropoff = [
    ...(Array.isArray(stops)
      ? stops.map((res) => res?.address_text || "")
      : []),
    dropOfAddress,
  ].filter((res) => res !== undefined);

  return (
    <Box sx={containerStyle}>
      <Box sx={imgContainer}>
        <img src={destinationSvg} width={"100%"} height={"100%"} alt="icon" />
      </Box>
      <Stack sx={detailsContainer}>
        <Stack
          width={"100%"}
          direction={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
          boxSizing={"border-box"}
        >
          <Typography
            variant="h6"
            sx={{
              color: "var(--font-dark, #0F1828)",
              fontFamily: "Proxima Nova",
              fontSize: { xs: "14px", sm: "18px" },
              fontStyle: "normal",
              fontWeight: 600,
              lineHeight: "normal",
              letterSpacing: "0.024px",
            }}
          >
            Destination
          </Typography>
          <Typography
            variant="h6"
            style={{
              fontFamily: "Proxima Nova",
              fontSize: { xs: "14px", sm: "18px" },
              fontStyle: "normal",
              fontWeight: 600,
              lineHeight: "normal",
              letterSpacing: "0.024px",
            }}
          >
            {dropOffTime}
          </Typography>
        </Stack>
        <Stack
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "8px",
            margin: "0",
          }}
        >
          {dropoff?.length > 0 &&
            dropoff?.map((res, index) => (
              <Box>
                <Typography style={addressTextStyle}>{`${
                  dropoff?.length === 1 ? "" : index + 1 + "."
                } ${res}`}</Typography>
                {dropoff.length - 1 !== index && (
                  <Box
                    sx={{
                      width: "100%",
                      height: "1.5px",
                      background: "#E3E7EF",
                      m: "5px 0",
                    }}
                  />
                )}
              </Box>
            ))}
        </Stack>
      </Stack>
    </Box>
  );
};

export default Destination;
