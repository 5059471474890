import { useState, useEffect, useMemo } from "react";
import {
  Box,
  Button,
  Typography,
  MenuItem,
  IconButton,
  Stack,
} from "@mui/material";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import useDebounce from "../hooks/useDebounce";
import DeleteIcon from "@mui/icons-material/Delete";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import BlockIcon from "@mui/icons-material/Block";
import axiosInstance from "../utils/axiosConfig";
import DataTable from "./DataTable";
import { StyledMenu, TripsComponent } from "./Customer";
import ArticleIcon from "@mui/icons-material/Article";
import CustomConfirmationDialog from "./CustomConfirmationDialog";
import { showErrorToast, showSuccessToast } from "../utils/toastUtils";
import SearchActionComponent from "./reusableComponents/SearchActionComponent";
import AddIcon from "@mui/icons-material/Add";
import SwipeableDrawerComponent from "./SwipeableDrawerComponent ";
import { BootstrapTooltip } from "./DetailsSidePanelChart";
import usePermission from "../hooks/usePermission";
import { useSettings } from "../hooks/useSettings";
import {
  setAppointmentStatus,
  setCabType,
  setDriverFromId,
  setDriverType,
  setGender,
  setIsOnline,
  setJoinedOn,
  setRating,
  setStatusUpdate,
  setTnvsType,
  setTrips,
} from "../state/filtersSlice";
import CustomFilter, {
  SortPanel,
} from "./reusableComponents/Filter/CustomFilter";
import { customeDateHandler } from "../const/commonfilterobject";
import FilteredResults from "./FilteredResults";
import { validateNumber } from "../utils/formatNumberShort";
import CommonDateFilterPanel from "./CommonDateFilterPanel";
import { getCountryNamesAndCodes } from "../const/countrylist";
import { commonSortOptions, joinedonSortObj } from "../const/sort";
import useQueryParams from "../hooks/useQueryParams ";
import dayjs from "dayjs";

const GenericText = ({ variant = "body1", text, css = "" }) => {
  const typoStyles = {
    color: "var(--text-dark, #000)",
    textAlign: "center",
    fontFamily: "Proxima Nova",
    fontSize: "13px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "normal",
    letterSpacing: "0.26px",
    textTransform: "capitalize",
    overflow: "hidden",
    textOverflow: "ellipsis",
  };
  return (
    <BootstrapTooltip title={text}>
      <Typography variant={variant} sx={css ? { ...css } : { ...typoStyles }}>
        {text}
      </Typography>
    </BootstrapTooltip>
  );
};

const ToggleButton = ({
  toggledrawer = () => () => {},
  setid = () => {},
  handleClick = () => {},
  id,
  text,
}) => {
  const textCss = {
    color: "#2d5a9b",
    fontFamily: "Proxima Nova",
    fontSize: "13px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "normal",
    letterSpacing: "0.26px",
    textTransform: "capitalize",
    overflow: "hidden",
    textOverflow: "ellipsis",
  };

  return (
    <Button
      sx={{
        justifyContent: "flex-start",
        overflow: "hidden",
      }}
      variant="text"
      onClick={(e) => {
        setid(id);
        // this  toggledrawer funtion return a funtion then we are passing event on it
        toggledrawer("right", true)(e);
        handleClick(id);
      }}
    >
      <GenericText text={text} css={textCss} />
    </Button>
  );
};

const Driver = () => {
  const [isLoading, setisLoading] = useState(false);

  const [DriverData, setDriverData] = useState([]);
  const { searchParams, setSearchParams, getQuery, removeQuery } =
    useQueryParams();
  const dispatch = useDispatch();

  const pageParam = parseInt(searchParams.get("page"), 0) || 0;
  const limit = parseInt(searchParams.get("limit"), 10) || 10;

  const category = searchParams.get("category");
  const [trackTable, setTrackTable] = useState({
    new: {
      paginationModel: {
        page: pageParam || 0,
        pageSize: limit || 10,
      },
      totalCount: 0,
    },

    onboarded: {
      paginationModel: {
        page: pageParam || 0,
        pageSize: limit || 10,
      },
      totalCount: 0,
    },
    blocked: {
      paginationModel: {
        page: pageParam || 0,
        pageSize: limit || 10,
      },
      totalCount: 0,
    },
    delete: {
      paginationModel: {
        page: pageParam || 0,
        pageSize: limit || 10,
      },
      totalCount: 0,
    },
  });

  const [sort, setSort] = useState({
    joinedon: getQuery("joined_on_sort") ?? "",
    peekupPoints: getQuery("peekup_points_sort") ?? "",
    verifiedAt: getQuery("verified_at_sort") ?? "",
    trips: getQuery("trips_sort") ?? "",
  });

  const [showSort, SetShowSort] = useState({ right: false });

  const permissions = usePermission();

  const [conformationDialog, setConformationDialog] = useState({
    delete: false,
    block: false,
  });

  const filters = useSelector((state) => state.filterSidePanel);

  const [filter, setFilter] = useState({ right: false });

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const [tableList, setTableList] = useState(category || "new");
  const { timezone, formatDate, dateformat, timeFormat } = useSettings();

  const page = trackTable[tableList].paginationModel.page;
  const pageSize = trackTable[tableList].paginationModel.pageSize;

  const [searchValue, setSearchValue] = useState("");

  const searchDebounce = useDebounce(searchValue, 500);

  const [date, setDate] = useState({
    startDate: "",
    endDate: "",
  });

  const [pointsValue, setPointsvalue] = useState("");

  const [peekuppointFilter, setPeekupPointFilter] = useState("");

  const [columnVisibilityModel, setColumnVisibilityModel] = useState({
    completed_trips_count: false,
    avg_rating: false,
    case_id: false,
    appointment_date: true,
    current_step_status: true,
    appointment_status: true,
    verified_by: false,
    verified_at: false,
    peekup_points: false,
    request_received: false,
    request_accepted: false,
    request_rejected: false,
    request_autorejected: false,
  });

  const handleConformationDailog = (label, value) => {
    console.log(label, value);
    setConformationDialog((prev) => ({ ...prev, [label]: value }));
  };

  // state to store driver id
  const [driverId, setDriverId] = useState("");
  const [driverName, setDriverName] = useState("");
  const [accountStatus, setAccountStatus] = useState("");

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleRowClick = (params) => {
    if (!params) return;
    navigate(`/driver/${params}`);
  };

  const buildQueryString = (status) => {
    // checking for query
    let query = [];

    if (searchDebounce && searchValue) {
      query.push(`search=${searchDebounce}`);
    }

    const activestatus = tableList.toUpperCase();
    if (activestatus.includes("BLOCKED") || activestatus === "DELETE") {
      const accountStatus = activestatus === "DELETE" ? "DELETE" : activestatus;
      query.push(`account_status=${accountStatus}`);
    } else {
    }

    if (filters?.driverFromId) {
      query.push(`start_driver_id=${filters?.driverFromId}`);
    }

    if (pointsValue && peekuppointFilter) {
      query.push(`peekup_points_value=${pointsValue}`);
      query.push(`peekup_points_filter=${peekuppointFilter}`);
    }

    if (sort?.joinedon) {
      query.push(`joined_on_sort=${sort?.joinedon}`);
    }

    if (sort?.peekupPoints) {
      query.push(`peekup_points_sort=${sort?.peekupPoints}`);
    }

    if (sort?.verifiedAt) {
      query.push(`verified_at_sort=${sort?.verifiedAt}`);
    }

    if (sort?.trips) {
      query.push(`trips_sort=${sort?.trips}`);
    }

    if (status) {
      query.push(`is_download=${true}`);
      if (driverId) {
        query.push(`from_driver_id=${driverId}`);
      }
    }

    if (date.startDate && date.endDate) {
      let startDate = `${date.startDate.year()}-${date.startDate.month()}-${date.startDate.date()}`;
      let endDate = `${date.endDate.year()}-${date.endDate.month()}-${date.endDate.date()}`;
      query.push(`date_gte=${startDate}&date_lte=${endDate}`);
    }

    if (filters.gender) {
      query.push(`gender=${filters.gender}`);
    }

    if (filters.joinedon) {
      query.push(`joined_on=${filters.joinedon}`);
    }

    if (filters.isOnline) {
      query.push(`is_online=${filters.isOnline === "1" ? true : false}`);
    }

    if (filters.appointmentStatus) {
      query.push(`appointment_status=${filters.appointmentStatus}`);
    }

    if (filters.statusUpdate) {
      query.push(`onboarding_status=${filters.statusUpdate}`);
    }

    if (tableList) {
      query.push(`is_verified=${tableList === "onboarded"}`);
    }

    if (filters.driverType) {
      query.push(`driver_type=${filters.driverType}`);
    }

    if (filters?.driverType === "TNVS" && filters?.tnvsType) {
      query.push(`is_existing_tnvs=${filters?.tnvsType === "Existing TNVS"}`);
    }
    if (filters.cabType) {
      query.push(`cab_type=${filters.cabType}`);
    }

    if (filters.trips) {
      query.push(`trips_sort=${filters.trips}`);
    }

    if (filters.rating) {
      query.push(`rating=${filters.rating}`);
    }

    let querys = query.length > 1 ? query.join("&") : query.join("");

    return querys;
  };

  const getDrivers = async (status) => {
    setisLoading(true);
    let checkQuery = buildQueryString(status);
    // Define the API endpoint URL
    const apiUrl = `/crm/driver/?page=${
      trackTable[tableList].paginationModel.page + 1
    }&limit=${trackTable[tableList].paginationModel.pageSize}&${checkQuery}`;

    await axiosInstance
      .get(apiUrl)
      .then((data) => {
        if (status) {
          if (data) {
            showSuccessToast(
              "file has been successfully sent via Email. Check your Email account",
              {
                autoClose: 5000,
              }
            );
          }
          return;
        }
        setDriverData(data?.data?.data?.records);

        setTrackTable((prev) => ({
          ...prev,
          [tableList]: {
            ...prev[tableList],
            totalCount: data?.data?.data?.total_count ?? 0,
          },
        }));
      })
      .catch((error) => {
        console.error("Fetch error:", error);
      })
      .finally(() => {
        setisLoading(false);
      });

    // Make a GET request to the API
  };

  const blockDriverByID = async () => {
    const url = `/crm/driver/${driverId}/`;

    const blockStatus =
      accountStatus === "ACTIVE" || accountStatus === "INACTIVE"
        ? "BLOCKED"
        : "ACTIVE";

    const updateAccountStatus = {
      account_status: blockStatus,
    };

    try {
      let response = await axiosInstance.patch(url, updateAccountStatus);

      if (response) {
        showSuccessToast(
          `Driver ${
            blockStatus === "BLOCKED" ? "Block" : "Unblock"
          } Succesfully!`
        );

        getDrivers();
      }
    } catch (error) {
      console.log("error while blocking driver", error);
      showErrorToast(error);
    }
  };

  const deleteDriverByID = async () => {
    let url = `/crm/driver/${driverId}/`;
    await axiosInstance
      .delete(url)
      .then((_) => {
        showSuccessToast("Driver Deleted Succesfully!");
        getDrivers();
      })
      .catch((error) => {
        console.log("Error while deleting driver by id", error);
        showErrorToast(error);
      });
  };

  const handleAccountStatus = (text) => {
    setTableList(text);
    searchParams.set("page", 0);
    setTrackTable((prev) => ({
      ...prev,
      [tableList]: {
        ...prev[tableList],
        paginationModel: { ...prev[tableList].paginationModel, page: 0 },
      },
    }));
    searchParams.set("category", text);
    setSearchParams(searchParams, { replace: true });
  };

  // config
  const columns = [
    {
      field: "phone",
      headerName: "Phone Number",
      minWidth: 150,
      flex: 1,
      renderCell: (params) => {
        if (!params.value) {
          return "--";
        }

        let phoneNumber = params.value;
        let number = phoneNumber.slice(0, 3) + " " + phoneNumber.slice(3);
        return (
          <ToggleButton
            handleClick={handleRowClick}
            text={number}
            id={params?.id}
          />
        );
      },
    },
    {
      field: "full_name",
      headerName: "Driver Name",
      minWidth: 150,
      renderCell: (params) => {
        if (!params.value) {
          return "--";
        }
        return (
          <ToggleButton
            handleClick={handleRowClick}
            id={params?.id}
            text={params?.value ?? ""}
          />
        );
      },
    },
    {
      field: "peekup_points",
      headerName: "Peekup Points",
      minWidth: 150,
      renderCell: (params) => {
        if (!params.value && params.value !== 0) {
          return "--";
        }

        return (
          <Typography
            sx={{
              color: "var(--text-dark, #000)",
              fontFamily: "Proxima Nova",
              fontSize: "13px",
              fontStyle: "normal",
              fontWeight: 500,
              lineHeight: "normal",
              letterSpacing: "0.26px",
            }}
          >{`${params.value}`}</Typography>
        );
      },
    },
    {
      field: "nick_name",
      headerName: "Nick Name",
      minWidth: 150,
      flex: 1,
      renderCell: (params) => {
        if (!params.value) {
          return "--";
        }
        return (
          <ToggleButton
            handleClick={handleRowClick}
            id={params?.id}
            text={params?.value ?? ""}
          />
        );
      },
    },

    {
      field: "case_id",
      headerName: "LTFRB ID",
      minWidth: 150,
      flex: 1,
      renderCell: (params) => {
        if (!params.value) return "--";

        return <GenericText text={params.value} />;
      },
    },
    {
      field: "appointment_status",
      headerName: "Appointment status",
      minWidth: 150,
      flex: 1,
      renderCell: (params) => {
        if (!params.value) {
          return "--";
        }

        const getColorBasedOnStatus = (appoinstatus) => {
          switch (appoinstatus) {
            case "Requested":
              return "#F98244";
            case "Alloted":
              return "#043A87";
            case "No Request":
              return "#6F747E";
            case "No Show":
              return "#F1554C";
            default:
              // Default color if none of the specified statuses match
              return "#000000";
          }
        };

        const getBackgroundColorBasedOnStatus = (appointmentStatus) => {
          switch (appointmentStatus) {
            case "Requested":
              return "rgba(249, 130, 68, 0.09)";
            case "Alloted":
              return "rgba(4, 58, 135, 0.09)";
            case "No Request":
              return "rgba(111, 116, 126, 0.10)";
            case "No Show":
              return "rgba(241, 85, 76, 0.08)";
            default:
              // Default color if none of the specified statuses match
              return "#000000";
          }
        };

        let appointmentStatus =
          params?.value
            ?.replace(/_/g, " ")
            .replace(/\b\w/g, (char) => char.toUpperCase())
            .replace(/\s/g, " ") ?? params.value;

        const typoStyles = {
          color: "var(--text-dark, #000)",
          textAlign: "center",
          fontFamily: "Proxima Nova",
          fontSize: "13px",
          fontStyle: "normal",
          fontWeight: 400,
          lineHeight: "normal",
          letterSpacing: "0.26px",
          textTransform: "capitalize",
          borderRadius: "40px",
          padding: "4px 6px",
        };

        // Requested color - #F98244
        // allotted - #043A87
        // No Request - #6F747E

        return (
          <GenericText
            text={appointmentStatus}
            css={{
              ...typoStyles,
              color: `${getColorBasedOnStatus(appointmentStatus)}`,
              backgroundColor: `${getBackgroundColorBasedOnStatus(
                appointmentStatus
              )}`,
            }}
          />
        );
      },
    },
    {
      field: "current_step_status",
      headerName: "Status Update",
      minWidth: 150,
      flex: 1,
      renderCell: (params) => {
        if (!params.row.current_step_status) {
          return "--";
        }

        let replaceText = params.row.current_step_status.replace(
          /\/[^/]*$/,
          " "
        );
        return <GenericText text={replaceText} />;
      },
    },
    {
      field: "appointment_date",
      headerName: "Appointment date",
      minWidth: 150,
      flex: 1,
      renderCell: (params) => {
        if (!params?.value) {
          return "--";
        }

        let inputDate = new Date(params.value);
        const year = inputDate.getFullYear();
        const month = inputDate?.getMonth() + 1; // Months are zero-based, so add 1.
        const day = inputDate?.getDate();

        return <GenericText text={`${day}/${month}/${year}`} />;
      },
    },
    {
      field: "driver_type",
      headerName: "Driver Type",

      hideable: true,
      flex: 1,
      renderCell: (params) => {
        if (!params.value) {
          return "--";
        }

        return <GenericText text={params.value} />;
      },
    },
    {
      field: "is_existing_tnvs",
      headerName: "TNVS Type",

      flex: 1,
      hideable: true,
      renderCell: (params) => {
        if (!params.value && params?.value !== false) {
          return "--";
        }

        const existing_tvns =
          params?.value === true ? "Existing TNVS" : "New Applicant";

        return <GenericText text={existing_tvns} />;
      },
    },
    {
      field: "operator_name",
      headerName: "Operator Name",

      flex: 1,
      hideable: true,
      renderCell: (params) => {
        if (!params.value) {
          return "--";
        }

        return <GenericText text={params.value} />;
      },
    },
    // {
    //   field: "gender",
    //   headerName: "Gender",
    //   minWidth : 150,
    //   renderCell: (params) => {
    //     if (!params.value) {
    //       return "--";
    //     }
    //     return <GenericText text={params.value} />;
    //   },
    // },
    {
      field: "email",
      headerName: "Email",

      flex: 1,
      renderCell: (params) => {
        if (!params.value) {
          return "--";
        }
        return <GenericText text={params.value} />;
      },
    },
    {
      field: "request_received",
      headerName: "Request Received",

      renderCell: (params) => {
        if (!params.value && params.value !== 0) {
          return "--";
        }

        return (
          <Typography
            sx={{
              color: "var(--text-dark, #000)",
              fontFamily: "Proxima Nova",
              fontSize: "13px",
              fontStyle: "normal",
              fontWeight: 500,
              lineHeight: "normal",
              letterSpacing: "0.26px",
              width: "100%",
              textAlign: "center",
            }}
          >{`${params.value}`}</Typography>
        );
      },
    },
    {
      field: "request_rejected",
      headerName: "Request Rejected",

      renderCell: (params) => {
        if (!params.value && params.value !== 0) {
          return "--";
        }

        return (
          <Typography
            sx={{
              color: "var(--text-dark, #000)",
              fontFamily: "Proxima Nova",
              fontSize: "13px",
              fontStyle: "normal",
              fontWeight: 500,
              lineHeight: "normal",
              letterSpacing: "0.26px",
              width: "100%",
              textAlign: "center",
            }}
          >{`${params.value}`}</Typography>
        );
      },
    },

    {
      field: "request_accepted",
      headerName: "Request Accepted",

      renderCell: (params) => {
        if (!params.value && params.value !== 0) {
          return "--";
        }

        return (
          <Typography
            sx={{
              color: "var(--text-dark, #000)",
              fontFamily: "Proxima Nova",
              fontSize: "13px",
              fontStyle: "normal",
              fontWeight: 500,
              lineHeight: "normal",
              letterSpacing: "0.26px",
              width: "100%",
              textAlign: "center",
            }}
          >{`${params.value}`}</Typography>
        );
      },
    },
    {
      field: "request_autorejected",
      headerName: "Request AutoRejected",

      renderCell: (params) => {
        if (!params.value && params.value !== 0) {
          return "--";
        }

        return (
          <Typography
            sx={{
              color: "var(--text-dark, #000)",
              fontFamily: "Proxima Nova",
              fontSize: "13px",
              fontStyle: "normal",
              fontWeight: 500,
              lineHeight: "normal",
              letterSpacing: "0.26px",
              width: "100%",
              textAlign: "center",
            }}
          >{`${params.value}`}</Typography>
        );
      },
    },
    {
      field: "completed_trips_count",
      headerName: "Trips",

      flex: 1,
      renderCell: (param) => {
        // return (
        //   <Typography
        //     sx={{
        //       color: "#043A87",
        //       fontFamily: "Proxima Nova",
        //       fontSize: "13px",
        //       fontStyle: "normal",
        //       fontWeight: 500,
        //       lineHeight: "normal",
        //       letterSpacing: "0.26px",
        //     }}
        //   >{`${params.value}/${params.row.total_trips_count}`}</Typography>
        // );

        return (
          <BootstrapTooltip
            key={param.row.id}
            title={
              <TripsComponent
                completeTrips={validateNumber(
                  param?.row?.completed_trips_count
                )}
                cancelledTrips={validateNumber(
                  param?.row?.cancelled_trips_count
                )}
                totalTrips={validateNumber(param?.row?.total_trips_count)}
              />
            }
            arrow
            placement="bottom-end"
          >
            <Typography
              variant="h6"
              sx={{
                color: "var(--Linked, #043A87)",
                fontFamily: "Proxima Nova",
                fontSize: "13px",
                fontStyle: "normal",
                fontWeight: 500,
                lineHeight: "normal",
                letterSpacing: "0.26px",
              }}
            >
              {`${param?.row?.completed_trips_count ?? 0}/${
                param?.row?.cancelled_trips_count ?? 0
              }`}
            </Typography>
          </BootstrapTooltip>
        );
      },
    },
    {
      field: "created_at",
      headerName: `Joined On(${dateformat})`,

      flex: 1,
      valueGetter: (params) => {
        if (!params.value) {
          return "--";
        }

        return `${formatDate(params?.value, timezone, dateformat)}`;
      },
    },
    {
      field: "avg_rating",
      headerName: "Rating",

      flex: 1,
    },
    {
      field: "verified_by",
      headerName: "Verified By",

      flex: 1,
      renderCell: (params) => {
        if (!params.value && params.value !== 0) {
          return "--";
        }

        return (
          <Typography
            sx={{
              color: "var(--text-dark, #000)",
              fontFamily: "Proxima Nova",
              fontSize: "13px",
              fontStyle: "normal",
              fontWeight: 500,
              lineHeight: "normal",
              letterSpacing: "0.26px",
            }}
          >{`${params.value}`}</Typography>
        );
      },
    },
    {
      field: "verified_at",
      headerName: `Verified At(${dateformat})`,

      flex: 1,
      formatter: {
        created_at: (params) => formatDate(params?.value, timezone, dateformat),
        time: (params) =>
          formatDate(params?.row.verified_at, timezone, timeFormat),
        rating: (params) => `(${params?.value})`,
        // Add other formatters as needed
      },
      renderCell: (params) => {
        if (!params.value && params.value !== 0) {
          return "--";
        }
        let date = formatDate(params.value, timezone, dateformat);
        const time = formatDate(params.value, timezone, timeFormat);

        return (
          <Stack direction={"row"} gap={"5px"} alignItems={"flex-end"}>
            <Typography
              sx={{
                color: "#000",
                fontFamily: "Proxima Nova",
                fontSize: "13px",
                fontStyle: "normal",
                fontWeight: "500",
                lineHeight: "normal",
                letterSpacing: "0.26px",
              }}
            >
              {date}
            </Typography>

            <Typography
              sx={{
                color: "#6F747E",
                fontFamily: "Proxima Nova",
                fontSize: "10px",
                fontStyle: "normal",
                fontWeight: "500",
                lineHeight: "normal",
                letterSpacing: "0.26px",
              }}
            >
              {time}
            </Typography>
          </Stack>
        );
      },
    },
    {
      field: "modified_by",
      headerName: "Modified By",

      flex: 1,
      renderCell: (params) => {
        if (!params.value && params.value !== 0) {
          return "--";
        }

        return (
          <Typography
            sx={{
              color: "var(--text-dark, #000)",
              fontFamily: "Proxima Nova",
              fontSize: "13px",
              fontStyle: "normal",
              fontWeight: 500,
              lineHeight: "normal",
              letterSpacing: "0.26px",
            }}
          >{`${params.value}`}</Typography>
        );
      },
    },

    {
      field: "",
      headerName: "Action",
      minWidth: 10,
      renderCell: (params) => {
        return (
          <>
            <IconButton
              id="demo-positioned-button"
              aria-controls={open ? "demo-positioned-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={(e) => {
                setDriverId(params?.id ?? "");
                setAccountStatus(params?.row?.account_status ?? "");
                setDriverName(params?.row?.full_name);
                handleClick(e);
              }}
              anchorOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
            >
              <MoreVertIcon
                sx={{
                  color: "#000",
                }}
              />
            </IconButton>
            <StyledMenu
              id="demo-positioned-menu"
              anchorEl={anchorEl}
              open={anchorEl ? true : false}
              onClose={handleClose}
            >
              <MenuItem>
                <Button
                  sx={{
                    color: "#000",
                    fontFamily: "Proxima Nova",
                    fontSize: "15px",
                    fontStyle: "normal",
                    fontWeight: 500,
                    lineHeight: "175%",
                  }}
                  variant="text"
                  startIcon={
                    <ArticleIcon
                      sx={{
                        fill: "#000",
                      }}
                    />
                  }
                  onClick={() => {
                    handleRowClick(driverId);
                  }}
                >
                  View Details
                </Button>
              </MenuItem>
              {permissions?.UPDATE && (
                <MenuItem>
                  <Button
                    sx={{
                      color: "#000",
                      fontFamily: "Proxima Nova",
                      fontSize: "15px",
                      fontStyle: "normal",
                      fontWeight: 500,
                      lineHeight: "175%",
                    }}
                    variant="text"
                    startIcon={<BlockIcon />}
                    onClick={() => {
                      handleConformationDailog("block", true);
                      handleClose();
                    }}
                  >
                    {accountStatus === "BLOCKED"
                      ? "Unblock Driver"
                      : "Block Driver"}
                  </Button>
                </MenuItem>
              )}
              {permissions?.DELETE && (
                <MenuItem>
                  <Button
                    sx={{
                      color: "#000",
                      fontFamily: "Proxima Nova",
                      fontSize: "15px",
                      fontStyle: "normal",
                      fontWeight: 500,
                      lineHeight: "175%",
                    }}
                    variant="text"
                    startIcon={<DeleteIcon />}
                    onClick={(e) => {
                      handleClose();
                      handleConformationDailog("delete", true);
                    }}
                  >
                    Delete Driver
                  </Button>
                </MenuItem>
              )}
            </StyledMenu>
          </>
        );
      },
    },
  ];

  // config

  const tnvsType = [
    { value: "Existing TNVS", label: "Existing TNVS" },
    { value: "New Applicant", label: "New Applicant" },
  ];

  const filterOptions = [
    {
      type: "input",
      summryText: "Start From Id",
      filterName: "start_from_id",
      state: filters?.driverFromId,
      setState: setDriverFromId,
      parentDispatch: true,
    },
    {
      summryText: "Status update",
      state: filters?.statusUpdate,
      filterName: "status_update",
      setState: setStatusUpdate,
      parentDispatch: true,
      options: [
        { value: "details_pending", label: "Details pending" },
        { value: "documents_pending", label: "Document pending" },
        { value: "training_pending", label: "Training pending" },
        { value: "approval_pending", label: "Approval pending" },
        { value: "appointment_pending", label: "Appointment pending" },
        { value: "completed", label: "Completed" },
      ],
    },
    {
      summryText: "Joined On",
      filterName: "joined_on",
      state: filters?.joinedon,
      setState: setJoinedOn,
      parentDispatch: true,
      options: [
        { label: "Yesterday", value: "yesterday" },
        { label: "Last Week", value: "last_week" },
        { label: "Last month", value: "last_month" },
        { label: "Last year", value: "last_year" },
      ],
      date: {
        customDateState: date,
        setState: setDate,
        filterName: ["joined_on_date_gte", "joined_on_date_lte"],
        customeDateHandler,
        customDateHeader: "Custom",
        customDateLabel: ["From", "To"],
        customDateName: ["startDate", "endDate"],
      },
    },
    {
      summryText: "Cab type",
      state: filters?.cabType,
      filterName: "cab_type",
      setState: setCabType,
      parentDispatch: true,
      options: [
        { value: "COMPACT", label: "Compact" },
        { value: "TAXI", label: "Taxi" },
        { value: "PLUS", label: "Plus" },
      ],
    },
    {
      summryText: "Appointment status",
      state: filters?.appointmentStatus,
      filterName: "appointment_status",
      setState: setAppointmentStatus,
      parentDispatch: true,
      options: [
        { value: "requested", label: "Requested" },
        { value: "no_show", label: "No Show" },
        { value: "alloted", label: "Allotted" },
        { value: "no_request", label: "No Request" },
      ],
    },
    {
      summryText: "Gender",
      state: filters?.gender,
      filterName: "gender",
      setState: setGender,
      parentDispatch: true,
      options: [
        { label: "Male", value: "male" },
        { label: "Female", value: "female" },
      ],
    },
    {
      summryText: "Driver Type",
      state: filters?.driverType,
      filterName: "driver_type",
      setState: setDriverType,
      parentDispatch: true,
      options: [
        { label: "TNVS", value: "TNVS" },
        { label: "TAXI", value: "TAXI" },
      ],
      conditionalState: {
        state: filters?.tnvsType,
        filterName: "tnvs_type",
        conditionIsDispatch: true,
        conditionLogic: (res) => {
          if (res === "TNVS") {
            return true;
          } else {
            removeQuery("tnvs_type");
            return false;
          }
        },
        setState: setTnvsType,
        options: tnvsType.map((res) => ({
          label: res.value,
          value: res.value,
        })),
      },
    },
    {
      summryText: "Trips",
      filterName: "trips",
      state: filters?.trips,
      setState: setTrips,
      parentDispatch: true,
      options: [
        { label: "Max trips", value: "max_trips" },
        { label: "Min Trips", value: "min_trips" },
        { label: "Max Cancelled", value: "max_cancelled" },
        { label: "Min Cancelled", value: "min_cancelled" },
      ],
    },
    {
      summryText: "Online",
      filterName: "online",
      state: filters?.isOnline,
      setState: setIsOnline,
      parentDispatch: true,
      options: [
        { label: "Online", value: "1" },
        { label: "Offline", value: "0" },
      ],
    },
    {
      summryText: "Rating",
      state: filters?.rating,
      filterName: "rating",
      setState: setRating,
      parentDispatch: true,
      options: [
        { label: "5 star", value: "5" },
        { label: "4 star", value: "4" },
        { label: "3 star", value: "3" },
        { label: "2 star", value: "2" },
        { label: "1 star", value: "1" },
      ],
    },
    {
      summryText: "Peekup Points",
      type: "input radio",
      inputlabel: "Enter the Points",
      filterName: "peekup_points",
      inputOptionsFilterName: "peekup_point_option",
      state: peekuppointFilter,
      inputstate: pointsValue,
      inputSetState: setPointsvalue,
      setState: setPeekupPointFilter,
      options: [
        { label: "Equals", value: "EQ" },
        { label: "Greater Than", value: "GT" },
        { label: "Greater Than or Equal", value: "GTE" },
        { label: "Less Than or Equal", value: "LTE" },
        { label: "Less Than", value: "LT" },
      ],
    },
  ];

  useEffect(() => {
    const startFromId = getQuery("start_from_id");
    const statusUpdate = getQuery("status_update");
    const joinedOn = getQuery("joined_on");
    const cabType = getQuery("cab_type");
    const appointmentStatus = getQuery("Appointment status");
    const gender = getQuery("gender");
    const driverType = getQuery("driver_type");
    const tnvsType = getQuery("tnvs_type");
    const trips = getQuery("trips");
    const online = getQuery("online");
    const rating = getQuery("rating");
    const peekupPoints = getQuery("peekup_points");
    const peekupPointsOptions = getQuery("peekup_point_option");
    if (startFromId) {
      dispatch(setDriverFromId(startFromId));
    }

    if (statusUpdate) {
      dispatch(setStatusUpdate(statusUpdate));
    }

    if (joinedOn) {
      dispatch(setJoinedOn("joined_on"));
    }

    if (cabType) {
      dispatch(cabType);
    }

    if (appointmentStatus) {
      dispatch(setAppointmentStatus("appointment_status"));
    }

    if (gender) {
      dispatch(setGender(gender));
    }

    if (driverType) {
      dispatch(setDriverType(driverType));
    }

    if (tnvsType) {
      dispatch(setTnvsType(tnvsType));
    }

    if (trips) {
      dispatch(setTrips(trips));
    }

    if (online) {
      dispatch(setIsOnline(online === 1 ? true : false));
    }

    if (rating) {
      dispatch(setRating(rating));
    }

    if (peekupPoints) {
      setPointsvalue(Number(peekupPoints));
    }

    if (peekupPointsOptions) {
      setPeekupPointFilter(peekupPointsOptions);
    }

    const joinedonDateGte = getQuery("joined_on_date_gte");
    const joinedonDateLte = getQuery("joined_on_date_lte");
    console.log(date, joinedonDateGte);
    if (joinedonDateGte || joinedonDateLte) {
      setDate({
        startDate: joinedonDateGte ? dayjs(joinedonDateGte) : null,
        endDate: joinedonDateLte ? dayjs(joinedonDateLte) : null,
      });
    }
  }, []);

  const sortFilterConfig = {
    options: [
      {
        ...joinedonSortObj,
        state: sort?.joinedon ?? [],
        filterName: "joined_on_sort",
      },
      {
        summaryText: "Peekup Points",
        name: "peekupPoints",
        state: sort?.peekupPoints ?? "",
        options: commonSortOptions,
        filterName: "peekup_points_sort",
      },
      {
        summaryText: "Veified At",
        name: "verifiedAt",
        state: sort?.verifiedAt ?? "",
        options: commonSortOptions,
        filterName: "verified_at_sort",
      },
      {
        summaryText: "Trips",
        name: "trips",
        state: sort?.trips ?? "",
        options: commonSortOptions,
        filterName: "trips_sort",
      },
    ],
    handleClearState: () => {},
    // handleFilterState: handleFilterState,
  };

  const sub_section_to_label = {
    new: "New",
    onboarded: "Onboarded",
    DELETE: "Delete",
    BLOCKED: "Blocked",
  };

  const sub_section_callback = {
    new: () => {
      handleAccountStatus("new");
      setColumnVisibilityModel({
        completed_trips_count: false,
        avg_rating: false,
        case_id: false,
        appointment_date: true,
        current_step_status: true,
        appointment_status: true,
        verified_by: false,
        verified_at: false,
        peekup_points: false,
        request_received: false,
        request_accepted: false,
        request_rejected: false,
        request_autorejected: false,
      });
    },
    onboarded: () => {
      handleAccountStatus("onboarded");
      setColumnVisibilityModel({
        completed_trips_count: true,
        avg_rating: true,
        case_id: true,
        appointment_date: false,
        current_step_status: false,
        appointment_status: false,
        verified_by: true,
        verified_at: true,
        peekup_points: true,
        request_received: false,
        request_accepted: false,
        request_rejected: false,
        request_autorejected: false,
      });
    },
    DELETE: () => {
      handleAccountStatus("delete");
      setColumnVisibilityModel({
        completed_trips_count: true,
        avg_rating: true,
        case_id: true,
        appointment_date: false,
        current_step_status: false,
        appointment_status: false,
        verified_by: true,
        verified_at: true,
        peekup_points: false,
        request_received: false,
        request_accepted: false,
        request_rejected: false,
        request_autorejected: false,
      });
    },
    BLOCKED: () => {
      handleAccountStatus("blocked");
      setColumnVisibilityModel({
        completed_trips_count: true,
        avg_rating: true,
        case_id: true,
        appointment_date: false,
        current_step_status: false,
        appointment_status: false,
        verified_by: true,
        verified_at: true,
        peekup_points: false,
        request_received: false,
        request_accepted: false,
        request_rejected: false,
        request_autorejected: false,
      });
    },
  };

  const allowed_sub_sections = permissions?.SUB_SECTIONS ?? [
    "new",
    "onboarded",
    "DELETE",
    "BLOCKED",
  ];
  const tableHeadButtonConfig = allowed_sub_sections?.map((sub_section) => ({
    label: sub_section_to_label[sub_section],
    value: sub_section_to_label[sub_section]?.toLowerCase() ?? "",
    callback: sub_section_callback[sub_section],
  }));

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setFilter((state) => ({ ...state, [anchor]: open }));
  };

  const toggleSortDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    SetShowSort({ ...showSort, [anchor]: open });
  };

  const handlePagination = (value) => {
    setTrackTable((prev) => ({
      ...prev,
      [tableList]: { ...prev[tableList], paginationModel: value },
    }));
    searchParams.set("category", category || "new");

    searchParams.set("page", value?.page || 0);
    searchParams.set("limit", value?.pageSize?.toString() || 10);

    setSearchParams(searchParams, { replace: true });
  };

  const handleDownload = () => {
    getDrivers("download");
  };

  useEffect(() => {
    if (tableList) {
      const callback = sub_section_callback[tableList?.toLocaleLowerCase()];

      if (callback) {
        callback();
      }
    }
  }, []);

  useEffect(() => {
    getDrivers();
  }, [
    pageSize,
    page,
    searchDebounce,
    date,
    filters,
    tableList,
    pointsValue,
    peekuppointFilter,
    sort,
  ]); // The empty array [] ensures that this effect runs once when the component mounts

  const navigate = useNavigate();

  return (
    <>
      <Box sx={{ m: "1.5rem 1.5rem" }}>
        <SearchActionComponent
          value={searchValue}
          setState={setSearchValue}
          placeholder={"Search Driver, Phone no, Email"}
          toggleDrawer={toggleDrawer}
          toggleSortDrawer={toggleSortDrawer}
          require={{ search: true, filter: true, download: true, sort: false }}
          downloadFile={handleDownload}
        >
          {permissions?.CREATE && (
            <Button
              variant="outlined"
              sx={{
                color: "white",
                backgroundColor: "black",
                height: "42px",
                borderColor: "black",
                fontWeight: "bold",
                borderRadius: "10px",
                textTransform: "capitalize",
                ml: "10px",
              }}
              startIcon={<AddIcon />}
              onClick={() => navigate("/new-driver")}
            >
              Add Driver
            </Button>
          )}
        </SearchActionComponent>

        <FilteredResults options={filterOptions} />

        <Box
          sx={{
            mt: "15px",
            display: "flex",
            gap: "0.25rem",
          }}
        >
          {tableHeadButtonConfig.length > 0 &&
            tableHeadButtonConfig.map((res) => (
              <Button
                key={res.label}
                sx={{
                  display: "flex",
                  width: "142px",
                  padding: "10px",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "10px",
                  marginTop: "25px",
                  borderRadius: "10px 10px 0 0",
                  border: "1px solid #929EAE",
                }}
                style={{
                  background: tableList === res.value ? "lightgray" : "#FFF",
                }}
                onClick={() => {
                  console.log(res);
                  if (res?.callback) {
                    res.callback();
                  }
                }}
              >
                <Typography
                  variant="h4"
                  sx={{
                    fontSize: "14px",
                    fontFamily: "Proxima Nova",
                    textAlign: "right",
                    fontWeight: "600",
                    lineHeight: "normal",
                    letterSpacing: "0.28px",
                    textTransform: "capitalize",
                    color: "#000",
                  }}
                >
                  {res.label === "Onboarded" ? "Activated" : res?.label}
                </Typography>

                {tableList === res.value && (
                  <Box
                    sx={{
                      display: "inline-flex",
                      padding: "2px 5px",
                      justifyContent: "center",
                      alignItems: "center",
                      borderRadius: "10px",
                      background: "#000",
                    }}
                  >
                    <Typography
                      sx={{
                        color: "#fff",
                        textAlign: "right",
                        fontFamily: "Proxima Nova",
                        fontSize: "14px",
                        lineHeight: "normal",
                        fontWeight: "600px",
                        letterSpacing: "0.28px",
                        textTransform: "capitalize",
                      }}
                    >
                      {trackTable[res.value].totalCount > 0
                        ? trackTable[res.value].totalCount
                        : 0}
                    </Typography>
                  </Box>
                )}
              </Button>
            ))}
        </Box>

        {tableList === "new" && (
          <DataTable
            ownPaginationHandler={true}
            row={DriverData || []}
            columns={columns}
            loading={isLoading}
            paginationModel={trackTable[tableList].paginationModel}
            setPaginationModel={handlePagination}
            totalCount={trackTable[tableList].totalCount}
            columnVisibilityModel={columnVisibilityModel}
            setColumnVisibilityModel={setColumnVisibilityModel}
          />
        )}

        {tableList === "onboarded" && (
          <DataTable
            ownPaginationHandler={true}
            row={DriverData || []}
            columns={columns}
            loading={isLoading}
            paginationModel={trackTable[tableList].paginationModel}
            setPaginationModel={handlePagination}
            totalCount={trackTable[tableList].totalCount}
            columnVisibilityModel={columnVisibilityModel}
            setColumnVisibilityModel={setColumnVisibilityModel}
          />
        )}
        {tableList === "delete" && (
          <DataTable
            ownPaginationHandler={true}
            row={DriverData || []}
            columns={columns}
            loading={isLoading}
            paginationModel={trackTable[tableList].paginationModel}
            setPaginationModel={handlePagination}
            totalCount={trackTable[tableList].totalCount}
            columnVisibilityModel={columnVisibilityModel}
            setColumnVisibilityModel={setColumnVisibilityModel}
          />
        )}
        {tableList === "blocked" && (
          <DataTable
            ownPaginationHandler={true}
            row={DriverData || []}
            columns={columns}
            loading={isLoading}
            paginationModel={trackTable[tableList].paginationModel}
            setPaginationModel={handlePagination}
            totalCount={trackTable[tableList].totalCount}
            columnVisibilityModel={columnVisibilityModel}
            setColumnVisibilityModel={setColumnVisibilityModel}
          />
        )}
      </Box>

      <CustomConfirmationDialog
        open={conformationDialog.block}
        isGroup={true}
        value={false}
        handleState={handleConformationDailog}
        message={`Are you sure you want to ${
          accountStatus === "BLOCKED" ? "Unblock" : "Block "
        } ${driverName}?`}
        blockById={blockDriverByID}
        status="block"
      />
      <CustomConfirmationDialog
        open={conformationDialog.delete}
        isGroup={true}
        value={false}
        handleState={handleConformationDailog}
        message={`Are you sure you want to delete ${driverName}?`}
        deleteById={deleteDriverByID}
        status="delete"
      />

      {showSort.right && (
        <SwipeableDrawerComponent
          isOpen={showSort["right"]}
          onClose={toggleSortDrawer("right", false)}
          anchor="right"
        >
          <SortPanel
            toggleDrawer={toggleSortDrawer}
            sortOptions={sortFilterConfig?.options}
            // handleClear={handleClear}
            setState={setSort}
          />
        </SwipeableDrawerComponent>
      )}

      <SwipeableDrawerComponent
        isOpen={filter["right"]}
        anchor="right"
        onClose={toggleDrawer("right", false)}
      >
        <CustomFilter
          filterOptions={filterOptions}
          toggleDrawer={() => toggleDrawer("right", false)}
        />
      </SwipeableDrawerComponent>
    </>
  );
};

export default Driver;
