import { Box, Typography } from "@mui/material";
import React from "react";
import andriodFrame from "../../assets/modern-blank-android-device-mockup-frame 1.svg";
import appleFrame from "../../assets/digital-device-mockup 1.svg";

const NotificationContainer = ({
  title = "",
  description = "",
  headerText,
}) => {
  const notificationContainer = {
    position: "absolute",
    top: headerText === "Apple (IOS)" ? "46%" : "38%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    borderRadius: "5px",
    width: headerText === "Apple (IOS)" ? "80%" : "86%",
    boxShadow: "0px 0px 10px 0px #00000029",
    overflow: "hidden",
    backgroundColor: "white", // Added to ensure text visibility
    padding: "6px 6px",
    boxSizing: "border-box",
    display: "flex",
    flexDirection: "column",
    // justifyContent: "center",
    height: "42%",
  };

  const titleStyle = {
    fontFamily: "Proxima Nova",
    width: "100%",
    fontSize: "11px",
    fontWeight: 600,
    lineHeight: "12.18px",
    textAlign: "left",
    mb: "5px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  };

  const descriptionStyle = {
    fontFamily: "Proxima Nova",
    fontSize: "10px",
    fontWeight: 600,
    lineHeight: "12px",
    textAlign: "left",
    overflow: "hidden",
    display: "-webkit-box",
    WebkitBoxOrient: "vertical",
    WebkitLineClamp: 3,
    whiteSpace: "normal",
  };

  return (
    <Box sx={notificationContainer}>
      <Typography sx={{ ...titleStyle }} variant="subtitle1">
        {title}
      </Typography>
      <Typography sx={descriptionStyle} variant="body2">
        {description}
      </Typography>
    </Box>
  );
};

const PhoneFrame = ({ img = andriodFrame, title, description, headerText }) => {
  return (
    <Box
      sx={{
        position: "relative",
      }}
    >
      <NotificationContainer
        title={title}
        description={description}
        headerText={headerText}
      />
      <img
        src={headerText === "Apple (IOS)" ? appleFrame : andriodFrame}
        style={{
          display: "block",
          width: "100%",
        }}
        alt="mobile frame"
      />
    </Box>
  );
};
const MobileNotificationPreview = ({
  headerText = "Android",
  title,
  description,
}) => {
  const containerStyle = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    boxSizing: "border-box",
    maxWidth: "472px",
    width: "100%",
    height: "200px",
    backgroundColor: "#F3F4F7",
    alignItems: "center",
    borderRadius: "5px",
    padding: "8px 8px 0px 8px",
    position: "relative",
  };

  const headerTitleStyle = {
    position: "absolute",
    top: "14%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  };

  return (
    <Box sx={containerStyle}>
      <Typography sx={headerTitleStyle}>{headerText}</Typography>

      <PhoneFrame
        title={title}
        description={description}
        headerText={headerText}
      />
    </Box>
  );
};

export default MobileNotificationPreview;
