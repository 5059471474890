import { Box, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import crossIcon from "../assets/svg/crossIconNew.svg";
import dayjs from "dayjs";
import { useSearchParams } from "react-router-dom";
import { removeQuery } from "../utils/queryHelpers";
import useQueryParams from "../hooks/useQueryParams ";

//css

const filterListStyles = {
  fontFamily: "Proxima Nova A",
  background: "#fff",
  display: "flex",
  padding: "5px 8px 5px 8px",
  gap: "5px",
  alignItems: "center",
  borderRadius: "20px",
  border: "1px solid #0000004D",
};

const filterListTitle = {
  fontFamily: "Proxima Nova",
  fontSize: "14px",
  fontWeight: 600,
  lineHeight: "17.05px",
  textAlign: "left",
  color: "#000",
  marginRight: "8px",
};

const filterListvalue = {
  ...filterListTitle,
  color: "#6F747E",
  fontSize: "14px",
  fontWeight: 400,
  fontFamily: "Proxima Nova A",
  lineHeight: "17.05px",
};

const closeIconStyle = {
  color: "#000",
  height: "10px",
  width: "10px",
  cursor: "pointer",
};

const anyValuesPresent = (obj) => {
  if (!obj) return false;

  return Object.values(obj).every((value) => !!value);
};

const List = ({ data, format }) => {
  return (
    <Stack direction={"row"} spacing={"4px"} alignItems={"center"}>
      {Object.entries(data).map(([key, value], index) => (
        <React.Fragment key={key}>
          <span>{dayjs(value)?.format(format)}</span>

          {index === 0 && (
            <div
              style={{
                background: "rgb(111, 116, 126)",
                width: "9px",
                height: "2px",
                // marginRight: "2px",
              }}
            ></div>
          )}
        </React.Fragment>
      ))}
    </Stack>
  );
};

const getDataByName = (data) => {
  if (data?.date) {
    return <List data={data?.date?.customDateState} format={"MM-DD-YY"} />;
  }

  if (data?.time) {
    return <List data={data?.time?.timeState} format={"HH-MM"} />;
  }
};

const FilterListContainer = ({
  name,
  state,
  value,
  handleRemove,
  originalValue,
  originalName,
  showDate,
  showTime,
  filterName,
  setInputState,
  date,
  time,
  inputOptionsFilterName,
}) => {
  const dispatch = useDispatch();
  const { setSearchParams, removeMultipleQueries } = useQueryParams();
  return (
    <Box sx={filterListStyles}>
      <Stack direction={"row"} alignItems={"center"} justifyContent={"center"}>
        {showDate || showTime ? (
          getDataByName(state)
        ) : (
          <span style={filterListvalue}>{value}</span>
        )}
      </Stack>

      <img
        src={crossIcon}
        style={closeIconStyle}
        onClick={() => {
          if (filterName || time || date) {
            const options = [];

            if (filterName) {
              options.push({
                filterName,
              });
            }

            if (inputOptionsFilterName) {
              options.push({
                inputOptionsFilterName,
              });
            }

            if (date?.filterName) {
              options?.push({ date });
            }

            if (time?.filterName) {
              options?.push({ time });
            }

            removeMultipleQueries(options);
          }
          if (setInputState) {
            setInputState("");
          }
          if (state?.parentDispatch) {
            dispatch(state?.setState(""));
          } else if (state?.setState) {
            state?.setState("");
          } else if (handleRemove) {
            handleRemove(originalName, originalValue);
          }

          if (state?.date?.customDateState) {
            const dateState = {};

            Object.entries(state?.date?.customDateState ?? {}).forEach(
              ([key, _]) => {
                dateState[key] = null;
              }
            );

            state?.date?.isDispatch
              ? dispatch(state?.date.setState(dateState))
              : state?.date.setState(dateState);
          }

          if (state?.time?.timeState) {
            state?.time?.setState((prev) => {
              const newState = {};
              Object.entries(prev).forEach(([key, _]) => {
                newState[key] = null;
              });
              return newState;
            });
          }
        }}
        alt=""
      />
    </Box>
  );
};

const FilteredResults = ({ options = [], handleRemove }) => {
  const containerStyle = {
    display: "flex",
    flexWrap: "wrap",
    gap: "18px",
    width: "100%",
    marginTop: "18px",
  };

  return (
    <Box sx={containerStyle}>
      {options.length > 0 &&
        options.map((res, id) => {
          const showDate =
            !res?.state &&
            res?.date &&
            anyValuesPresent(res?.date?.customDateState);

          const showTime =
            !res?.state && res?.time && anyValuesPresent(res?.time?.timeState);

          if (res?.type === "input radio" && res?.state && res?.inputstate) {
            const findLabelName = res?.options?.find((ele) => {
              if (ele.value?.toString() === res.state) return true;
              return false;
            });

            /// this mapping is for input radio
            return ["", ""]?.map((_, index) => (
              <Box
                key={id + index}
                sx={{
                  display: "flex",
                  gap: "5px",
                  background: "#F0F0F0",
                  alignItems: "center",
                  flexWrap: "wrap",
                  padding: "5px 10px",
                  borderRadius: "10px",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "15px",
                    fontWeight: 600,
                    fontFamily: "Proxima Nova A",
                    lineHeight: "17.05px",
                  }}
                >
                  {res?.summryText}:
                </Typography>
                <FilterListContainer
                  name={res?.summryText}
                  value={index === 0 ? res?.state : res?.inputstate}
                  originalValue={res?.options}
                  originalName={res?.name}
                  stateFuntion={true}
                  handleRemove={res?.state}
                  state={res}
                  res={res}
                  inputOptionsFilterName={res?.inputOptionsFilterName}
                  showDate={showDate}
                  showTime={showTime}
                  setInputState={res?.inputSetState}
                  filterName={res?.filterName}
                />
              </Box>
            ));
          }

          // normal mapping
          if (res.state || showDate || showTime) {
            const findLabelName = res?.options?.find((ele) => {
              if (ele.value?.toString() === res.state) return true;
              return false;
            });
            return (
              <Box
                key={id}
                sx={{
                  display: "flex",
                  gap: "5px",
                  background: "#F0F0F0",
                  alignItems: "center",
                  flexWrap: "wrap",
                  padding: "5px 10px",
                  borderRadius: "10px",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "15px",
                    fontWeight: 600,
                    fontFamily: "Proxima Nova A",
                    lineHeight: "17.05px",
                  }}
                >
                  {res?.summryText}:
                </Typography>
                <FilterListContainer
                  name={res?.summryText}
                  value={
                    res?.type === "input" || "select"
                      ? res?.type === "select"
                        ? res?.state?.label
                        : res?.state
                      : findLabelName?.label
                  }
                  originalValue={res?.options}
                  date={res?.date}
                  time={res?.time}
                  originalName={res?.name}
                  stateFuntion={true}
                  handleRemove={res?.state}
                  state={res}
                  res={res}
                  showDate={showDate}
                  showTime={showTime}
                  filterName={res?.filterName}
                />
              </Box>
            );
          } else {
            // no value returning null
            return null;
          }
        })}
    </Box>
  );
};

export default FilteredResults;
