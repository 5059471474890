// ReusableTimePicker.js
import React from "react";
import PropTypes from "prop-types";
import dayjs from "dayjs";
import { TimePicker } from "@mui/x-date-pickers";
import useQueryParams from "../../../hooks/useQueryParams ";

const ReusableTimePicker = ({
  header,
  label,
  ampm = false,
  name,
  disabled = false,
  value,
  onChange = () => {},
  sx = {},
  setState,
  filterName,
  ...rest
}) => {
  const { setQuery, removeQuery } = useQueryParams();
  return (
    <TimePicker
      sx={{ marginTop: header ? "18px" : "", ...sx }}
      ampm={ampm}
      disabled={disabled}
      slotProps={{ textField: { size: "small" } }}
      label={label}
      value={value ? dayjs(value) : null}
      onChange={(newValue) => {
        if (newValue) {
          if (filterName) {
            setQuery(filterName, newValue.toISOString());
          }
        } else {
          removeQuery("time"); // Remove the time from query params
        }
        onChange(name, newValue.toISOString(), setState);
      }}
      {...rest}
    />
  );
};

ReusableTimePicker.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  sx: PropTypes.object, // Optional styling prop
};

export default ReusableTimePicker;
