import { Avatar, Box, Typography } from "@mui/material";
import { useTheme, useMediaQuery } from "@mui/material";

//css

const SharedCard = ({
  backgroundColor,
  title,
  svgicon,
  value,
  conatinerStyleAlt,
  titleStyleAlt,
  valueStyleAlt,
  handleClick = () => {},
  cursorType = "default",
  gap,
}) => {
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down("sm"));
  // const isMd = useMediaQuery(theme.breakpoints.between("sm", "md"));
  // const isLg = useMediaQuery(theme.breakpoints.up("md"));

  const containerStyle = {
    width: "100%",
    backgroundColor,
    height: isXs ? "fit-content" : "94px",
    borderRadius: "12px",
    padding: "10px 15px",
    boxSizing: "border-box",
    display: "flex",
    justifyContent: "space-between",
    boxShadow: "0px 0px 8px 0px rgba(150, 150, 150, 0.10)",
    alignItems: "center",
    cursor: cursorType,
    flexDirection: isXs ? "column" : "row",
    textAlign: isXs ? "center" : "left",
  };

  const titleStyle = {
    fontFamily: "Proxima Nova",
    fontSize: isXs ? "16px" : "20px",
    fontStyle: "normal",
    fontWeight: 500,
    color: "rgba(0, 0, 0, 0.70)",
    width: "100%",
    display: "flex",
        lineHeight: "1.2",
    flexWrap: "wrap",
    textTransform: "capitalize",
    cursor: cursorType,
  };

  const valueStyle = {
    textAlign: isXs ? "center" : "right",
    fontFamily: "Proxima Nova",
    fontSize: isXs ? "28px" : "34px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "normal",
    letterSpacing: "0.051px",
    color: "#000",
    cursor: cursorType,
    ...(isXs && { marginTop: "10px" }),
  };

  return (
    <Box
      sx={conatinerStyleAlt ?? containerStyle}
      onClick={() => handleClick(title)}
    >
      <Box
        sx={{
          display: "flex",
          gap: gap ?? "10px",
          alignItems: "center",
          justifyContent: isXs ? "center" : "flex-start",
          flexDirection: isXs ? "column" : "row",
        }}
      >
        <Box
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
          sx={{
            background: "rgba(0, 0, 0, 0.07)",
            height: "47px",
            width: "46px",
            borderRadius: "50px",
            gap: "15px",
            marginBottom: isXs ? "5px" : "0",
          }}
        >
          <Avatar
            sx={{
              width: "24px",
              height: "24px",
            }}
            src={svgicon}
          />
        </Box>
        <Typography  dangerouslySetInnerHTML={{ __html: title }} sx={titleStyleAlt ?? titleStyle}></Typography>
      </Box>

      <Typography sx={valueStyleAlt ?? valueStyle}>{value}</Typography>
    </Box>
  );
};

export default SharedCard;
