import { useEffect, useState } from "react";

const useDebounce = (value, delay) => {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    // Create a timer that will update debouncedValue after the delay
    const debounceTimer = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    // Clear the timer if the value or delay changes before the delay elapses
    return () => {
      clearTimeout(debounceTimer);
    };
  }, [value, delay]);

  return debouncedValue;
};

export default useDebounce;
