import { Box, Button, Divider, Typography, Stack } from "@mui/material";
import React, { useState } from "react";
import convertTimestampTo12HourFormat from "../utils/convertTimestampTo12HourFormat";
import CustomerDetails from "./CustomerDetails";
import SwipeableDrawerComponent from "./SwipeableDrawerComponent ";
import { useNavigate } from "react-router-dom";
import formatDate from "../utils/formateDate";
import { useSettings } from "../hooks/useSettings";

const commonTextStyles = {
  fontFamily: "Proxima Nova",
  fontStyle: "normal",
  lineHeight: "normal",
  letterSpacing: "0.32px",
  textTransform: "capitalize",
};

const titleTextStyle = {
  ...commonTextStyles,
  fontWeight: "400",
  fontSize: "16px",
  marginBottom: "5px",
};

const valueTextStyle = {
  ...commonTextStyles,
  fontWeight: "500",
  fontSize: "18px",
  letterSpacing: "0.36px",
  color: "#000",
};

const dateTextStyle = {
  color: "#000",
  fontFamily: "Proxima Nova",
  fontSize: "18px",
  fontWeight: "500",
  letterSpacing: "0.26px",
};

const timeTextStyle = {
  color: "#6F747E",
  fontFamily: "Proxima Nova",
  fontSize: "15px",
  fontWeight: "500",
  letterSpacing: "0.26px",
};

const containerStyle = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "flex-start",
  gap: "8px",
};

const dividerStyle = {
  width: "414px",
  margin: "20px auto",
  order: 4,
};

// css
const DetailsStyleContainer = {
  display: "inline-flex",
  flexDirection: "column",
  alignItems: "flex-start",
  width: "414px",
  margin: "5px auto",
  gap: "11px",
  paddingLeft: "10px",
};

const DetailsSideInnerConatiner = {
  display: "flex",
  width: "100%",
  justifyContent: "space-between",
};

const boxStyle = {
  ...DetailsStyleContainer,
  order: 5,
};

const RideDetails = ({
  sidePanelData,
  handleNavigate,
  role,
  setRiderId,
  priceBreakupObj,
  toggleDrawer,
  fetchFunction,
  riderId,
  anchor,
  showRating = true,
  specialFilter,
  DId,
  payment = {},
}) => {
  const [showCustomerDetails, setShowCustomerDetails] = useState({
    right: false,
  });

  const navigate = useNavigate();
  const { dateformat, formatDate, timezone, timeFormat } = useSettings();

  // Mui SlideDrawer Funtion
  const toggleCustomerDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setShowCustomerDetails({ ...showCustomerDetails, [anchor]: open });
  };

  const handleNavigateTickets = (path, id) => {
    navigate(path, { state: { id: id } });
  };

  const priceBreakFareName = [
    "Booking fee",
    "Base fare",
    "Distance (km)",
    "Ride time (mins)",
    "Surge fee",
    "Discount Coupon",
  ];

  return (
    <Box
      sx={{
        width: "100%",
        height: "auto",
        background: "#FFF",
        boxSizing: "border-box",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Box
            sx={{
              ...DetailsStyleContainer,
              order: role === "customer" ? 3 : 1,
            }}
          >
            <Box sx={DetailsSideInnerConatiner}>
              <Typography
                sx={{
                  fontFamily: "Proxima Nova",
                  fontStyle: "normal",
                  fontWeight: "400",
                  fontSize: "16px",
                  lineHeight: "normal",
                  letterSpacing: "0.32px",
                  textTransform: "capitalize",
                }}
              >{`Driver details`}</Typography>

              <Button
                sx={{
                  fontFamily: "Proxima Nova",
                  fontStyle: "normal",
                  fontWeight: "500",
                  fontSize: "14px",
                  lineHeight: "130%",
                  color: "#043A87",
                }}
                onClick={handleNavigate}
              >
                view more details
              </Button>
            </Box>

            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "flex-start",
                gap: "6px",
              }}
            >
              <Typography
                sx={{
                  fontFamily: " Proxima Nova",
                  fontSize: "18px",
                  fontWeight: "500",
                  lineHeight: "normal",
                  letterSpacing: "0.36px",
                  textTransform: "capitalize",
                  color: "#000",
                }}
              >
                {sidePanelData?.driver_details?.full_name ?? ""}
              </Typography>
              <Typography
                sx={{
                  fontFamily: "Proxima Nova",
                  fontSize: "16px",
                  fontWeight: "400",
                  lineHeight: "normal",
                  letterSpacing: "0.32px",
                  textTransform: "capitalize",
                  color: "rgba(0, 0, 0, 0.70))",
                }}
              >
                {sidePanelData?.driver_details?.phone ?? ""}
              </Typography>
              <Typography
                sx={{
                  fontFamily: "Proxima Nova",
                  fontSize: "16px",
                  fontWeight: "400",
                  lineHeight: "normal",
                  letterSpacing: "0.32px",
                  textTransform: "capitalize",
                  color: "rgba(0, 0, 0, 0.70))",
                }}
              >
                {sidePanelData?.driver_details?.email ?? ""}
              </Typography>
            </Box>
          </Box>
          <Divider
            sx={{
              width: "414px",
              margin: "20px auto",
              order: 2,
            }}
          />
          <Box
            sx={{
              ...DetailsStyleContainer,
              order: role === "customer" ? 1 : 3,
            }}
          >
            <Box sx={DetailsSideInnerConatiner}>
              <Typography
                sx={{
                  fontFamily: "Proxima Nova",
                  fontStyle: "normal",
                  fontWeight: "400",
                  fontSize: "16px",
                  lineHeight: "normal",
                  letterSpacing: "0.32px",
                  textTransform: "capitalize",
                }}
              >{`Customer details`}</Typography>

              <Button
                sx={{
                  fontFamily: "Proxima Nova",
                  fontStyle: "normal",
                  fontWeight: "500",
                  fontSize: "14px",
                  color: "#043A87",
                  lineHeight: "130%",
                }}
                onClick={(e) => {
                  setRiderId(sidePanelData?.rider_details?.id);
                  let callback = toggleCustomerDrawer("right", true);
                  callback(e);
                }}
              >
                view more details
              </Button>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "flex-start",
                gap: "6px",
              }}
            >
              <Typography
                sx={{
                  fontFamily: " Proxima Nova",
                  fontSize: "18px",
                  fontWeight: "500",
                  lineHeight: "normal",
                  letterSpacing: "0.36px",
                  textTransform: "capitalize",
                  color: "#000",
                }}
              >
                {sidePanelData?.rider_details?.full_name ?? ""}
              </Typography>
              <Typography
                sx={{
                  fontFamily: "Proxima Nova",
                  fontSize: "16px",
                  fontWeight: "400",
                  lineHeight: "normal",
                  letterSpacing: "0.32px",
                  textTransform: "capitalize",
                  color: "rgba(0, 0, 0, 0.70))",
                }}
              >
                {sidePanelData?.rider_details?.phone ?? ""}
              </Typography>
              <Typography
                sx={{
                  fontFamily: "Proxima Nova",
                  fontSize: "16px",
                  fontWeight: "400",
                  lineHeight: "normal",
                  letterSpacing: "0.32px",
                  textTransform: "capitalize",
                  color: "rgba(0, 0, 0, 0.70))",
                }}
              >
                {sidePanelData?.rider_details?.email ?? ""}
              </Typography>
            </Box>
          </Box>
          {(sidePanelData?.ride?.ride_cancellation_reason ||
            sidePanelData?.cancelled_by) && (
            <>
              <Divider
                sx={{
                  width: "414px",
                  margin: "20px auto",
                  order: 4,
                }}
              />
              <Box
                sx={{
                  ...DetailsStyleContainer,
                  order: 5,
                }}
              >
                <Box sx={DetailsSideInnerConatiner}>
                  <Typography
                    sx={{
                      fontFamily: "Proxima Nova",
                      fontStyle: "normal",
                      fontWeight: "400",
                      fontSize: "16px",
                      lineHeight: "normal",
                      letterSpacing: "0.32px",
                      textTransform: "capitalize",
                    }}
                  >{`Trip Cancellation Details`}</Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "flex-start",
                    gap: "8px",
                  }}
                >
                  {sidePanelData?.cancelled_by && (
                    <Stack>
                      <Typography
                        sx={{
                          fontFamily: "Proxima Nova",
                          fontStyle: "normal",
                          fontWeight: "400",
                          fontSize: "16px",
                          lineHeight: "normal",
                          letterSpacing: "0.32px",
                          textTransform: "capitalize",
                          mb: "5px",
                        }}
                      >
                        {"Cancelled By"}
                      </Typography>

                      <Typography
                        sx={{
                          fontFamily: " Proxima Nova",
                          fontSize: "18px",
                          fontWeight: "500",
                          lineHeight: "normal",
                          letterSpacing: "0.36px",
                          textTransform: "capitalize",
                          color: "#000",
                        }}
                      >
                        {sidePanelData?.cancelled_by === "RIDER"
                          ? "CUSTOMER"
                          : sidePanelData?.cancelled_by ?? ""}
                      </Typography>
                    </Stack>
                  )}

                  {sidePanelData?.ride_cancellation_reason && (
                    <Stack>
                      <Typography
                        sx={{
                          fontFamily: "Proxima Nova",
                          fontStyle: "normal",
                          fontWeight: "400",
                          fontSize: "16px",
                          lineHeight: "normal",
                          letterSpacing: "0.32px",
                          textTransform: "capitalize",
                          mb: "5px",
                        }}
                      >
                        Cancellation Reason
                      </Typography>
                      <Typography
                        sx={{
                          fontFamily: " Proxima Nova",
                          fontSize: "18px",
                          fontWeight: "500",
                          lineHeight: "normal",
                          letterSpacing: "0.36px",
                          textTransform: "capitalize",
                          color: "#000",
                        }}
                      >
                        {sidePanelData?.ride_cancellation_reason ?? ""}
                      </Typography>
                    </Stack>
                  )}
                </Box>
              </Box>
            </>
          )}

          {/*  */}

          {(sidePanelData?.ride?.unflagged_by ||
            sidePanelData?.unflag_time ||
            sidePanelData?.unflag_remark) && (
            <>
              <Divider sx={dividerStyle} />
              <Box sx={boxStyle}>
                <Box sx={DetailsSideInnerConatiner}>
                  <Typography sx={titleTextStyle}>
                    {`Trip unflagged Details`}
                  </Typography>
                </Box>
                <Box sx={containerStyle}>
                  {sidePanelData?.unflagged_by && (
                    <Stack>
                      <Typography sx={titleTextStyle}>
                        {"Unflagged by"}
                      </Typography>
                      <Typography sx={valueTextStyle}>
                        {sidePanelData?.unflagged_by}
                      </Typography>
                    </Stack>
                  )}
                  {sidePanelData?.unflag_time && (
                    <Stack>
                      <Typography sx={titleTextStyle}>Unflagged at</Typography>
                      <Stack
                        direction={"row"}
                        gap={"5px"}
                        alignItems={"flex-end"}
                      >
                        <Typography sx={dateTextStyle}>
                          {formatDate(
                            sidePanelData?.unflag_time,
                            timezone,
                            dateformat
                          )}
                        </Typography>
                        <Typography sx={timeTextStyle}>
                          {formatDate(
                            sidePanelData?.unflag_time,
                            timezone,
                            timeFormat
                          )}
                        </Typography>
                      </Stack>
                    </Stack>
                  )}
                  {sidePanelData?.unflag_remark && (
                    <Stack>
                      <Typography sx={titleTextStyle}>
                        Unflagged Reason
                      </Typography>
                      <Typography sx={valueTextStyle}>
                        {sidePanelData?.unflag_remark ?? ""}
                      </Typography>
                    </Stack>
                  )}
                </Box>
              </Box>
            </>
          )}

          {/*  */}
        </Box>
      </Box>
      <Divider
        sx={{
          width: "414px",
          margin: "20px auto",
        }}
      />
      <Box
        sx={{
          width: "414px",
          margin: "auto",
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          gap: "19px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            gap: "10px",
            width: "406px",
          }}
        >
          <Typography
            sx={{
              textAlign: "center",
              fontSize: "16px",
              fontFamily: "Proxima Nova",
              fontStyle: "normal",
              fontWeight: "400",
              lineHeight: "normal",
              letterSpacing: "0.32px",
              textTransform: "capitalize",
            }}
          >
            Ride distance
          </Typography>
          <Typography
            sx={{
              fontSize: "16px",
              fontStyle: "normal",
              color: "#000",
              lineHeight: "30%",
            }}
          >
            {typeof sidePanelData?.initial_ride_distance_in_km === "number" &&
              `${sidePanelData?.initial_ride_distance_in_km ?? 0} km`}
          </Typography>
        </Box>

        <Box
          sx={{
            width: "414px",
            margin: "auto",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            gap: "19px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              gap: "10px",
              width: "406px",
            }}
          >
            <Typography
              sx={{
                textAlign: "center",
                fontSize: "16px",
                fontFamily: "Proxima Nova",
                fontStyle: "normal",
                fontWeight: "400",
                lineHeight: "normal",
                letterSpacing: "0.32px",
                textTransform: "capitalize",
              }}
            >
              {`From (${
                sidePanelData?.ride_start_time
                  ? formatDate(
                      sidePanelData?.ride_start_time ?? "",
                      timezone,
                      timeFormat
                    )
                  : ""
              })`}
            </Typography>
            <Typography
              sx={{
                fontSize: "16px",
                fontStyle: "normal",
                color: "#000",
                fontWeight: "400",
                lineHeight: "130%",
                textTransform: "capitalize",
              }}
            >
              {sidePanelData?.pickup_location?.address_text ?? ""}
            </Typography>
          </Box>

          {sidePanelData?.stops?.length > 0 && (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                gap: "10px",
                width: "406px",
              }}
            >
              <Typography
                sx={{
                  textAlign: "center",
                  fontSize: "16px",
                  fontFamily: "Proxima Nova",
                  fontStyle: "normal",
                  fontWeight: "400",
                  lineHeight: "normal",
                  letterSpacing: "0.32px",
                  textTransform: "capitalize",
                }}
              >
                {`Stops`}
              </Typography>

              <Stack>
                {sidePanelData.stops.map(({ address_text }) => (
                  <Typography
                    key={address_text}
                    sx={{
                      fontSize: "16px",
                      fontStyle: "normal",
                      color: "#000",
                      fontWeight: "400",
                      lineHeight: "130%",
                      textTransform: "capitalize",
                    }}
                  >
                    {address_text ?? ""}
                  </Typography>
                ))}
              </Stack>
            </Box>
          )}

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              gap: "10px",
              width: "406px",
            }}
          >
            <Typography
              sx={{
                textAlign: "center",
                fontSize: "16px",
                fontFamily: "Proxima Nova",
                fontStyle: "normal",
                fontWeight: "400",
                lineHeight: "normal",
                letterSpacing: "0.32px",
                textTransform: "capitalize",
              }}
            >
              {`To (${
                sidePanelData?.ride_end_time
                  ? formatDate(
                      sidePanelData?.ride_end_time ?? "",
                      timezone,
                      timeFormat
                    )
                  : ""
              })`}
            </Typography>
            <Typography
              sx={{
                fontSize: "16px",
                fontStyle: "normal",
                color: "#000",
                fontWeight: "400",
                lineHeight: "130%",
                textTransform: "capitalize",
              }}
            >
              {sidePanelData?.dropoff_location?.address_text ?? ""}
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          padding: "24px 16px",
          justifyContent: "center",
          alignItems: "center",
          boxSizing: "border-box",
          width: "414px",
          margin: "20px auto",
          background: "rgba(111, 116, 126, 0.09)",
          borderRadius: "8px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            padding: "0px",
            gap: "18px",
            width: "100%",
            boxSizing: "border-box",
          }}
        >
          <Typography
            sx={{
              color: "#000",
              padding: "0px",
              fontFamily: "Proxima Nova",
              fontSize: "16px",
              textAlign: "start",
              fontWeight: "600",
              letterSpacing: "0.32px",
              textTransform: "capitalize",
            }}
          >
            Fare Breakup
          </Typography>
          <Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                gap: "8px",
              }}
            >
              {Object.entries(priceBreakupObj).length > 0 &&
                Object.entries(priceBreakupObj).map(([value, res], index) => {
                  return (
                    <Box
                      key={priceBreakFareName[index]}
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "flex-start",
                        width: "100%",
                      }}
                    >
                      <Typography
                        sx={{
                          color: "rgba(15, 24, 40, 0.70)",
                          fontFamily: "Proxima Nova",
                          fontSize: "16px",
                          width: "215px",
                          letterSpacing: "0.32px",
                          lineHeight: "130%",
                          textAlign: "start",
                          textTransform: "capitalize",
                        }}
                      >
                        {priceBreakFareName[index]}
                      </Typography>

                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "flex-end",
                          alignItems: "center",
                          width: "100%",
                          gap: "3px",
                        }}
                      >
                        <Typography
                          sx={{
                            color: "#0F1828",
                            fontFamily: "Proxima Nova",
                            fontSize: "20px",
                            letterSpacing: "-0.408px",
                            lineHeight: "22px",
                            fontWeight: "400",
                            textAlign: "start",
                            textTransform: "capitalize",
                          }}
                        >
                          {value === "coupon_discount_amount" ? "-₱" : "₱"}
                        </Typography>
                        <Typography
                          sx={{
                            color: "#0F1828",
                            fontFamily: "Proxima Nova",
                            fontSize: "20px",
                            letterSpacing: "-0.408px",
                            lineHeight: "22px",
                            fontWeight: "400",
                            textAlign: "start",
                            textTransform: "capitalize",
                          }}
                        >
                          {res}
                        </Typography>
                      </Box>
                    </Box>
                  );
                })}
            </Box>
            <Divider
              sx={{ background: "rgba(0, 0, 0, 0.30)", margin: "10px 0" }}
            />
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "flex-start",
              }}
            >
              <Typography
                sx={{
                  color: "$000",
                  fontFamily: "Proxima Nova",
                  fontSize: "16px",
                  letterSpacing: "0.32px",
                  lineHeight: "130%",
                  fontWeight: "600",
                  textAlign: "start",
                  textTransform: "capitalize",
                }}
              >
                Total
              </Typography>

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                  gap: "3px",
                }}
              >
                <Typography
                  sx={{
                    color: "#0F1828",
                    fontFamily: "Proxima Nova",
                    fontSize: "20px",
                    letterSpacing: "-0.408px",
                    lineHeight: "22px",
                    fontWeight: "400",
                    textAlign: "start",
                    textTransform: "capitalize",
                  }}
                >
                  ₱
                </Typography>
                <Typography
                  sx={{
                    color: "#0F1828",
                    fontFamily: "Proxima Nova",
                    fontSize: "20px",
                    letterSpacing: "-0.408px",
                    lineHeight: "22px",
                    fontWeight: "400",
                    textAlign: "start",
                    textTransform: "capitalize",
                  }}
                >
                  {sidePanelData?.price_breakup?.total_fare ??
                    0 - sidePanelData?.price_breakup?.coupon_discount_amount ??
                    0}
                </Typography>
              </Box>
            </Box>

            {Object?.entries(payment)?.length > 0 &&
              Object.entries(payment)?.map((payment) => {
                if (payment[0] === undefined || payment[0] === null) {
                  return false;
                }

                return (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "flex-start",
                      mt: "9px",
                    }}
                  >
                    <Typography
                      sx={{
                        color: "#000",
                        fontFamily: "Proxima Nova",
                        fontSize: "16px",
                        letterSpacing: "0.32px",
                        lineHeight: "130%",
                        fontWeight: "600",
                        textAlign: "start",
                        textTransform: "capitalize",
                      }}
                    >
                      {payment[0]?.replace("_", " ")}
                    </Typography>

                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                        alignItems: "center",
                        gap: "3px",
                      }}
                    >
                      <Typography
                        sx={{
                          color: "#0F1828",
                          fontFamily: "Proxima Nova",
                          fontSize: "16px",
                          letterSpacing: "0.32px",
                          lineHeight: "130%",
                          fontWeight: "600",
                          textAlign: "start",
                          textTransform: "capitalize",
                        }}
                      >
                        {payment[1]?.toLowerCase()}
                      </Typography>
                    </Box>
                  </Box>
                );
              })}
          </Box>
        </Box>
      </Box>
      {showRating && role === "" && (
        <Box
          sx={{
            width: "414px",
            margin: "auto",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            gap: "19px",
          }}
        >
          {sidePanelData?.rating && (
            <>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <Typography
                  sx={{
                    color: "rgba(0, 0, 0, 0.70)",
                    fontFamily: "Proxima Nova",
                    fontSize: "16pxpx",
                    letterSpacing: "0.32px",
                    lineHeight: "normal",
                    fontWeight: "400",
                    textAlign: "start",
                    textTransform: "capitalize",
                  }}
                >
                  Rating & review
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "flex-end",
                      alignItems: "center",
                      gap: "8px",
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="15"
                      height="15"
                      viewBox="0 0 15 15"
                      fill="none"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M6.17521 0.799611C6.50776 0.365328 6.91879 0 7.5 0C8.08121 0 8.49224 0.365328 8.82479 0.79961C9.15155 1.22633 9.49805 1.84799 9.92153 2.60777L10.1717 3.05658C10.4456 3.54787 10.5156 3.65224 10.6035 3.71895C10.6881 3.78316 10.7972 3.81897 11.3298 3.93949L11.8182 4.04999C12.6383 4.23554 13.314 4.38841 13.8061 4.58235C14.3169 4.78366 14.7639 5.08038 14.936 5.63379C15.1063 6.18151 14.9149 6.68541 14.6191 7.15536C14.3317 7.61197 13.8726 8.14881 13.3118 8.80459L12.9807 9.19172C12.62 9.61352 12.5447 9.71673 12.5093 9.83063C12.4732 9.94676 12.4766 10.081 12.5311 10.6433L12.581 11.1583C12.6659 12.0346 12.7351 12.7492 12.7109 13.2934C12.6862 13.8473 12.5604 14.3793 12.1033 14.7264C11.6376 15.0799 11.0957 15.0401 10.5727 14.8902C10.0655 14.7449 9.43319 14.4537 8.66541 14.1001L8.208 13.8895C7.70755 13.6591 7.60041 13.6221 7.5 13.6221C7.39959 13.6221 7.29245 13.6591 6.792 13.8895L6.33459 14.1001C5.56681 14.4537 4.93452 14.7449 4.42733 14.8902C3.90425 15.0401 3.36242 15.0799 2.89673 14.7264C2.43958 14.3793 2.31377 13.8473 2.28909 13.2934C2.26485 12.7492 2.33411 12.0346 2.41904 11.1582L2.46894 10.6433C2.52343 10.081 2.52683 9.94676 2.49072 9.83063C2.4553 9.71673 2.37995 9.61352 2.01927 9.19172L1.68825 8.80461C1.12745 8.14883 0.668352 7.61197 0.380922 7.15536C0.0850999 6.68541 -0.106302 6.18151 0.0640111 5.63379C0.236098 5.08038 0.683099 4.78366 1.1939 4.58235C1.68599 4.38841 2.36168 4.23554 3.18185 4.04999L3.22626 4.03994L3.67021 3.93949C4.20281 3.81897 4.31193 3.78316 4.39651 3.71895C4.48438 3.65224 4.55444 3.54787 4.82829 3.05658L5.07847 2.60776C5.50195 1.84799 5.84845 1.22633 6.17521 0.799611Z"
                        fill="#FFC940"
                      />
                    </svg>
                    <Typography>{`(${sidePanelData?.rating?.rating} Star)`}</Typography>
                  </Box>
                </Box>
              </Box>

              {sidePanelData?.rating?.review && (
                <Typography
                  sx={{
                    color: "#0F1828",
                    width: "414px",
                    fontFamily: "Proxima Nova",
                    fontSize: "16px",
                    lineHeight: "130%",
                    fontStyle: "normal",
                    fontWeight: "400",
                  }}
                >
                  {sidePanelData?.rating?.review}
                </Typography>
              )}
            </>
          )}
        </Box>
      )}
      {sidePanelData?.coupon_code && (
        <Box
          sx={{
            widows: "414px",
            margin: "auto",
            paddingLeft: "11px",
          }}
        >
          <Typography
            sx={{
              color: "rgba(0, 0, 0, 0.70)",
              fontFamily: "Proxima Nova",
              fontSize: "16px",
              fontStyle: "normal",
              fontWeight: "400",
              lineHeight: "normal",
              letterSpacing: "0.32px",
              textTransform: "capitalize",
              margin: "10px 0",
            }}
          >
            Applied Coupon
          </Typography>

          <Typography
            sx={{
              fontFamily: "Proxima Nova",
              fontSize: "18px",
              fontStyle: "normal",
              fontWeight: "600",
              lineHeight: "23px",
              textTransform: "capitalize",
            }}
          >
            {sidePanelData?.coupon_code}
          </Typography>
        </Box>
      )}

      {showRating && (
        <Divider
          sx={{
            width: "414px",
            margin: "20px auto",
            background: "rgba(0, 0, 0, 0.14)",
          }}
        />
      )}

      {sidePanelData?.rider_ticket || sidePanelData?.driver_ticket ? (
        <Stack spacing={2}>
          {sidePanelData?.rider_ticket?.length > 0 && (
            <Stack>
              <Typography
                variant="h6"
                sx={{
                  color: "rgba(0, 0, 0, 0.70)",
                  fontSize: "16px",
                  fontFamily: "Proxima Nova",
                  m: "0 0 0 8px",
                  lineHeight: "normal",
                  letterSpacing: "0.23px",
                  fontWeight: "400",
                  textTransform: "capitalize",
                }}
              >
                Customer Tickets
                {`(${sidePanelData?.rider_ticket?.length ?? 0})`}
              </Typography>

              <Box
                sx={{
                  width: "414px",
                  margin: "auto",
                }}
              >
                <Box
                  sx={{
                    margin: "12px 0",
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                    background: "#fff",
                  }}
                >
                  {sidePanelData?.rider_ticket?.map((ticketData) => (
                    <Box
                      sx={{
                        padding: "10px",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                        gap: "10px",
                        border: "0.5px solid  rgba(0, 0, 0, 0.30)",
                        borderRadius: "8px",
                      }}
                      key={ticketData.id}
                    >
                      <Box
                        sx={{
                          width: "100%",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <Button
                          sx={{
                            margin: "0",
                            padding: "0",
                            boxSizing: "border-box",
                          }}
                          onClick={() => {
                            handleNavigateTickets(
                              "/customer-tickets",
                              ticketData?.id ?? ""
                            );
                          }}
                        >
                          <Typography
                            sx={{
                              color: "#0F1828",
                              fontFamily: "Proxima Nova",
                              fontSize: "16px",
                              fontStyle: "normal",
                              fontWeight: "600",
                              lineHeight: "23px",
                            }}
                          >
                            {ticketData.ticket_id}
                          </Typography>
                        </Button>
                        <Typography
                          sx={{
                            color: "#F98244",
                            fontFamily: "Proxima Nova",
                            fontSize: "12px",
                            fontStyle: "normal",
                            fontWeight: "500",
                            textTransform: "capitalize",
                            letterSpacing: "0.24px",
                          }}
                        >
                          {ticketData.status}
                        </Typography>
                      </Box>

                      <Typography
                        sx={{
                          fontSize: "16px",
                          fontSTyle: "normal",
                          fontWeight: "400",
                          lineHeight: "23px",
                          textTransform: "capitalize",
                        }}
                      >
                        {ticketData.description}
                      </Typography>
                    </Box>
                  ))}
                </Box>
              </Box>
            </Stack>
          )}

          {sidePanelData?.driver_ticket?.length > 0 && (
            <Stack>
              <Typography
                variant="h6"
                sx={{
                  color: "rgba(0, 0, 0, 0.70)",
                  fontSize: "16px",
                  fontFamily: "Proxima Nova",
                  lineHeight: "normal",
                  letterSpacing: "0.23px",
                  m: "0 0 0 8px",
                  fontWeight: "400",
                  textTransform: "capitalize",
                }}
              >
                Driver Tickets{" "}
                {`(${sidePanelData?.driver_ticket?.length ?? 0})`}
              </Typography>

              <Box
                sx={{
                  width: "414px",
                  margin: "auto",
                }}
              >
                <Box
                  sx={{
                    margin: "12px 0",
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                    background: "#fff",
                  }}
                >
                  {sidePanelData?.driver_ticket?.map((ticketData) => (
                    <Box
                      sx={{
                        padding: "10px",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                        gap: "10px",
                        border: "0.5px solid  rgba(0, 0, 0, 0.30)",
                        borderRadius: "8px",
                      }}
                      key={ticketData.id}
                    >
                      <Box
                        sx={{
                          width: "100%",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <Button
                          sx={{
                            margin: "0",
                            padding: "0",
                            boxSizing: "border-box",
                          }}
                          onClick={() => {
                            handleNavigateTickets(
                              "/driver-tickets",
                              ticketData?.id ?? ""
                            );
                          }}
                        >
                          <Typography
                            sx={{
                              color: "#0F1828",
                              fontFamily: "Proxima Nova",
                              fontSize: "16px",
                              fontStyle: "normal",
                              fontWeight: "600",
                              lineHeight: "23px",
                            }}
                          >
                            {ticketData.ticket_id}
                          </Typography>
                        </Button>
                        <Typography
                          sx={{
                            color: "#F98244",
                            fontFamily: "Proxima Nova",
                            fontSize: "12px",
                            fontStyle: "normal",
                            fontWeight: "500",
                            textTransform: "capitalize",
                            letterSpacing: "0.24px",
                          }}
                        >
                          {ticketData.status}
                        </Typography>
                      </Box>

                      <Typography
                        sx={{
                          fontSize: "16px",
                          fontSTyle: "normal",
                          fontWeight: "400",
                          lineHeight: "23px",
                          textTransform: "capitalize",
                        }}
                      >
                        {ticketData.description}
                      </Typography>
                    </Box>
                  ))}
                </Box>
              </Box>
            </Stack>
          )}
        </Stack>
      ) : (
        <Box
          sx={{
            margin: "12px 0",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography
            sx={{
              fontSize: "16px",
              fontSTyle: "normal",
              fontWeight: "400",
              lineHeight: "23px",
              textTransform: "capitalize",
              color: "rgba(0, 0, 0, 0.70",
              textAlign: "center",
              fontFamily: "Proxima Nova",
            }}
          >
            No Tickets Raised
          </Typography>
        </Box>
      )}

      <SwipeableDrawerComponent
        isOpen={showCustomerDetails["right"]}
        anchor="right"
        onClose={toggleCustomerDrawer("right", false)}
      >
        <CustomerDetails
          anchor={anchor}
          riderId={riderId}
          setRiderId={setRiderId}
          toggleDrawer={toggleCustomerDrawer}
          toggleDriverDrawer={toggleDrawer}
          fetchFunction={fetchFunction}
        />
      </SwipeableDrawerComponent>
    </Box>
  );
};

export default RideDetails;
