// sosSlice.js
import { createSlice } from "@reduxjs/toolkit";

const sosSlice = createSlice({
  name: "sos",
  initialState: {
    dataMap: {},
  },
  reducers: {
    updateSosData: (state, action) => {
      const incomingData = action.payload;

      // Update the dataMap based on the incoming data's ID
      if (incomingData?.data?.id in state.dataMap) {
        if (
          state.dataMap[incomingData?.data?.id] &&
          incomingData?.type === "rider_offline"
        ) {
          delete state.dataMap[incomingData.data.id];
        } else if (
          state.dataMap[incomingData?.data?.id] &&
          state.dataMap[incomingData?.data?.id].type !== "rider_offline" &&
          state.dataMap[incomingData.data.id]
        ) {
          state.dataMap[incomingData?.data?.id] = {
            ...state.dataMap[incomingData.data.id],
            ...incomingData,
          };
        }
      } else {
        if (incomingData?.data?.id && incomingData.type !== "rider_offline") {
          state.dataMap[incomingData?.data?.id] = incomingData;
        }
      }
    },
  },
});

export const { updateSosData } = sosSlice.actions;
export default sosSlice.reducer;
