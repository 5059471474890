function filterObjectsWithProperties(arr) {
  const result = [];

  for (const obj of arr) {
    if (
      typeof obj === "object" &&
      obj !== null &&
      "document_title_slug" in obj &&
      ("document_front" in obj || "document_back" in obj) &&
      Object.keys(obj).length >= 2
    ) {
      result.push(obj);
    }
  }

  return result;
}

export default filterObjectsWithProperties;
