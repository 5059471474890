import React from "react";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import { Checkbox, FormGroup } from "@mui/material";
import PropTypes from "prop-types";
import useQueryParams from "../../../hooks/useQueryParams ";

const ReusableCheckboxGroup = ({
  value,
  onChange = () => {},
  options,
  name,
  isSingleSelect = true, // New prop to control single or multiple selection
  filterName,
}) => {
  const { setQuery } = useQueryParams();

  return (
    <React.Fragment>
      <FormControl>
        <FormGroup
          name={name}
          value={value}
          sx={{
            display: "flex",
            boxSizing: "border-box",
            width: "365px",
            alignItems: "flex-start",
            alignContent: "flex-start",
            flexDirection: "column",
            gap: "0px",
            margin: "auto",
          }}
        >
          {options?.map((option) => (
            <FormControlLabel
              key={option.value}
              value={option.value}
              label={option.label}
              control={
                <Checkbox
                  sx={{
                    color: "#0F1828",
                    "&.Mui-checked": {
                      color: "#0F1828", // Checked color
                    },
                  }}
                />
              }
              checked={
                isSingleSelect
                  ? value === option.value // Single selection logic
                  : value?.includes(
                      typeof option.value === "string"
                        ? option.value
                        : String(option.value)
                    )
              }
              onChange={(e) => {
                e.stopPropagation();

                if (isSingleSelect) {
                  // Single selection: clear all previous selections
                  if (filterName) {
                    setQuery(filterName, e.target.value);
                  }
                  onChange(name, e.target.value, true);
                } else {
                  // Multiple selection logic
                  const selectedValue = e.target.value;
                  const isChecked = e.target.checked;
                  const updatedValues = isChecked
                    ? [...value, selectedValue] // Add the selected value
                    : value.filter((v) => v !== selectedValue); // Remove the unselected value

                  onChange(name, updatedValues, isChecked);
                }
              }}
              sx={{
                color: "#272728",
                fontWeight: 400,
                fontSize: "14px",
                fontFamily: "Proxima Nova A",
                lineHeight: "17.05px",
              }}
            />
          ))}
        </FormGroup>
      </FormControl>
    </React.Fragment>
  );
};

ReusableCheckboxGroup.propTypes = {
  value: PropTypes.string.isRequired,
  isSingleSelect: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    })
  ).isRequired,
  isDispatch: PropTypes.bool, // or adjust according to your needs
};

export default ReusableCheckboxGroup;
