import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import DataTable from "./DataTable";
import {
  Box,
  Button,
  IconButton,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { showErrorToast, showSuccessToast } from "../utils/toastUtils";
import ClearIcon from "@mui/icons-material/Clear";
import CheckIcon from "@mui/icons-material/Check";
import axiosInstance from "../utils/axiosConfig";
import geofencemarker from "../assets/geoFenceMarker.svg";
import creategeofencemarker from "../assets/geoFenceCreateMarkerSvg.svg";
import CustomConfirmationDialog from "./CustomConfirmationDialog";
import { CheckBox } from "@mui/icons-material";
import QuickFilter from "./QuickFilter";
import usePermission from "../hooks/usePermission";
import CircularLoader from "./CircularLoader";
import { useSettings } from "../hooks/useSettings";

const GenericText = ({ variant = "body1", text, css = "" }) => {
  const typoStyles = {
    color: "var(--text-dark, #000)",
    textAlign: "left",
    fontFamily: "Proxima Nova",
    fontSize: "13px",
    fontStyle: "normal",
    width: "210px",
    overflow: "hidden",
    fontWeight: 400,
    lineHeight: "normal",
    letterSpacing: "0.26px",
    textTransform: "capitalize",
  };
  return (
    <Typography variant={variant} sx={css ? { ...css } : { ...typoStyles }}>
      {text}
    </Typography>
  );
};

const ToggleButton = ({
  toggledrawer = () => () => {},
  setid = () => {},
  id,
  handleToggle = () => {},
  text,
}) => {
  const textCss = {
    color: "#2d5a9b",
    fontFamily: "Proxima Nova",
    fontSize: "13px",
    textAlign: "left",
    width: "210px",
    overflow: "hidden",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "normal",
    letterSpacing: "0.26px",
    textTransform: "capitalize",
  };

  return (
    <Button
      variant="text"
      sx={{
        width: "100%",
        height: "100%",
      }}
      onClick={(e) => {
        e.stopPropagation();
        setid(id);
        // this  toggledrawer funtion return a funtion then we are passing event on it
        toggledrawer("right", true)(e);
        handleToggle(id);
      }}
    >
      <GenericText text={text} css={textCss} />
    </Button>
  );
};

const TableAction = ({
  handleEditClick,
  permissions,
  id,
  setEdit,
  selectedShape,
  updatedCoords = [],
  setUpdatedCoords,
  createMark,
  setCreateMark,
  polygon,
  viewPolygonLocation,
  params,
  setStaticData,
  setDeleteModel,
  setId,
  checkId,
  setIsServiceable,
  setDeleteId,
  scrollToTop,
  setIsServiceableRef,
  setDeleteName,
  isHotzone,
  setMarkerName,
  markerName,
}) => {
  const patchPolygoan = async (isserviceable, isHotzone) => {
    let url = `/crm/geofence/${id}/`;

    let updateData = {};

    let addHotSpotMarker = [];
    console.log(createMark, "marker");

    if (createMark.length > 0) {
      createMark.forEach(([lat, lng, marker], index) => {
        console.log(lat, lng);
        if (marker) {
          marker.setMap(null);
        }
        addHotSpotMarker.push({
          name: markerName[index],
          point: [lng, lat],
        });
      });
    }

    // if (polygon && polygon[0]?.hotspots && polygon[0].hotspots?.length > 0) {
    //   polygon[0].hotspots.forEach((res) => {
    //     addHotSpotMarker.push([res.position.lng(), res.position.lat()]);
    //   });
    // }

    if (addHotSpotMarker.length > 0 && !isserviceable && !isHotzone) {
      updateData["hotspot_points"] = addHotSpotMarker;
    }

    if (updatedCoords.length > 0) {
      const firstCoordinate = updatedCoords[0];
      const lastCoordinate = updatedCoords[updatedCoords.length - 1];
      if (
        firstCoordinate[0] !== lastCoordinate[0] &&
        firstCoordinate[1] !== lastCoordinate[1]
      ) {
        updatedCoords.push(firstCoordinate);
      }
      updateData["polygon_coordinates"] = updatedCoords;
    }

    if (addHotSpotMarker.length === 0 && updatedCoords.length === 0) return;

    if (Object.keys(updateData).length === 0) return;

    try {
      await axiosInstance.patch(url, updateData).then((res) => {
        showSuccessToast("Updated Successfully");
        setStaticData((prev) => {
          let newdata = prev.map((prev) =>
            prev.id === id ? res.data.data : prev
          );
          return newdata;
        });

        viewPolygonLocation(
          res.data.data.polygon.coordinates[0],
          res.data.data.hotspot_points,
          id,
          false,
          true,
          res.data.data.is_serviceable,
          res?.data?.data?.is_hotzone ?? false
        );
      });
    } catch (error) {
      showErrorToast(error);
    } finally {
      setMarkerName([]);
      setCreateMark([]);
      setEdit(false);
      setUpdatedCoords();
    }
  };

  return (
    <Box sx={{ display: "flex" }}>
      {permissions?.UPDATE &&
        (checkId !== id ? (
          <IconButton
            onClick={(e) => {
              e.stopPropagation();
              const is_serviceable = params.row.is_serviceable;
              const is_hotzone = params?.row?.is_hotzone ?? false;

              if (params?.row?.hotspot_points) {
                const pointArray = [];
                const nameList = [];
                params?.row?.hotspot_points?.forEach((res) => {
                  if (typeof res === "object" && !Array.isArray(res)) {
                    const { name, point } = res;
                    pointArray.push([point[1], point[0]]);

                    nameList.push(name ?? "");
                  } else {
                    pointArray.push(res);
                  }
                });

                if (nameList) {
                  console.log(nameList);
                  setMarkerName(nameList);
                }

                setCreateMark(pointArray);
              }

              setId(id);
              setIsServiceable(params.row.is_serviceable);
              setIsServiceableRef(is_serviceable);
              isHotzone.current = is_hotzone;
              handleEditClick(id);
              scrollToTop();
            }}
          >
            <EditIcon />
          </IconButton>
        ) : (
          <Box sx={{ border: "1px solid #363636" }}>
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                setId("");
                setEdit(false);
                if (selectedShape?.setEditable) {
                  selectedShape?.setEditable(false);
                }

                viewPolygonLocation(
                  params.row.polygon.coordinates[0],
                  params.row.hotspot_points,
                  id,
                  true,
                  false,
                  params.row.is_serviceable,
                  params?.row?.is_hotzone ?? false
                );
              }}
            >
              <ClearIcon />
            </IconButton>
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                setId("");
                if (selectedShape?.setEditable) {
                  selectedShape?.setEditable(false);
                }
                patchPolygoan(
                  params.row.is_serviceable,
                  params?.row?.is_hotzone ?? false
                );

                setCreateMark([]);
                setUpdatedCoords([]);
              }}
            >
              <CheckIcon />
            </IconButton>
          </Box>
        ))}
      {permissions?.DELETE && (
        <IconButton
          onClick={() => {
            setDeleteModel(true);
            setDeleteId(id);
            setDeleteName(params.row.name);
          }}
        >
          <DeleteIcon />
        </IconButton>
      )}
    </Box>
  );
};

function stokeAndFillWithLabel(name) {
  switch (name) {
    case "serviceable":
      return { strokeColor: "#F1554C", fill: "rgba(241, 85, 76, 0.20)" };
    case "hotzone":
      return { strokeColor: "#FFFF00", fill: "rgba(241, 85, 76, 0.20)" };
    case "hotspot":
      return { strokeColor: "#009D0F", fill: "rgba(0, 157, 15, 0.20)" }; // Added a closing parenthesis
    default:
      return;
  }
}

const GeoFence = ({ create, setCreate }) => {
  const permissions = usePermission();
  const [map, setMap] = useState(null);

  const nameDeleteRef = useRef("");
  const [drawingManager, setDrawingManager] = useState(null);
  const [selectedShape, setSelectedShape] = useState(null);
  const [coordinates, setCoordinates] = useState([]);
  const [polygon, setPolygon] = useState([]);
  const [selectionName, setSelectionName] = useState("");
  const [makerName, setMarkerName] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [createMark, setCreateMark] = useState([]);
  const [edit, setEdit] = useState(false);
  const [show, setShow] = useState(false);
  const trackUpdate = useRef(false);
  const [editId, setEditId] = useState(null);
  const [updatedCoords, setUpdatedCoords] = useState([]);
  const [loader, setLoader] = useState(false);
  const [id, setId] = useState(null);
  const [deleteId, setDeleteId] = useState("");
  // conformation model state
  const [openDeleteModel, setDeleteModel] = useState("");
  const [isserviceable, setIsServiceable] = useState(false);
  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: 10,
    page: 0,
  });

  const handleMarkerName = (index, name) => {
    setMarkerName((prev) => {
      // Create a copy of the previous array or initialize it if it was undefined
      const updatedName = [...(prev || [])];
      updatedName[index] = name; // Update the name at the specific index
      return updatedName; // Return the updated array
    });
  };

  function provideParamNameAccrodingName(name) {
    switch (name) {
      case "HOTZONE":
        return `is_hotzone=${true}`;
      case "HOTSPOT":
        return `is_hotspot=${true}`;
      case "SERVICEABLE AREA":
        return `is_serviceable=${true}`;
      default:
        break;
    }
  }

  const [quickfilter, setQuickFilter] = useState([]);

  const [geoFenceType, setGeoFenceType] = useState("");

  const selectOpion = useRef("");
  const { dateformat, timezone, formatDate } = useSettings();

  // Created a ref to keep the track of serviceable area
  const isserviceableRef = useRef(false);

  // Created a ref to the element you want to scroll to (e.g., the top of the page)
  const topRef = useRef(null);
  const trackCreate = useRef("initial");
  const is_hotzone = useRef(false);

  // Function to scroll to the top using the ref
  const scrollToTop = () => {
    topRef.current.scrollIntoView({ behavior: "smooth" });
  };

  let clickListener = null;

  const overlaysTrack = useRef([]);

  const [staticData, setStaticData] = useState([]);

  const setIsServiceableRef = (value) => {
    if (value === undefined) return;
    isserviceableRef.current = value;
  };

  const setDeleteName = (name) => {
    nameDeleteRef.current = name;
  };

  const buildQueryString = () => {
    const queryParameters = [];

    if (quickfilter.length > 0) {
      const statusQueryParams = quickfilter.map((status) =>
        provideParamNameAccrodingName(status)
      );

      queryParameters.push(statusQueryParams.join("&"));
    }

    const queryString =
      queryParameters.length > 0 ? `&${queryParameters.join("&")}` : "";

    return queryString;
  };

  const fetchPolyoan = async () => {
    setLoader(true);
    setLoader(true);
    const query = buildQueryString();

    let url = `/crm/geofence/?page=${paginationModel.page + 1}&limit=${
      paginationModel.pageSize
    }${query}`;
    try {
      await axiosInstance.get(url).then((res) => {
        // console.log(res?.data?.data?.records);
        setStaticData(res?.data?.data?.records);
        setTotalCount(res.data.data.total_count);
      });
    } catch (error) {
      showErrorToast(error);
      console.log("Error While Fetching Geo Fence from server", error);
    } finally {
      setTimeout(() => {
        setLoader(false);
      }, 300);
    }
  };

  const columns = [
    {
      field: "name",
      flex: 2,
      headerName: "Geo Fence Name",
      renderCell: (params) => {
        if (!params.value) {
          return "--";
        }

        return (
          <ToggleButton
            text={params.value}
            handleToggle={() => {
              const resid = params.id;
              if (resid && id !== resid) {
                setId("");
                if (edit) {
                  setEdit(false);
                }
              }

              scrollToTop();
              setShow(true);
              viewPolygonLocation(
                params.row.polygon.coordinates[0],
                params.row.hotspot_points,
                resid,
                false,
                false,
                params.row.is_serviceable,
                params?.row?.is_hotzone ?? false
              );
            }}
            id={params.id}
          />
        );
      },
    },
    // {
    //   field: "Address",
    //   flex: 3,
    //   headerName: "Address",
    //   renderCell: (params) => {
    //     if (!params.value) {
    //       return "--";
    //     }

    //     return <ToggleButton text={params.value} />;
    //   },
    // },
    {
      field: "created_at",
      flex: 6,
      headerName: "Created on",
      renderCell: (params) => {
        if (!params.value) {
          return "--";
        }

        let inputDate = formatDate(params?.value, timezone, dateformat);
        return <GenericText text={`${inputDate}`} />;
      },
    },
    {
      field: "action",
      headerName: "Actions",
      flex: 1.5,
      renderCell: (params) => {
        return (
          <TableAction
            handleEditClick={handleEditClick}
            permissions={permissions}
            polygon={polygon}
            id={params.id}
            checkId={id}
            setEdit={setEdit}
            selectedShape={selectedShape}
            clickListener={clickListener}
            updatedCoords={updatedCoords}
            setUpdatedCoords={setUpdatedCoords}
            createMark={createMark}
            setCreateMark={setCreateMark}
            fetchPolyoan={fetchPolyoan}
            cleanPolygoan={cleanPolygoan}
            viewPolygonLocation={viewPolygonLocation}
            params={params}
            setStaticData={setStaticData}
            setDeleteModel={setDeleteModel}
            setId={setId}
            setIsServiceable={setIsServiceable}
            deleteId={deleteId}
            setDeleteId={setDeleteId}
            scrollToTop={scrollToTop}
            setIsServiceableRef={setIsServiceableRef}
            setDeleteName={setDeleteName}
            isHotzone={is_hotzone}
            setMarkerName={setMarkerName}
            markerName={makerName}
          />
        );
      },
    },
  ];

  // Function to handle the click event when the user wants to save a marker or polygon on the map
  const handleSaveClick = () => {
    // Checking if there are no coordinates (polygon points) drawn on the map
    if (coordinates.length === 0) {
      showErrorToast(
        "No polygon has been created on the map. Please draw a polygon"
      );

      return;
    }

    if (!selectionName) {
      showErrorToast("Please Fill Selection Name Feild");
      return;
    }

    let polygonData = {
      polygon_coordinates: coordinates,
      name: selectionName,
    };

    const type = [
      {
        value: "is_hotzone",
        label: "HOTZONE",
      },
      {
        value: "is_hotspot",
        label: "HOTSPOT",
      },
      {
        value: "is_serviceable",
        label: "SERVICEABLE AREA",
      },
    ];

    if (!geoFenceType) {
      showErrorToast("Plase Select Geo Fence Type!");
      return;
    }

    const selectedGeoFenceType = type.find((res) => res.label === geoFenceType);
    geoFenceTypeConfig.forEach((res, index) => {
      if (selectedGeoFenceType.label === res.value) {
        polygonData[type[index].value] = true;
      } else {
        polygonData[type[index].value] = false;
      }
    });

    let hotspot = [];

    let check = false;

    // here we are mapping on the new marker and setting initial marker null
    if (createMark.length > 0) {
      createMark.forEach(([lat, lng, marker], index) => {
        if (!makerName[index]) {
          check = true;
        }
        marker.setMap(null);
        hotspot.push({ name: makerName[index], point: [lng, lat] });
      });
    }

    if (check) {
      showErrorToast("Please Fill All Hostspot Name");
      return;
    }

    // If there are hotspot points and the area is not serviceable, add them to the polygon data
    if (hotspot.length > 0 && selectOpion.current === "HOTSPOT") {
      polygonData["hotspot_points"] = hotspot;
    }

    postPolygoan(polygonData);

    // Reset the state for creating markers
    setCreate(false);
  };

  // Function to delete a polygon by its ID
  const deletePolygonById = async () => {
    if (!deleteId) return;
    let url = `/crm/geofence/${deleteId}/`;
    try {
      axiosInstance.delete(url).then((_) => {
        showSuccessToast("Polygon Deleted Successfully!");
        if (polygon && polygon.length > 0) {
          cleanPolygoan(polygon);
        }

        if (deleteId) {
          setDeleteId("");
        }

        setDeleteName("");

        setStaticData((prevState) => {
          return prevState.filter((res) => res.id !== deleteId);
        });
      });
    } catch (error) {
      showErrorToast(error);
    } finally {
      setDeleteModel(false);
    }
  };

  // Function to view and render a polygon on the map with optional hotspot markers
  const viewPolygonLocation = (
    polygonCoordinatesprop,
    hotspotPoints,
    parentId,
    isCancel = false,
    edit = false,
    isserviceable = false,
    is_hotzone = false
  ) => {
    if (!map) return;
    if (editId !== parentId) {
      setEditId(parentId);
    } else if (parentId === editId && !isCancel && !edit) {
      return;
    }

    if (polygon.length > 0) {
      cleanPolygoan(polygon);
    }

    // Center the map on the first coordinate of the polygon
    const bounds = new window.google.maps.LatLngBounds();

    if (polygonCoordinatesprop) {
      const paths = polygonCoordinatesprop.map(([lng, lat], index) => {
        bounds.extend(new window.google.maps.LatLng(lat, lng));
        return {
          lat,
          lng,
        };
      });

      const polygoanStyle = stokeAndFillWithLabel(
        is_hotzone ? "hotzone" : isserviceable ? "serviceable" : "hotspot"
      );

      const polygonCords = new window.google.maps.Polygon({
        paths: paths,
        id: parentId,
        strokeColor: polygoanStyle.strokeColor,
        strokeOpacity: 0.8,
        strokeWeight: 3,
        fillColor: polygoanStyle.fill,
        fillOpacity: 0.35,
        map: map,
        zIndex: 8888,
        isserviceable: isserviceable,
      });

      let hotspots;

      if (hotspotPoints) {
        // Display markers for hotspot points
        hotspots = hotspotPoints
          .map((pointItem, index) => {
            if (typeof pointItem === "object" && !Array.isArray(pointItem)) {
              const { name, point } = pointItem;

              let dateId = `${index + 1}-${Date.now()}`;
              let newMarker = new window.google.maps.Marker({
                position: { lat: point[1], lng: point[0] },
                map: map,
                title: `${name}`,
                icon: geofencemarker,
                zIndex: 9999,
              });

              // Optionally store the ID separately if needed
              newMarker.customId = dateId;

              return newMarker;
            } else if (Array.isArray(pointItem) && pointItem.length >= 2) {
              let dateId = `${index + 1}-${Date.now()}`;
              let newMarker = new window.google.maps.Marker({
                position: { lat: pointItem[1], lng: pointItem[0] },
                map: map,
                title: `Hotspot Marker ${index}`,
                icon: geofencemarker,
                zIndex: 9999,
              });

              // Optionally store the ID separately if needed
              newMarker.customId = dateId;

              return newMarker;
            }
            return null; // Handle cases where pointItem doesn't match expected types
          })
          .filter(Boolean); // Remove any null values from the hotspots array

        setPolygon([
          { polygon: polygonCords, hotspots, isserviceable: isserviceable },
        ]);
      }

      // if (hotspotPoints) {
      //   // Display markers for hotspot points
      //   hotspots = hotspotPoints.map((pointiteam, index) => {
      //     if (typeof pointiteam === "object" && !Array.isArray(pointiteam)) {
      //       const { name, point } = pointiteam;

      //       let dateId = index + 1 + Date.now();
      //       let newMarker = new window.google.maps.Marker({
      //         position: { lat: point[1], lng: point[0] },
      //         map: map,
      //         title: `${name}`,
      //         id: dateId,
      //         icon: geofencemarker,
      //         zIndex: 9999,
      //       });

      //       return newMarker;
      //     } else {
      //       let dateId = index + 1 + Date.now();
      //       let newMarker = new window.google.maps.Marker({
      //         position: { lat: pointiteam[1], lng: pointiteam[0] },
      //         map: map,
      //         title: `Hotspot Marker ${index}`,
      //         id: dateId,
      //         icon: geofencemarker,
      //         zIndex: 9999,
      //       });

      //       return newMarker;
      //     }
      //   });

      //   console.log(hotspotPoints, "hotspot");

      //   setPolygon([
      //     { polygon: polygonCords, hotspots, isserviceable: isserviceable },
      //   ]);
      // }

      map.fitBounds(bounds);

      return [
        { polygon: polygonCords, hotspots, isserviceable: isserviceable },
      ];
    }
  };

  // Function to handle the click event when editing a polygon
  const handleEditClick = (id) => {
    scrollToTop();
    const selectedPolygon = polygon.find((p) => {
      return p.polygon.id === id;
    });

    let selectShow = staticData.find((res) => id === res.id);
    if (selectShow && id !== editId) {
      if (!show) {
        setShow(true);
      }
      let value = viewPolygonLocation(
        selectShow.polygon.coordinates[0],
        selectShow.hotspot_points,
        id,
        false,
        false,
        selectShow.is_serviceable,
        selectShow?.is_hotzone ?? false
      );

      value[0].hotspots.map((res) => {
        const newMarker = res;

        const position = newMarker.getPosition(); // This returns a LatLng object
        const lat = position.lat(); // Get the latitude
        const lng = position.lng(); // Get the longitude

        newMarker.addListener("click", () => {
          newMarker.setMap(null);
          setCreateMark((prev) => {
            const updatedPolygons = [...prev];
            const updatedHotspots = updatedPolygons.filter((hotspotMarker) => {
              console.log(lat !== hotspotMarker[1], lng, hotspotMarker[1]);
              return lng !== hotspotMarker[1] || lat !== hotspotMarker[0];
            });

            console.log(updatedHotspots);

            return updatedHotspots;
          });
        });

        return newMarker;
      });

      if (value[0]?.polygon?.setEditable) {
        value[0].polygon.setEditable(true);
        addPolygonClickListener(value[0].polygon, 1);
      }

      setEdit(true);
    }

    if (
      !selectedPolygon ||
      !selectedPolygon?.polygon ||
      !selectedPolygon?.hotspots
    )
      return;

    setEdit(true);

    selectedPolygon.hotspots.map((res) => {
      const newMarker = res;

      newMarker.addListener("click", () => {
        newMarker.setMap(null);
        setPolygon((prev) => {
          const updatedPolygons = [...prev];
          const updatedHotspots = updatedPolygons[0].hotspots.filter(
            (hotspotMarker) => newMarker.id !== hotspotMarker.id
          );
          updatedPolygons[0].hotspots = updatedHotspots;
          return updatedPolygons;
        });
      });

      return newMarker;
    });

    // Clear any existing selection
    clearSelection();

    // Set the selected shape to the existing polygon
    setSelectedShape(selectedPolygon.polygon);

    // Make the selected shape editable
    if (selectedPolygon?.polygon?.setEditable) {
      selectedPolygon.polygon.setEditable(true);
      addPolygonClickListener(selectedPolygon.polygon, 1);
    }
  };

  const postPolygoan = async (data) => {
    const url = "/crm/geofence/";
    try {
      await axiosInstance.post(url, data).then((res) => {
        showSuccessToast("Polygoan Created Succesfully!");
        fetchPolyoan();
        if (isserviceable) {
          setIsServiceable(false);
        }
        if (create) {
          setCreate(false);
        }
        if (selectionName) {
          setSelectionName("");
        }

        if (makerName?.length > 0) {
          setMarkerName([]);
        }

        selectOpion.current = "";

        if (geoFenceType !== "initial" || !geoFenceType) {
          setGeoFenceType("");
        }
      });
    } catch (error) {
      showErrorToast(error);
      console.log("Error While Posting New Marker");
    } finally {
      trackUpdate.current = false;
    }
  };

  // Define the cleanPolygon callback using useCallback
  const cleanPolygoan = useCallback((polygonMarkers) => {
    if (!polygonMarkers || polygonMarkers.length === 0) return;

    polygonMarkers.forEach(({ polygon, hotspots }) => {
      if (polygon) {
        polygon.setMap(null);
      }

      if (hotspots) {
        hotspots.forEach((marker) => {
          marker.setMap(null);
        });
      }
    });

    setPolygon([]);
  }, []);

  useEffect(() => {
    // fetching polygoan
    fetchPolyoan();
  }, [paginationModel.page, paginationModel.pageSize, quickfilter]);

  // Initialize map
  const initializeMap = () => {
    const initialCenter = {
      lat: +process.env.REACT_APP_MAP_CENTER_LAT,
      lng: +process.env.REACT_APP_MAP_CENTER_LNG,
    };
    const mapOptions = {
      center: initialCenter,
      zoom: 12,
      mapTypeId: window.google.maps.MapTypeId.ROADMAP,
    };
    const newMap = new window.google.maps.Map(
      document.getElementById("map-canvas"),
      mapOptions
    );

    return newMap;
  };

  // Initialize drawing manager
  const initializeDrawingManager = (map) => {
    const manager = new window.google.maps.drawing.DrawingManager({
      drawingControlOptions: {
        position: window.google.maps.ControlPosition.TOP_CENTER,
        drawingModes: ["marker", "polygon"],
      },
      polygonOptions: {
        clickable: true,
        draggable: false,
        editable: true,
        fillColor: "transparent", // Set the fill color to transparent
        strokeWeight: 3, // Set the border (stroke) weight
        strokeColor: "#000",
      },
    });

    window.google.maps.event.addListener(
      manager,
      "overlaycomplete",
      handleOverlayComplete
    );

    return manager;
  };
  const handleOverlayComplete = (event) => {
    const { overlay, type } = event;
    const newShape = overlay;
    // Check if the completed overlay is a marker;
    if (type === window.google.maps.drawing.OverlayType.MARKER) {
      if (isserviceableRef.current) {
        overlay.setMap(null);
        return;
      }

      if (is_hotzone.current) {
        overlay.setMap(null);
        return;
      }

      if (
        trackCreate.current === "true" &&
        overlaysTrack.current.length === 0 &&
        selectOpion.current !== "HOTSPOT"
      ) {
        if (selectOpion.current !== "HOTSPOT") {
          showErrorToast("Marker can only be added to hotspot areas!");
          overlay.setMap(null);
          return;
        }
        overlay.setMap(null);
        showErrorToast("Please Create Geofence!");
        return;
      }

      if (trackCreate.current === "true" && selectOpion.current !== "HOTSPOT") {
        showErrorToast("Please Select Geofence Type as Hotspot Add Marker!");
        overlay.setMap(null);
        return;
      }

      handleMarkerComplete(overlay);

      return;
    }

    // Check if the overlay is a polygon
    if (overlay instanceof window.google.maps.Polygon) {
      // Set up a click event listener on the new overlay to enable selection
      window.google.maps.event.addListener(overlay, "click", () => {
        console.log(overlay, "overlay");
        setSelection(overlay);
      });

      getPolygonCoords(overlay);
    }

    // Add the overlay to the overlays array

    overlaysTrack.current = [...overlaysTrack.current, newShape];
  };

  // Main component
  useEffect(() => {
    // Initialize the map
    const newMap = initializeMap();
    // console.log(newMap, "map initialized");
    setMap(newMap);

    // Initialize the drawing manager
    const manager = initializeDrawingManager(newMap);
    setDrawingManager(manager);

    // Cleanup
    return () => {
      window.google.maps.event.clearListeners(manager, "overlaycomplete");
      setMap(null);
      setDrawingManager(null);
      setSelectedShape(null);
      setCoordinates([]);
      overlaysTrack.current.forEach((overlay) => {
        overlay.setMap(null);
      });
      overlaysTrack.current = [];
    };
  }, []);

  useEffect(() => {
    if (map && drawingManager) {
      drawingManager.setMap(create || edit ? map : null);
      if (create) {
        if (polygon.length > 0) {
          cleanPolygoan(polygon);
        }
      }

      // Check if drawingManager is not null before using it
      if (
        (create && drawingManager.setDrawingMode) ||
        (edit && drawingManager.setDrawingMode)
      ) {
        // Explicitly clear any existing drawing mode
        // drawingManager.setDrawingMode(null);

        drawingManager.setOptions({
          drawingControl: true,
        });

        // drawingManager.setDrawingMode(edit ? "marker" : null);

        if (!create && edit === false) {
          cleanPolygoan(polygon);
        }
      }
    }

    // Clean up DrawingManager shapes when create becomes false
    if (create) {
      trackCreate.current = "true";
    } else if (trackCreate.current === "true") {
      trackCreate.current = "false";
    }
    if (create === false && edit === false && drawingManager) {
      drawingManager.setDrawingMode(null);
      drawingManager.setOptions({
        drawingControl: false,
      });

      if (overlaysTrack.current) {
        overlaysTrack.current.forEach((overlay) => {
          if (
            overlay instanceof window.google.maps.Polygon ||
            overlay instanceof window.google.maps.Marker
          ) {
            overlay.setMap(null);
          }
        });

        overlaysTrack.current = [];
      }
    }

    if (create) {
      setIsServiceable(false);
      isserviceableRef.current = false;
      selectOpion.current = "";
    }

    if (makerName?.length > 0 && !edit) {
      setMarkerName([]);
    }

    if (createMark.length > 0) {
      createMark.forEach((res) => {
        if (res) {
          res[2]?.setMap(null);
        }
      });
    }

    if (!edit) {
      console.log("edit is hit", makerName);
      setCreateMark([]);
      setMarkerName([]);
    }
  }, [map, drawingManager, create, edit]);

  const addPolygonClickListener = (polygon) => {
    window.google.maps.event.addListener(polygon.getPath(), "set_at", () => {
      handlePolygonEdit(polygon);
    });

    window.google.maps.event.addListener(polygon.getPath(), "insert_at", () => {
      handlePolygonEdit(polygon);
    });
  };

  const handleMarkerComplete = (marker) => {
    const markerPosition = marker.getPosition().toJSON();

    // Determine which marker icon to use based on the edit mode
    const checkmarker = edit ? geofencemarker : creategeofencemarker;

    // Set the marker icon
    marker.setIcon(checkmarker);

    // Add a click listener to the marker
    marker.addListener("click", async () => {
      console.log("Marker clicked!");

      // Remove marker from the map
      marker.setMap(null);
      console.log("Marker removed from the map.");

      // Update state to remove marker position
      setCreateMark((prev) => {
        const newmarker = prev?.filter(
          ([lat, lng]) =>
            lat !== markerPosition.lat || lng !== markerPosition.lng
        );

        return newmarker;
      });
    });

    // Add marker position to state
    setCreateMark((prev) => [
      ...prev,
      [markerPosition.lat, markerPosition.lng, marker],
    ]);
  };

  const handlePolygonEdit = (editedPolygon) => {
    let polygoan = editedPolygon.getPath().getArray();
    let updatedCoordinates = [];

    polygoan.forEach((res) => updatedCoordinates.push([res.lng(), res.lat()]));

    setUpdatedCoords(updatedCoordinates);
  };

  const setSelection = (shape) => {
    clearSelection();
    setSelectedShape(shape);
    shape.setEditable(true);
  };

  const clearSelection = () => {
    if (selectedShape) {
      selectedShape.setEditable(false);
      setSelectedShape(null);
    }
  };

  const getPolygonCoords = (newShape) => {
    const len = newShape.getPath().getArray();
    if (!len) {
      return;
    }

    let coordinates = len.map(function (coord) {
      return [coord.lng(), coord.lat()];
    });

    coordinates.push(coordinates[0]);

    setCoordinates(coordinates);
  };

  //checkbox handling function
  const handleTicketStatus = (e) => {
    const ticketStatus = [...quickfilter];
    if (ticketStatus.includes(e.target.value)) {
      ticketStatus.splice(ticketStatus.indexOf(e.target.value), 1);
    } else {
      ticketStatus.push(e.target.value);
    }
    setQuickFilter(ticketStatus);
  };

  //css

  const buttonCss = {
    width: "230px",
    height: "50px",
    borderRadius: "4px",
  };

  // config
  const quickFilterConfig = [
    {
      label: "Hotzone",
      value: "HOTZONE",
    },
    {
      label: "Hotspot",
      value: "HOTSPOT",
    },
    {
      label: "serviceable area",
      value: "SERVICEABLE AREA",
    },
  ];

  const geoFenceTypeConfig = [
    {
      label: "Hotzone",
      value: "HOTZONE",
    },
    {
      label: "Hotspot",
      value: "HOTSPOT",
    },
    {
      label: "serviceable area",
      value: "SERVICEABLE AREA",
    },
  ];

  return (
    <div id="scrollTop" ref={topRef}>
      <div style={{ marginTop: "25px" }}>
        <QuickFilter
          handleTicketStatus={handleTicketStatus}
          quickfilter={quickfilter}
          quickFilterConfig={quickFilterConfig}
        />
      </div>
      <div
        id="map-canvas"
        style={{
          height: show || create ? "630px" : "auto",
          width: "100%",
          marginTop: "18px",
          display: show || create ? "block" : "none",
        }}
      ></div>
      <div style={{ marginTop: create ? "20px" : "20px" }}>
        {create ? (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                paddingBottom: "20px",
                alignItems: "center",
              }}
            >
              <Stack>
                <Stack direction="row" gap="20px" alignItems={"center"}>
                  <TextField
                    type="text"
                    value={selectionName}
                    onChange={(e) => {
                      setSelectionName(e.target.value);
                    }}
                    placeholder="Enter Selection Name"
                    sx={{
                      border: "none",
                      width: "281px",
                      padding: "16px",
                      borderRadius: "12px",
                    }}
                    InputProps={{
                      sx: {
                        outline: "none",
                      },
                    }}
                  />
                  <TextField
                    select
                    sx={{
                      width: "160px",
                    }}
                    label="Geo Fence Type"
                    value={geoFenceType}
                    name="geo fence type"
                    onChange={(e) => {
                      selectOpion.current = e.target.value;
                      setGeoFenceType(e.target.value);
                    }}
                  >
                    {geoFenceTypeConfig.length > 0 &&
                      geoFenceTypeConfig.map((res, index) => (
                        <MenuItem key={res.label} value={res.value}>
                          {res.value}
                        </MenuItem>
                      ))}
                  </TextField>
                </Stack>
              </Stack>
              <Stack direction="row" gap="20px">
                <Button
                  variant="outlined"
                  sx={{
                    ...buttonCss,
                    border: "1px solid #000",
                    color: "#000",
                  }}
                  onClick={() => {
                    setCreate();
                    setIsServiceable(false);
                    setMarkerName([]);
                    isserviceableRef.current = false;
                    selectOpion.current = "";
                    if (geoFenceType !== "initial" || !geoFenceType) {
                      setGeoFenceType("");
                    }
                  }}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  sx={{
                    ...buttonCss,
                    background: "#000",
                    color: "#fff",
                  }}
                  onClick={handleSaveClick}
                >
                  Save
                </Button>
              </Stack>
            </Box>

            <Stack>
              {createMark.length > 0 && (
                <p
                  style={{
                    fontSize: "20px",
                    marginLeft: "16px",
                    letterSpacing: "0.25%",
                    fontFamily: "Proxima Nova",
                  }}
                >
                  Hotspot marker Name
                </p>
              )}

              <Stack direction={"row"} flexWrap={"wrap"} gap={"2px"}>
                {createMark?.length > 0 &&
                  createMark?.map((res, index) => {
                    return (
                      <TextField
                        type="text"
                        placeholder={`marker ${index + 1}`}
                        key={index}
                        sx={{
                          border: "none",
                          width: "281px",
                          padding: "16px",
                          borderRadius: "12px",
                        }}
                        value={makerName[index] ?? ""}
                        onChange={(e) => {
                          const value = e.target.value;
                          handleMarkerName(index, value);
                        }}
                        InputProps={{
                          sx: {
                            outline: "none",
                          },
                        }}
                      />
                    );
                  })}
              </Stack>
            </Stack>
          </Box>
        ) : loader ? (
          <CircularLoader />
        ) : (
          <>
            {
              <Stack>
                {createMark.length > 0 && (
                  <p
                    style={{
                      fontSize: "20px",
                      marginLeft: "16px",
                      letterSpacing: "0.25%",
                      fontFamily: "Proxima Nova",
                    }}
                  >
                    Hotspot marker Name
                  </p>
                )}

                <Stack direction={"row"} flexWrap={"wrap"} gap={"2px"}>
                  {createMark?.length > 0 &&
                    createMark?.map((res, index) => {
                      return (
                        <TextField
                          type="text"
                          placeholder={`marker ${index + 1}`}
                          key={index}
                          sx={{
                            border: "none",
                            width: "281px",
                            padding: "16px",
                            borderRadius: "12px",
                          }}
                          value={makerName[index] ?? ""}
                          onChange={(e) => {
                            const value = e.target.value;
                            handleMarkerName(index, value);
                          }}
                          InputProps={{
                            sx: {
                              outline: "none",
                            },
                          }}
                        />
                      );
                    })}
                </Stack>
              </Stack>
            }

            <DataTable
              row={staticData || []}
              columns={columns}
              paginationModel={paginationModel}
              setPaginationModel={setPaginationModel}
              totalCount={totalCount}
            />
          </>
        )}
      </div>

      <CustomConfirmationDialog
        open={openDeleteModel}
        setOpen={setDeleteModel}
        deleteById={deletePolygonById}
        status="delete"
        prop={deleteId}
        message={`Are you sure you want to delete ${nameDeleteRef.current}?`}
      />
    </div>
  );
};

export default GeoFence;
