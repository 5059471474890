import { Box, Button, MenuItem, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import useDebounce from "../../hooks/useDebounce";
import SearchActionComponent from "../reusableComponents/SearchActionComponent";
import formatNumberShort from "../../utils/formatNumberShort";
import DataTable from "../DataTable";
import { BootstrapTooltip } from "../DetailsSidePanelChart";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import BlockIcon from "@mui/icons-material/Block";
import MuiIconButton from "../uicomponents/MuiIconButton";
import { StyledMenu } from "../Customer";
import { fetchFlag, updateFlagStatus } from "../../service/customer";
import SwipeableDrawerComponent from "../SwipeableDrawerComponent ";
import CustomerDetails from "../CustomerDetails";
import CustomConfirmationDialog from "../CustomConfirmationDialog";
import axiosInstance from "../../utils/axiosConfig";
import { showErrorToast, showSuccessToast } from "../../utils/toastUtils";
import filterEmptyPropertiesRecursively from "../../utils/filterEmptyPropertiesRecursively";
import { useSearchParams } from "react-router-dom";

function Action({
  handleUserStatus,
  handleConformationDailog,
  params,
  open,
  handleClick,
  setbookingId,
  setRiderName,
  anchorEl,
  handleClose,
  permissionsSubSection = {
    DELETE: true,
    UPDATE: true,
  },
  userStatusRef,
}) {
  // css
  const menuIteamBtnStyle = {
    color: "#000",
    fontFamily: "Proxima Nova",
    fontSize: "16px",
    width: "100%",
    fontStyle: "normal",
    justifyContent: "flex-start",
    fontWeight: 500,
    lineHeight: "175%",
  };

  return (
    <>
      <MuiIconButton
        id="demo-positioned-button"
        aria-controls={open ? "demo-positioned-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={(e) => {
          handleUserStatus("", {
            isBlocked: params?.row?.account_status,
          });
          handleClick(e, params.row.id);
        }}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <MoreVertIcon
          sx={{
            color: "#000",
          }}
        />
      </MuiIconButton>
      <>
        <StyledMenu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
          ModalProps={{
            style: {
              zIndex: 1200,
              backgroundColor: "rgba(0, 0, 0, 0.5)",
            },
          }}
        >
          {permissionsSubSection?.UPDATE && (
            <MenuItem>
              <Button
                sx={menuIteamBtnStyle}
                disableRipple
                disableFocusRipple
                disableElevation
                variant="text"
                startIcon={<BlockIcon sx={{ color: "#000" }} />}
                onClick={(e) => {
                  e.stopPropagation();
                  handleClose();
                  setRiderName(params?.row?.full_name);
                  handleConformationDailog("block", true);
                }}
              >
                {userStatusRef?.current?.isBlocked === "ACTIVE"
                  ? "Block"
                  : "Unblock"}
              </Button>
            </MenuItem>
          )}

          {permissionsSubSection?.UPDATE && (
            <MenuItem>
              <Button
                sx={menuIteamBtnStyle}
                disableRipple
                disableFocusRipple
                disableElevation
                variant="text"
                // startIcon={<DeleteIcon sx={{ color: "#000" }} />}
                onClick={(e) => {
                  e.stopPropagation();
                  setbookingId(params?.row?.booking_id);
                  handleClose();
                  handleConformationDailog("flagged", true);
                }}
              >
                UNFLAG
              </Button>
            </MenuItem>
          )}
        </StyledMenu>
      </>
    </>
  );
}

export const CustomerFlagged = () => {
  const [isLoading, setisLoading] = useState(false);

  // anchor which reprsent sidebar postion
  const anchor = "right";
  const [searchParams, setSearchParams] = useSearchParams();

  const page = parseInt(searchParams.get("page"), 0) || 0;
  const limit = parseInt(searchParams.get("limit"), 10) || 10;

  const [customerFlag, setCustomerFlag] = useState({
    data: [],
    total: 0,
    paginationModel: {
      pageSize: limit || 10,
      page: page || 0,
    },
  });

  const [conformationDialog, setConformationDialog] = useState({
    delete: false,
    block: false,
    flagged: false,
  });

  const userStatusRef = useRef({
    isBlocked: false,
    isFlagged: false,
  });

  const [riderId, setRiderId] = useState("");
  const [bookingId, setbookingId] = useState("");
  const [riderName, setRiderName] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const [showShowCustomerDetails, setShowCustomerDetails] = useState({
    [anchor]: false,
  });

  const FlaggedColumns = [
    {
      field: "phone",
      headerName: "Phone Number",
      flex: 1,
      renderCell: (params) => {
        if (!params?.value) {
          return "--";
        }

        let phoneNumber = params.value;
        let formattedNumber;
        if (phoneNumber.length === 10) {
          // Format 1234567890 as 123 456 7890
          formattedNumber =
            phoneNumber.slice(0, 3) +
            " " +
            phoneNumber.slice(3, 6) +
            " " +
            phoneNumber.slice(6);
        } else {
          // Fallback formatting
          formattedNumber =
            phoneNumber.slice(0, 3) + " " + phoneNumber.slice(3);
        }

        return (
          <Button
            id={params.id}
            sx={{
              color: "#043A87",
              width: "120px",
              fontWeight: "500",
              textAlign: "center",
              fontFamily: "Proxima Nova",
              fontSize: "13px",
              textTransform: "capitalize",
              border: "none",
            }}
            onClick={(e) => {
              e.stopPropagation();
              setRiderId(params.id);
              toggleDrawer("right", true)(e);
            }}
          >
            {formattedNumber}
          </Button>
        );
      },
      minWidth: 150,
    },
    {
      field: "full_name",
      headerName: "Name",
      flex: 1,
      minWidth: 150,
      renderCell: (params) => {
        if (!params?.value) {
          return "--";
        }

        return (
          <BootstrapTooltip title={params?.value}>
            <Button
              id={params.id}
              sx={{
                border: "none",
                display: "flex",
                justifyContent: "flex-start",
                WebkitBoxOrient: "vertical",
                WebkitLineClamp: 1,
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
              onClick={(e) => {
                e.stopPropagation();
                setRiderId(params.id);
                toggleDrawer("right", true)(e);
              }}
            >
              <Typography
                sx={{
                  color: "#043A87",
                  fontFamily: "Proxima Nova",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  fontSize: "13px",
                  textTransform: "capitalize",
                  letterSpacing: "0.26px",
                  fontWeight: "500",
                }}
              >
                {params?.value}
              </Typography>
            </Button>
          </BootstrapTooltip>
        );
      },
    },
    {
      field: "nick_name",
      headerName: "Nick name",
      flex: 1,
      minWidth: 150,
      renderCell: (params) => {
        if (!params?.value) {
          return "--";
        }

        return (
          <BootstrapTooltip title={params?.value}>
            <Button
              id={params.id}
              sx={{
                border: "none",
                display: "flex",
                justifyContent: "flex-start",
                WebkitBoxOrient: "vertical",
                WebkitLineClamp: 1,
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
              onClick={(e) => {
                e.stopPropagation();
                setRiderId(params.id);
                toggleDrawer("right", true)(e);
              }}
            >
              <Typography
                sx={{
                  color: "#043A87",
                  fontFamily: "Proxima Nova",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  fontSize: "13px",
                  textTransform: "capitalize",
                  letterSpacing: "0.26px",
                  fontWeight: "500",
                }}
              >
                {params?.value}
              </Typography>
            </Button>
          </BootstrapTooltip>
        );
      },
    },
    {
      field: "total_created_rides",
      headerName: "Total Created Rides",
      flex: 1,
      minWidth: 150,
      valueGetter: (param) => param?.value,
    },
    {
      field: "total_completed_rides",
      headerName: "Total Completed Rides",
      flex: 1,
      minWidth: 180,
      valueGetter: (param) => param?.value,
    },
    {
      field: "total_cancelled_rides_by_rider",
      headerName: "Total Cancelled By Rider",
      flex: 1,
      minWidth: 180,
      valueGetter: (param) => param?.value,
    },
    {
      field: "total_cancelled_rides_by_driver",
      headerName: "Total Cancelled By Driver",
      flex: 1,
      minWidth: 180,
      valueGetter: (param) => param?.value,
    },
    {
      field: "total_driver_not_found",
      headerName: "Total Driver Not Found",
      flex: 1,
      minWidth: 180,
      valueGetter: (param) => param?.value,
    },
    // {
    //   field: "id",
    //   headerName: "Action",
    //   minWidth: 25,
    //   renderCell: (params) => (
    //     <Action
    //       handleUserStatus={handleUserStatus}
    //       handleConformationDailog={handleconformationDialog}
    //       userStatusRef={userStatusRef}
    //       params={params}
    //       toggleDrawer={toggleDrawer},
    //       setbookingId,
    //       setRiderName,
    //       open={open}
    //       handleClick={handleClick}
    //       anchorEl={anchorEl}
    //       handleClose={handleClose}
    //     />
    //   ),
    // },
  ];

  const [search, setSearch] = useState("");

  const searchDebounce = useDebounce(search, 500);

  const [activeTab, setActiveTab] = useState("All");

  const handleClick = (event, riderId) => {
    setAnchorEl(event.currentTarget);
    setRiderId(riderId);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setShowCustomerDetails((prev) => ({ ...prev, [anchor]: open }));
  };

  const getCustomerFlag = () => {
    fetchFlag(setCustomerFlag, [], customerFlag?.paginationModel);
  };

  // Funtion To Block Rider By Id
  const handleBlock = () => {
    let updateData = filterEmptyPropertiesRecursively({
      account_status:
        userStatusRef?.current?.isBlocked === "ACTIVE" ? "BLOCKED" : "ACTIVE",
    });

    console.log(updateData, "data");

    try {
      // console.log(updateData, "data");
      axiosInstance.patch(`/crm/rider/${riderId}/`, updateData).then((_) => {
        showSuccessToast(
          `The account has been successfully ${
            userStatusRef?.current?.isBlocked === "BLOCKED"
              ? "unblocked"
              : "blocked"
          }!`
        );
        getCustomerFlag();
      });
    } catch (error) {
      showErrorToast(error);
      console.log(error, "Error while updating customer");
    }
  };

  const handleUserStatus = (name, value) => {
    if (typeof value === "object") {
      userStatusRef.current = { ...userStatusRef.current, ...value };
    } else {
      userStatusRef.current = { ...userStatusRef.current, [name]: value };
    }
  };

  const handleconformationDialog = (name, value) => {
    setConformationDialog((prev) => ({ ...prev, [name]: value }));
  };

  const checkQuery = (download) => {
    const query = [];

    if (searchDebounce && search) {
      query.push(`search=${searchDebounce}`);
    }

    let qurys = query.length > 1 ? query.join("&") : query.join("");

    return qurys;
  };

  useEffect(() => {
    const query = checkQuery();
    fetchFlag(
      setisLoading,
      setCustomerFlag,
      [],
      customerFlag?.paginationModel,
      query
    );
  }, [customerFlag?.paginationModel, searchDebounce]);

  return (
    <Box m="1.5rem 1.5rem">
      <SearchActionComponent
        value={search}
        setState={setSearch}
        require={{ search: true }}
      />

      <Box
        sx={{
          mt: "15px",
          display: "flex",
          gap: "0.25rem",
        }}
      >
        <Button
          sx={{
            display: "flex",
            width: "142px",
            padding: "10px",
            justifyContent: "center",
            alignItems: "center",
            gap: "10px",
            marginTop: "25px",
            borderRadius: "10px 10px 0 0",
            border: "1px solid #929EAE",
          }}
          style={{
            background: true ? "lightgray" : "#FFF",
          }}
          // onClick={() => {
          //   res.handleAccountStatus(res.value);
          // }}
        >
          <Typography
            variant="h4"
            sx={{
              fontSize: "14px",
              fontFamily: "Proxima Nova",
              textAlign: "right",
              fontWeight: "600",
              lineHeight: "normal",
              letterSpacing: "0.28px",
              textTransform: "capitalize",
              color: "#000",
            }}
          >
            All
          </Typography>

          {customerFlag.total > 0 && (
            <Box
              sx={{
                display: "inline-flex",
                padding: "2px 5px",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "10px",
                background: "#000",
              }}
            >
              <Typography
                sx={{
                  color: "#fff",
                  textAlign: "right",
                  fontFamily: "Proxima Nov",
                  fontSize: "14px",
                  lineHeight: "normal",
                  fontWeight: "600px",
                  letterSpacing: "0.28px",
                  textTransform: "capitalize",
                }}
              >
                {formatNumberShort(customerFlag.total ? customerFlag.total : 0)}
              </Typography>
            </Box>
          )}
        </Button>
      </Box>

      <DataTable
        loading={isLoading}
        row={customerFlag?.data}
        columns={FlaggedColumns}
        paginationModel={customerFlag?.paginationModel}
        setPaginationModel={setCustomerFlag}
        totalCount={customerFlag?.total}
        paginationInnerObject={true}
      />

      {showShowCustomerDetails?.right && (
        <SwipeableDrawerComponent
          isOpen={showShowCustomerDetails[anchor]}
          anchor="right"
          onClose={toggleDrawer("right", false)}
          ModalProps={{
            style: {
              zIndex: 1800,
              backgroundColor: "rgba(0, 0, 0, 0.5)",
            },
          }}
        >
          <CustomerDetails
            toggleDrawer={toggleDrawer}
            anchor={"right"}
            riderId={riderId}
            setRiderId={setRiderId}
            fetchFunction={getCustomerFlag}
          />
        </SwipeableDrawerComponent>
      )}

      <CustomConfirmationDialog
        open={conformationDialog?.block}
        isGroup={true}
        message={`Are you Sure you Want to ${
          userStatusRef?.isBlocked !== "ACTIVE" ? "unblock" : "block"
        } ${riderName}}?`}
        status="block"
        blockById={handleBlock}
        handleState={handleconformationDialog}
      />

      <CustomConfirmationDialog
        open={conformationDialog?.flagged}
        isGroup={true}
        message={`Are you sure you want to unflag this ride with booking ID ${"Unflag"} ${bookingId}?`}
        status="flagged"
        flagbyid={updateFlagStatus}
        riderId={riderId}
        handleState={handleconformationDialog}
      />
    </Box>
  );
};
