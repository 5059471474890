import React from "react";
import { Button } from "@mui/material";

const MuiButton = ({
  variant = "contained",
  style = { padding: "8px 30px" },
  bg = "black",
  color = "white",
  icon = "",
  onClick,
  children
}) => {
  return (
    <Button
      style={{
        backgroundColor: bg,
        color: color,
        textTransform: "none",
        ...style,
      }}
      variant={variant}
      startIcon={icon && <img src={icon} alt="icon" />}
      size="small"
      onClick={onClick}
    >
      {children}
    </Button>
  );
};

export default MuiButton;
