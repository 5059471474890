import {
  Box,
  Stack,
  Step,
  StepConnector,
  StepLabel,
  Stepper,
  Typography,
} from "@mui/material";
import React from "react";
import peekupLogo from "../../assets/svg/peekupCircleLogo.svg";
import reactangle from "../../assets/svg/Rectanglesvg.svg";
import underline from "../../assets/svg/underline.svg";

//css
const containerStyle = {
  display: "flex",
};

const textStyle = {
  color: "#0F1828B2",
  fontFamily: "Proxima Nova",
  fontSize: "15px",
  fontWeight: 400,
  lineHeight: "20px",
};

// components

const ImageContainer = ({ src }) => {
  return <img src={src} width={"32px"} height={"32px"} alt="logo" />;
};

// main container
const RequestRideAddress = ({ apiResponse }) => {
  const data = [
    apiResponse?.pickup_location?.address_text || "",
    ...(Array.isArray(apiResponse?.stops)
      ? apiResponse.stops.map((res) => res?.address_text || "")
      : []),
    apiResponse?.dropoff_location?.address_text || "",
  ];

  return (
    <Box
      sx={{
        backgroundColor: "#fff",
        mb: "5px",
        display: "flex",
        padding: "15px",
        boxSizing: "border-box",
      }}
    >
      <Stack width={"50px"} alignItems={"center"}>
        <Stepper
          connector={
            <StepConnector
              sx={{
                "& .MuiStepConnector-line": {
                  borderLeftWidth: "3px",
                  borderColor: "#f98244", // Change to your desired color
                  minHeight: "28px",
                },
                "&.MuiStepConnector-root": {
                  margin: "auto",
                },
              }}
            />
          }
          orientation="vertical"
        >
          {data?.map((res, index) => (
            <Step key={index}>
              <StepLabel
                StepIconComponent={() => (
                  <ImageContainer src={index === 0 ? peekupLogo : reactangle} />
                )}
                sx={{
                  "&.MuiStepLabel-root": {
                    padding: `2px 0px ${index === 0 ? "3px" : "2px"} 0px`,
                  },
                  "& .MuiStepLabel-iconContainer": {
                    paddingRight: "0px", // Remove padding around the icon
                  },
                }}
              />
            </Step>
          ))}
        </Stepper>
      </Stack>
      <Stack width={"calc(100% - 52px)"}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          {data?.map((res, index) => (
            <Box
              key={index}
              sx={{
                position: "relative",
                marginTop: index === 0 ? "0px" : "8px", // No margin top for the first item
              }}
            >
              <Typography
                variant="body2"
                sx={{
                  marginBottom: index !== data.length - 1 ? "20px" : "0",
                  whiteSpace: "normal", // Change to normal for multi-line
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  display: "-webkit-box",
                  WebkitBoxOrient: "vertical",
                  WebkitLineClamp: 2, // Limit to 2 lines
                  width: "100%",
                  padding: "0px 8px",
                  boxSizing: "border-box",
                  ...textStyle,
                }}
              >
                {res}
              </Typography>
              {index !== data.length - 1 && (
                <Box
                  sx={{
                    width: "100%",
                    position: "absolute",
                    bottom: "-8px", // Adjust the position to fit the underline properly
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <img src={underline} width={"100%"} alt="underline img" />
                </Box>
              )}
            </Box>
          ))}
        </Box>
      </Stack>
    </Box>
  );
};
export default RequestRideAddress;
