import { useSearchParams } from "react-router-dom";

const useQueryParams = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  // Set a query parameter
  const setQuery = (key, value) => {
    const params = new URLSearchParams(searchParams);
    params.set(key, value);
    setSearchParams(params, { replace: true });
  };

  const removeMultipleQueries = (filterOptions) => {
    const searchParams = new URLSearchParams(window.location.search);

    filterOptions?.forEach(
      ({ filterName, inputOptionsFilterName, date, time }) => {
        if (filterName) {
          searchParams.delete(filterName);
        }

        if (date?.filterName) {
          date?.filterName?.forEach((dateFilterName) => {
            searchParams.delete(dateFilterName);
          });
        }

        if (time?.filterName) {
          time?.filterName?.forEach((dateFilterName) => {
            searchParams.delete(dateFilterName);
          });
        }

        if (inputOptionsFilterName) {
          searchParams.delete(inputOptionsFilterName);
        }
      }
    );

    // Set the updated query params only once after all the deletions
    setSearchParams(searchParams, { replace: true });
  };

  // Remove a query parameter
  const removeQuery = (key) => {
    const params = new URLSearchParams(searchParams);
    if (params.has(key)) {
      params.delete(key);
      setSearchParams(params, { replace: true });
    }
  };

  // Get a query parameter
  const getQuery = (key) => {
    return searchParams.get(key);
  };

  return {
    setQuery,
    removeQuery,
    getQuery,
    searchParams,
    setSearchParams,
    removeMultipleQueries,
  };
};

export default useQueryParams;
