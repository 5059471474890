import { Box, Stack } from "@mui/material";
import React, { useEffect, useState } from "react";
import AnalyticsCard from "./AnalyticsCard";
import axiosInstance from "../utils/axiosConfig";
import { showErrorToast } from "../utils/toastUtils";
import banknoteIcon from "../assets/summary/Banknote.svg";
import carIcon from "../assets/summary/carIcon.svg";
import userIcon from "../assets/summary/userIcon.svg";
import arrowActionsIcons from "../assets/customerAnalytics/arrowAction.svg";
import ticketsIcon from "../assets/customerAnalytics/tickestIcon.svg";
import userIcon1 from "../assets/summary/userIcon1.svg";
import CustomSelect from "./reusableComponents/CustomSelect";
import CircularLoader from "./CircularLoader";
import { SelectOptions, initialDateFilter } from "../const/analytics";
import CommonDateFilterPanel from "./CommonDateFilterPanel";
import { useNavigate } from "react-router-dom";
import { formatDateWithDayJs } from "../utils/formateDate";

const CustomerAnalytics = () => {
  const [customerData, setCoustomerData] = useState({});
  const navigate = useNavigate();

  const [dateFilter, setDateFilter] = useState(initialDateFilter);

  const [selectedOption, setSelectedOption] = useState("TODAY");

  const handleDateFilter = (name, value) => {
    if (name === "select" && value !== "CUSTOM") {
      setDateFilter((prev) => ({
        ...prev,
        [name]: value,
        from_time: null,
        to_time: null,
      }));

      return;
    }

    setDateFilter((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const [loader, setLoader] = useState(false);

  const handleCardCLick = (e, headerText) => {
    e.stopPropagation();

    switch (headerText) {
      case "Today’s Completed Trips":
        navigate("/customer-trips", {
          state: {
            range: dateFilter?.select?.toLocaleLowerCase(),
            status: "COMPLETED",
          },
        });
        return;
      case "Today’s Created Trips":
        navigate("/customer-trips", {
          state: {
            range: dateFilter?.select?.toLocaleLowerCase(),
          },
        });
        return;
      default:
        return;
    }
  };

  const customerDataObj = {
    // todays_revenue: {
    //   text: "Today’s Revenue",
    //   svgIcon: banknoteIcon,
    // },
    // total_revenue: {
    //   text: "Total Revenue",
    //   svgIcon: banknoteIcon,
    // },
    todays_trips: {
      text: "Today’s Created Trips",
      svgIcon: carIcon,
    },
    active_session: {
      text: "Current Active Customer Session Count",
      svgIcon: userIcon,
    },
    total_downloads: {
      text: "Total Customer App Downloads",
      svgIcon: arrowActionsIcons,
    },
    new_downloads: {
      text: "New Customer App Downloads",
      svgIcon: arrowActionsIcons,
    },
    avg_ticket_size_booking: {
      text: "Avg Booking Ticket Size",
      svgIcon: ticketsIcon,
    },
    avg_session_on_app: {
      text: "Avg Customer Session Duration On App",
      svgIcon: userIcon1,
    },
    todays_completed_trips: {
      text: "Today’s Completed Trips",
      svgIcon: carIcon,
    },
  };

  //css
  const containerStyle = {
    display: "flex",
    flexDirection: "column",
    boxSizing: "border-box",
    gap: "15px",
    position: "relative",
    paddingBottom: "10px",
  };

  const flexContainer = {
    display: "flex",
    flexDirection: "row",
    flexWrap: { xs: "wrap", lg: "noWrap" },
    boxSizing: "border-box",
    gap: "20px",
  };

  const flexChildContainer = {
    width: { xs: "100%", lg: "49%" },
    mt: "20px",
    flexWrap: "wrap",
  };

  const getSummary = async () => {
    const queryBuilder = () => {
      const querys = [];

      const format = "DD-MM-YYYY";

      querys.push(`special=RIDER`);

      if (dateFilter?.select && dateFilter !== "CUSTOM") {
        querys.push(`range=${dateFilter?.select}`);
      }

      if (
        dateFilter?.select === "CUSTOM" &&
        dateFilter?.from_time &&
        dateFilter?.to_time
      ) {
        const custom_start_date = formatDateWithDayJs(
          dateFilter?.from_time,
          format
        );

        const custom_end_date = formatDateWithDayJs(
          dateFilter?.to_time,
          format
        );

        querys.push(`range=${dateFilter?.select}`);
        querys.push(`custom_start_date=${custom_start_date}`);
        querys.push(`custom_end_date=${custom_end_date}`);
      }

      return querys.length > 0 ? `?${querys.join("&")}` : "";
    };

    setLoader(true);

    const querys = queryBuilder();

    let url = `/crm/v3/summary/${querys}`;
    try {
      let response = await axiosInstance.get(url);

      if (response) {
        let customerSummary = response?.data?.data?.customer_summary ?? null;
        setCoustomerData(customerSummary);
      }
    } catch (error) {
      showErrorToast(error);
    } finally {
      setLoader(false);
    }
  };

  const convertSecondsToMinutes = (value) => {
    const time = value / 60;

    return `${time.toFixed(2)} Minutes`;
  };

  useEffect(() => {
    // calling summary funtion
    if (dateFilter?.select !== "CUSTOM") {
      getSummary(dateFilter?.select);
    } else if (dateFilter?.from_time && dateFilter?.to_time) {
      getSummary(dateFilter?.select);
    }
  }, [dateFilter]);

  return loader ? (
    <CircularLoader />
  ) : (
    <Box sx={containerStyle}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          margin: "14px 0",
          alignContent: "flex-end",
        }}
      >
        <CommonDateFilterPanel
          dateFilter={dateFilter}
          filterSelectOptions={SelectOptions}
          handleDateFilter={handleDateFilter}
        />
      </Box>
      {/* <CustomSelect
        state={selectedOption}
        setState={setSelectedOption}
        options={SelectOptions}
        top={"-40px"}
      /> */}
      {/* <Box sx={flexContainer}>
        <Box sx={flexChildContainer}>
          <AnalyticsCard
            headerText={customerDataObj.todays_revenue.text}
            svg={customerDataObj.todays_revenue.svgIcon}
            value={customerData?.todays_revenue ?? 0}
          />
        </Box>
        <Box sx={flexChildContainer}>
          <AnalyticsCard
            headerText={customerDataObj.total_revenue.text}
            svg={customerDataObj.total_revenue.svgIcon}
            value={customerData?.total_revenue ?? 0}
          />
        </Box>
      </Box> */}
      <Box sx={flexContainer}>
        <Box sx={flexChildContainer}>
          <Stack
            direction={{ xs: "column", lg: "row" }}
            gap={"25px"}
            width={"100%"}
          >
            <AnalyticsCard
              headerText={customerDataObj.todays_trips.text}
              svg={customerDataObj.todays_trips.svgIcon}
              value={customerData?.todays_trips ?? 0}
              cursorType="pointer"
              handleClick={handleCardCLick}
            />{" "}
            <AnalyticsCard
              headerText={customerDataObj.todays_completed_trips.text}
              svg={customerDataObj.todays_completed_trips.svgIcon}
              value={customerData?.todays_completed_trips ?? ""}
              cursorType="pointer"
              handleClick={handleCardCLick}
            />
          </Stack>
        </Box>
        <Box sx={flexChildContainer}>
          <Stack direction={{ xs: "column", sm: "row" }} gap={"25px"}>
            <AnalyticsCard
              headerText={customerDataObj.total_downloads.text}
              svg={customerDataObj.total_downloads.svgIcon}
              value={customerData?.downloads?.total ?? 0}
            />{" "}
            <AnalyticsCard
              headerText={customerDataObj.new_downloads.text}
              svg={customerDataObj.new_downloads.svgIcon}
              value={customerData?.downloads?.new ?? 0}
            />
          </Stack>
        </Box>
      </Box>
      <Box sx={flexContainer}>
        <Box sx={flexChildContainer}>
          <AnalyticsCard
            headerText={customerDataObj.avg_ticket_size_booking.text}
            svg={customerDataObj.avg_ticket_size_booking.svgIcon}
            value={
              customerData?.avg_ticket_size_booking
                ? `₱ ${customerData.avg_ticket_size_booking}`
                : "₱ 0"
            }
          />
        </Box>
        <Box sx={flexChildContainer}>
          <AnalyticsCard
            format={true}
            headerText={customerDataObj.avg_session_on_app.text}
            svg={customerDataObj.avg_session_on_app.svgIcon}
            value={
              customerData?.avg_session_on_app
                ? convertSecondsToMinutes(customerData?.avg_session_on_app)
                : "0 Min"
            }
          />
        </Box>
      </Box>

      <Box sx={flexChildContainer}>
        <AnalyticsCard
          headerText={customerDataObj.active_session.text}
          svg={customerDataObj.active_session.svgIcon}
          value={customerData?.active_session ?? 0}
        />
      </Box>
    </Box>
  );
};

export default CustomerAnalytics;
