import React from "react";
import FlexBetween from "./FlexBetween";
import { Box, Stack } from "@mui/system";
import { Checkbox, MenuItem, TextField, Typography } from "@mui/material";

const DriverTrainingDetails = ({
  tranningDetails,
  handleTrainingDetailsChanges,
  tranningCenter,
  handleTraningCenter,
  setTrainingDetails,
  validateTranningDetails,
  handleCheckboxChange,
  statusCheckBox,
  slotOptions = [],
  handleSlotOptions = () => {},
  isDisabledCheckbox = false,
}) => {
  return (
    <>
      <FlexBetween sx={{ m: "1.5rem 1rem" }}>
        <Typography gutterBottom variant="h6" component="div" mt="0.5rem">
          Training Details
        </Typography>
      </FlexBetween>
      <Box sx={{ width: "80%", m: "1.5rem 1.5rem" }}>
        <Stack pacing={2} direction="row" sx={{ marginBottom: 4 }}>
          <TextField
            type="date"
            InputLabelProps={{
              shrink: true,
            }}
            sx={{ m: "0 12px 0 0" }}
            variant="outlined"
            disabled={tranningDetails.auto_appoint}
            color="secondary"
            name="date"
            value={tranningDetails.date}
            onChange={(e) => {
              handleTrainingDetailsChanges(e);
            }}
            label={"Appointment Date"}
            fullWidth
          />
          <TextField
            type="text"
            sx={{ m: "0 12px 0 0" }}
            disabled
            variant="outlined"
            color="secondary"
            name="date"
            value={tranningDetails.reporting_time}
            label={"Appointment Time"}
            fullWidth
          />
        </Stack>
        <Stack>
          <TextField
            select
            label="Venue Address"
            disabled={tranningDetails.auto_appoint}
            value={tranningDetails.training_center_detail_id}
            name="training_center_address"
            onChange={handleTraningCenter}
          >
            {tranningCenter.length > 0 &&
              tranningCenter.map((res, index) => (
                <MenuItem key={res.id} value={res.id}>
                  {res.training_center_address}
                </MenuItem>
              ))}
          </TextField>
        </Stack>
        <Stack mt={"18px"}>
          <TextField
            select
            label="Slots"
            disabled={tranningDetails.auto_appoint || slotOptions.length === 0}
            value={tranningDetails.slot_key}
            name="slot_key"
            onChange={handleSlotOptions}
          >
            {slotOptions.length > 0 &&
              slotOptions.map((res, index) => (
                <MenuItem key={res.slot_key} value={res.slot_key}>
                  {res.render_name}
                </MenuItem>
              ))}
          </TextField>
        </Stack>
      </Box>
      <Box
        sx={{
          display: "flex",
          m: "0 1.5rem",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Checkbox
            checked={tranningDetails.auto_appoint}
            color="success"
            name="auto_appoint"
            onChange={(e) => {
              setTrainingDetails((prev) => ({
                ...prev,
                auto_appoint: e?.target?.checked ?? false,
              }));
            }}
            sx={{ color: "#009D0F" }}
            inputProps={{ "aria-label": "controlled" }}
          />
          <Box>
            <Typography
              gutterBottom
              variant="h6"
              component="div"
              mt="0.5rem"
              sx={{
                fontSize: "22px",
                fontStyle: "normal",
                fontFamily: "Proxima Nova",
                lineHeight: "normal",
              }}
            >
              AutoAppoint
            </Typography>
          </Box>
        </Box>
      </Box>
      <br />
      <Box
        sx={{
          display: "flex",
          backgroundColor: tranningDetails.is_verified
            ? "rgba(0, 157, 15, 0.20)"
            : "#EAEAEA",
        }}
      >
        <Checkbox
          checked={tranningDetails.is_verified}
          disabled={isDisabledCheckbox}
          color="success"
          name="is_verified"
          onChange={(e) => {
            const obj = {
              ...statusCheckBox,
              tranningDetails: e.target.checked,
            };
            if (handleCheckboxChange(obj, "tranningDetails")) return;
            validateTranningDetails(e);
          }}
          sx={{ color: "#009D0F" }}
          inputProps={{ "aria-label": "controlled" }}
        />
        <Typography
          gutterBottom
          variant="h6"
          component="div"
          mt="0.5rem"
          sx={{
            fontSize: "22px",
            fontStyle: "normal",
            fontFamily: "Proxima Nova",
            lineHeight: "normal",
          }}
        >
          Training done
        </Typography>
      </Box>
    </>
  );
};

export default DriverTrainingDetails;
