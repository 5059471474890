import { Button } from "@mui/material";
import React from "react";
import { ArrowDropDownOutlined } from "@mui/icons-material";

const FilterByButton = ({
  toggleDrawer = () => {},
  varientType = "text",
  text = "Filter By",
  endIcon = "",
}) => {
  //css
  const buttonStyle = {
    width: "auto",
    border: "1px solid rgba(0, 0, 0, 0.30)",
    borderRadius: "12px",
    height: "45px",
    fontSize: "15px",
    fontFamily: "Proxima Nova",
    color: "#000",
    fontWeight: 600,
    padding: "0px 15px",
    backgroundColor: "#fff",
    lineHeight: "normal",
  };

  return (
    <Button
      variant={varientType}
      sx={buttonStyle}
      onClick={toggleDrawer("right", true)}
      endIcon={endIcon ? endIcon : <ArrowDropDownOutlined />}
    >
      {text}
    </Button>
  );
};

export const SortByButton = ({
  toggleDrawer = () => {},
  varientType = "text",
  text = "Sort By",
  endIcon = "",
}) => {
  //css
  const buttonStyle = {
    width: "auto",
    border: "1px solid rgba(0, 0, 0, 0.30)",
    borderRadius: "12px",
    height: "45px",
    fontSize: "15px",
    fontFamily: "Proxima Nova",
    color: "#000",
    fontWeight: 600,
    padding: "0px 15px",
    backgroundColor: "#fff",
    lineHeight: "normal",
  };

  return (
    <Button
      variant={varientType}
      sx={buttonStyle}
      onClick={toggleDrawer("right", true)}
      endIcon={endIcon ? endIcon : <ArrowDropDownOutlined />}
    >
      {text}
    </Button>
  );
};


export default FilterByButton;
