import { Box, Button, IconButton, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import useDebounce from "../hooks/useDebounce";
import { toast } from "react-toastify";
import axiosInstance from "../utils/axiosConfig";
import { showErrorToast, showSuccessToast } from "../utils/toastUtils";
import DataTable from "./DataTable";
import CloseIcon from "@mui/icons-material/Close";
import SwipeableDrawerComponent from "./SwipeableDrawerComponent ";
import DetailsSidePanel from "./DetailsSidePanel";
import CustomFilter, {
  SortPanel,
} from "./reusableComponents/Filter/CustomFilter";
import SearchActionComponent from "./reusableComponents/SearchActionComponent";
import formatNumberShort from "../utils/formatNumberShort";
import usePermission from "../hooks/usePermission";
import FilteredResults from "./FilteredResults";
import { useSearchParams } from "react-router-dom";
import { commonSortOptions } from "../const/sort";
import useQueryParams from "../hooks/useQueryParams ";

const ToggleButton = ({
  toggledrawer = () => () => {},
  setid = () => {},
  handleClick = () => {},
  id,
  text,
}) => {
  const textCss = {
    color: "#2d5a9b",
    fontFamily: "Proxima Nova",
    fontSize: "13px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "normal",
    letterSpacing: "0.26px",
    textTransform: "capitalize",
  };

  return (
    <Button
      variant="text"
      sx={{
        display: "flex",
        justifyContent: "flex-start",
        overflow: "hidden",
      }}
      onClick={(e) => {
        setid(id);
        // this  toggledrawer funtion return a funtion then we are passing event on it
        toggledrawer("right", true)(e);
        handleClick(id);
      }}
    >
      <GenericText text={text} css={textCss} />
    </Button>
  );
};

const GenericText = ({ variant = "body1", text, css = "" }) => {
  const typoStyles = {
    color: "var(--text-dark, #000)",
    ml: "20px",
    textAlign: "center",
    fontFamily: "Proxima Nova",
    fontSize: "13px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "normal",
    letterSpacing: "0.26px",
    textTransform: "capitalize",
    overflow: "hidden",
  };
  return (
    <Typography variant={variant} sx={css ? { ...css } : { ...typoStyles }}>
      {text}
    </Typography>
  );
};

const Settlements = () => {
  const [isLoading, setisLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [driverId, setDriverId] = useState();
  const permission = usePermission();
  const { searchParams, setSearchParams, getQuery } = useQueryParams();
  // using this set driver id
  const [id, setId] = useState("");
  const page = parseInt(searchParams.get("page"), 0) || 0;
  const limit = parseInt(searchParams.get("limit"), 10) || 10;

  const [showSort, SetShowSort] = useState({ right: false });

  const [sort, setSort] = useState({
    totalCashCollected: getQuery("total_cash_collected_sort") ?? "",
    totalOnlineAmount: getQuery("total_online_amount_sort") ?? "",
    totalSettledAmount: getQuery("total_settled_amount_sort") ?? "",
    totalAmountDue: getQuery("total_amount_due_sort") ?? "",
  });

  const [settlements, setSttlements] = useState({
    data: [],
    totalCount: 0,
    paginationModel: {
      page: page || 0,
      pageSize: limit || 10,
    },
  });
  const [earningDetails, setEarningDetails] = useState({
    data: [],
    totalCount: 0,
    paginationModel: {
      page: 0,
      pageSize: 10,
    },
  });
  const [settlementSidePanel, setSettlementSidePanel] = useState({
    right: false,
  });
  const [tableType, setTableType] = useState("Settlements");

  const [showFilter, setShowFilter] = useState({
    right: false,
  });

  const [showDetailsPanel, setShowDetailsPanel] = useState({
    right: false,
  });

  const [showName, setName] = useState("");

  const [showExtraEarningDetails, setShowExtraEarningDetails] = useState(false);

  // Function To Handle Search
  const getSearchUser = async () => {
    const url = `/crm/settlement/?page=${paginationModel.page + 1}&limit=${
      paginationModel.pageSize
    }&search=${search}`;
    try {
      await axiosInstance.get(url).then((res) => {
        setSttlements(res?.data?.data.records ?? []);
        setTotalCount(res?.data?.total_count ?? 10);
      });
    } catch (error) {
      console.log("Error while Searching user in settlement page", error);
    }
  };

  // const handlePagination = (value) => {
  //   //The value will be an object that contains the page and page size, which will come form the dataGrid.
  //   // searchParams.set("page", value?.page || 0);
  //   // searchParams.set("limit", value?.pageSize || 10);

  //   // // Set the new search parameters with the replace option
  //   // setSearchParams(searchParams, { replace: true });
  //  ;
  // };

  // mui toggleDrawer funtion for side panel
  const toggleFilterDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setShowFilter({ ...showFilter, [anchor]: open });
  };

  const toggleDetailsSidepanel = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setShowDetailsPanel({ ...settlementSidePanel, [anchor]: open });
  };

  // control mui pagination
  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: 10,
    page: 0,
  });

  const [totalCount, setTotalCount] = useState({
    earningCount: 0,
    settleCount: 0,
    allEarningsCount: 0,
  });

  const [driverType, setDriverType] = useState(getQuery("driver_type") ?? "");
  const [settleon, setSettleOn] = useState(getQuery("settlements") ?? "");
  const [settleonDate, setSettleOnDate] = useState({
    startDate: "",
    endDate: "",
  });

  // Search Debounce
  const searchDebounce = useDebounce(search, 500);

  // Funtion to Builds and returns a query
  const buildQueryString = (download = false) => {
    const queryParameters = [];

    if (download) {
      queryParameters.push(`is_download=${true}`);
    }

    if (sort?.totalCashCollected) {
      queryParameters.push(
        `total_cash_collected_sort=${sort?.totalCashCollected}`
      );
    }

    if (sort?.totalOnlineAmount) {
      queryParameters.push(
        `total_online_amount_sort=${sort?.totalOnlineAmount}`
      );
    }
    if (sort?.totalSettledAmount) {
      queryParameters.push(
        `total_settled_amount_sort=${sort?.totalSettledAmount}`
      );
    }

    if (sort?.totalAmountDue) {
      queryParameters.push(`total_amount_due_sort=${sort?.totalAmountDue}`);
    }

    if (searchDebounce && search) {
      queryParameters.push(`search=${searchDebounce}`);
    }

    if (driverType) {
      queryParameters.push(`driver_type=${driverType}`);
    }

    if (settleon) {
      queryParameters.push(`settled_on=${settleon}`);
    }

    if (settleonDate.startDate && settleonDate.endDate) {
      let startDate = `${settleonDate.startDate.year()}-${settleonDate.startDate.month()}-${settleonDate.startDate.date()}`;
      let endDate = `${settleonDate.endDate.year()}-${settleonDate.endDate.month()}-${settleonDate.endDate.date()}`;
      queryParameters.push(`date_gte=${startDate}&date_lte=${endDate}`);
    }

    const queryString =
      queryParameters.length > 0 ? `&${queryParameters.join("&")}` : "";

    return queryString;
  };

  //Function To Fetch Settlements data
  const getSettlements = async (download = false) => {
    setisLoading(true);
    const query = buildQueryString(download);
    const url = `/crm/settlement-v2/?page=${
      settlements?.paginationModel?.page + 1
    }&limit=${settlements?.paginationModel?.pageSize}${query}`;
    try {
      await axiosInstance.get(url).then((res) => {
        if (download) return;
        setSttlements((prev) => ({
          ...prev,
          data: res?.data?.data.records ?? [],
          totalCount: res?.data?.data?.total_count ?? 0,
        }));
      });
    } catch (error) {
      let message = error?.response?.data?.message;
      if (message) {
        toast.error(message);
      }
    } finally {
      setisLoading(false);
    }
  };

  const getEarnings = async (download = false) => {
    const query = buildQueryString(download);
    const url = `/crm/earning/${id}/?page=${
      earningDetails?.paginationModel?.page + 1
    }&limit=${earningDetails?.paginationModel?.pageSize}${query}`;
    try {
      await axiosInstance.get(url).then((res) => {
        if (download) return;
        setEarningDetails((prev) => ({
          ...prev,
          data: res?.data?.data.records ?? [],
          totalCount: res?.data?.data?.total_count ?? 0,
        }));
      });
    } catch (error) {
      let message = error?.response?.data?.message;
      if (message) {
        toast.error(message);
      }
    }
  };

  const handleUnsettledEarningDownload = async () => {
    try {
      const response = await axiosInstance.post("crm/settled/unsettled/");

      if (response) {
        showSuccessToast(
          "file has been successfully sent via Email. Check your Email account",
          {
            autoClose: 5000,
          }
        );
      }
    } catch (error) {
      showErrorToast(error);
    }
  };

  // Funtion change tableType accroding to the name
  const handleChangeTableType = (type = "") => {
    if (showExtraEarningDetails) {
      setShowExtraEarningDetails(false);
    }

    if (!type) return;
    setTableType(type);
  };

  const checkIsFalsy = (value) => {
    if (!value && value !== 0) {
      return true;
    }

    return false;
  };

  const columns = [
    {
      field: `driver_name`,
      headerName: "Driver Name",
      flex: 1,
      renderCell: (param) => {
        if (!param?.value) return "--";

        return <GenericText text={param?.value} />;
      },
    },

    {
      field: `driver_nick_name`,
      headerName: "Driver nick name",
      flex: 1,
      renderCell: (param) => {
        if (!param?.value) return "--";

        return <GenericText text={param?.value} />;
      },
    },
    {
      field: `total_cash_collected`,
      headerName: "Total Cash Collected",
      flex: 1,
      renderCell: (param) => {
        if (checkIsFalsy(param?.value)) return "--";

        return <GenericText text={`₱ ${param?.value}`} />;
      },
    },
    {
      field: `total_online_amount`,
      headerName: "Total Online Amount",
      flex: 1,
      renderCell: (param) => {
        if (checkIsFalsy(param?.value)) return "--";

        return <GenericText text={`₱ ${param?.value}`} />;
      },
    },
    {
      field: `total_settlement_till_date`,
      headerName: "Total Settled Amount",
      flex: 1,
      renderCell: (param) => {
        if (checkIsFalsy(param?.value)) return "--";

        return <GenericText text={`₱ ${param?.value}`} />;
      },
    },
    {
      field: `total_amount_due`,
      headerName: "Total Amount Due",
      flex: 1,
      renderCell: (param) => {
        if (checkIsFalsy(param?.value)) return "--";

        return <GenericText text={`₱ ${param?.value}`} />;
      },
    },
  ];

  const settlementDetailsColumn = [
    {
      field: `booking_id`,
      headerName: "Booking ID",
      flex: 0.8,
      renderCell: (param) => {
        if (!param?.value) {
          return "--";
        }

        let booking_id = param?.row?.booking_id ?? "";
        let id = booking_id?.split("P").pop();
        return (
          <ToggleButton
            text={param.value}
            toggledrawer={toggleDetailsSidepanel}
            id={id}
            setid={setDriverId}
          />
        );
      },
    },
    {
      field: `actual_ride_fare`,
      headerName: "Actual Ride Fare",
      flex: 1,
      renderCell: (param) => {
        if (checkIsFalsy(param?.value)) return "--";

        return <GenericText text={`₱ ${param?.value}`} />;
      },
    },
    {
      field: `peekup_charge`,
      headerName: "Comission",
      flex: 1,
      renderCell: (param) => {
        if (checkIsFalsy(param?.value)) return "--";

        return <GenericText text={`₱ ${param?.value}`} />;
      },
    },
    {
      field: `total_earning`,
      headerName: "Driver Earning",
      flex: 1,
      renderCell: (param) => {
        if (checkIsFalsy(param?.value)) return "--";

        return <GenericText text={`₱ ${param?.value}`} />;
      },
    },
    {
      field: `amount_due`,
      headerName: "Settlement Amount",
      flex: 1,
      renderCell: (param) => {
        if (!param?.value) return "--";

        return <GenericText text={param?.value} />;
      },
    },
    {
      field: `is_settled`,
      headerName: "Settlement Status",
      flex: 1,
      renderCell: (param) => {
        if (!param?.value && typeof param?.value !== "boolean") return "--";

        const text = param?.value ? "Settled" : "Not Settled";

        return <GenericText text={text} />;
      },
    },
  ];

  const handleClear = () => {
    if (settleon) {
      setSettleOn("");
    }

    if (settleonDate.startDate || settleonDate.endDate) {
      setSettleOnDate({
        startDate: "",
        endDate: "",
      });
    }

    if (driverType) {
      setDriverType("");
    }
  };

  const customeDateHandler = (name, value, setState) => {
    setState((prev) => ({ ...prev, [name]: value }));
  };

  const filterOptions = [
    {
      summryText: "Settlements",
      state: settleon,
      filterName: "settlements",
      setState: setSettleOn,
      options: [
        { value: "yesterday", label: "Yesterday" },
        { value: "last_week", label: "Last Week" },
        { value: "last_month", label: "Last Month" },
        { value: "last_year", label: "Last Year" },
      ],
      date: {
        customDateState: settleonDate,
        customDateHeader: "Custom",
        customDateLabel: ["From", "To"],
        customDateName: ["startDate", "endDate"],
        customeDateHandler,
        setState: setSettleOnDate,
        isoString: true,
      },
    },
    {
      summryText: "Driver type",
      filterName: "driver_type",
      state: driverType,
      setState: setDriverType,
      options: [
        { value: "INDIVIDUAL", label: "Individual" },
        {
          value: "VIA_OPERATOR",
          label: "Operators",
        },
      ],
    },
  ];

  // config
  const allowed_sub_sections = permission?.SUB_SECTIONS ?? ["settlements"];
  const sub_section_to_label = {
    settlements: "Settlements",
  };

  const tableHeadButtonConfig = allowed_sub_sections?.map((sub_section) => {
    return {
      label: sub_section_to_label[sub_section],
      status: tableType,
      totalcount: settlements?.totalCount ?? 0,
    };
  });

  const toggleSortDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    SetShowSort({ ...showSort, [anchor]: open });
  };

  const sortFilterConfig = {
    options: [
      {
        summaryText: "Total Cash Collected",
        name: "totalCashCollected",
        state: sort?.totalCashCollected ?? "",
        options: commonSortOptions,
        filterName: "total_cash_collected_sort",
      },
      {
        summaryText: "Total Online Amount",
        name: "totalOnlineAmount",
        state: sort?.totalOnlineAmount ?? "",
        options: commonSortOptions,
        filterName: "total_online_amount_sort",
      },
      {
        summaryText: "Total Settled Amount",
        name: "totalSettledAmount",
        state: sort?.totalSettledAmount ?? "",
        options: commonSortOptions,
        filterName: "total_settled_amount_sort",
      },
      {
        summaryText: "Total Amount Due",
        name: "totalAmountDue",
        state: sort?.totalAmountDue ?? "",
        options: commonSortOptions,
        filterName: "total_amount_due_sort",
      },
    ],
    handleClearState: () => {},
    // handleFilterState: handleFilterState,
  };

  useEffect(() => {
    if (!tableType) return;
    if (tableType === "Settlements") {
      getSettlements();
    }
  }, [
    tableType,
    settlements?.paginationModel,
    searchDebounce,
    driverType,
    settleon,
    settleonDate,
    sort,
  ]);

  useEffect(() => {
    if (!tableType && id) {
      getEarnings();
    }
  }, [id, earningDetails.paginationModel]);

  return (
    <Box
      sx={{
        margin: { sm: "1.5rem 1.5rem", overflowX: "hidden" },
      }}
    >
      <SearchActionComponent
        toggleDrawer={toggleFilterDrawer}
        value={search}
        setState={setSearch}
        placeholder={"Search Driver , Phone no, Email"}
        toggleSortDrawer={toggleSortDrawer}
        require={{ search: true, filter: true, download: true, sort: false }}
        downloadFile={() => {
          switch (tableType) {
            case "Settlements":
              getSettlements(true);
              return;
            default:
              return;
          }
        }}
      >
        {permission?.DOWNLOAD && (
          <Button
            sx={{
              width: "fit-content",
              border: "1px solid rgba(0, 0, 0, 0.30)",
              background: "#fff",
              borderRadius: "12px",
              fontSize: "15px",
              height: "42px",
              fontFamily: "Proxima Nova",
              color: "#000",
              fontWeight: 600,
              lineHeight: "normal",
            }}
            variant="Primary"
            onClick={() => handleUnsettledEarningDownload()}
          >
            Unsettled Earnings
          </Button>
        )}
      </SearchActionComponent>

      {filterOptions && <FilteredResults options={filterOptions} />}

      <Box
        sx={{
          mt: "15px",
          display: "flex",
          gap: "0.25rem",
        }}
      >
        {tableHeadButtonConfig?.map((show_section) => (
          <Button
            key={show_section?.label}
            sx={{
              display: "flex",
              width: "142px",
              padding: "10px",
              justifyContent: "center",
              alignItems: "center",
              gap: "10px",
              marginTop: "25px",
              borderRadius: "10px 10px 0 0",
              border: "1px solid #929EAE",
            }}
            style={{
              background:
                tableType === show_section?.label ? "lightgray" : "#FFF",
            }}
            onClick={() => {
              handleChangeTableType(show_section?.label);
            }}
          >
            <Typography
              variant="h4"
              sx={{
                fontSize: "14px",
                fontFamily: "Proxima Nova",
                textAlign: "right",
                fontWeight: "600",
                lineHeight: "normal",
                letterSpacing: "0.28px",
                textTransform: "capitalize",
                color: "#000",
              }}
            >
              {show_section?.label}
            </Typography>

            {show_section?.totalcount > 0 &&
              show_section?.status === show_section?.label && (
                <Box
                  sx={{
                    display: "inline-flex",
                    padding: "2px 5px",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: "10px",
                    background: "#000",
                  }}
                >
                  <Typography
                    sx={{
                      color: "#fff",
                      textAlign: "right",
                      fontFamily: "Proxima Nov",
                      fontSize: "14px",
                      lineHeight: "normal",
                      fontWeight: "600px",
                      letterSpacing: "0.28px",
                      textTransform: "capitalize",
                    }}
                  >
                    {formatNumberShort(show_section?.totalcount ?? 0)}
                  </Typography>
                </Box>
              )}
          </Button>
        ))}

        {showExtraEarningDetails && (
          <Button
            sx={{
              display: "flex",
              width: "auto",
              padding: "10px",
              justifyContent: "flex-start",
              alignItems: "center",
              gap: "10px",
              marginTop: "25px",
              borderRadius: "10px 10px 0 0",
              border: "1px solid #929EAE",
              position: "relative",
            }}
            style={{
              background: true ? "lightgray" : "#FFF",
            }}
            onClick={() => {
              handleChangeTableType("Settlements");
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "5px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                }}
              >
                <Typography
                  variant="h4"
                  sx={{
                    fontSize: "14px",
                    fontFamily: "Proxima Nova",
                    textAlign: "right",
                    fontWeight: "600",
                    lineHeight: "normal",
                    m: "0",
                    letterSpacing: "0.28px",
                    textTransform: "capitalize",
                    maxWidth: "150px",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    color: "#000",
                  }}
                >
                  earnings
                </Typography>
              </Box>

              <Typography
                sx={{
                  color: "#000",
                  maxWidth: "110px",
                  textOverflow: "ellipsis",
                  height: "18px",
                  overflow: "hidden",
                  fontFamily: "Proxima Nov",
                  fontSize: "14px",
                  lineHeight: "normal",
                  fontWeight: "600px",
                  letterSpacing: "0.28px",
                  textTransform: "capitalize",
                }}
              >
                ({showName})
              </Typography>

              {earningDetails.totalCount > 0 && (
                <Box
                  sx={{
                    display: "inline-flex",
                    padding: "2px 5px",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: "10px",
                    background: "#000",
                  }}
                >
                  <Typography
                    sx={{
                      color: "#fff",
                      textAlign: "right",
                      fontFamily: "Proxima Nov",
                      fontSize: "14px",
                      lineHeight: "normal",
                      fontWeight: "600px",
                      letterSpacing: "0.28px",
                      textTransform: "capitalize",
                    }}
                  >
                    {earningDetails.totalCount}
                  </Typography>
                </Box>
              )}
            </Box>

            <IconButton
              sx={{
                position: "absolute",
                top: "-7px",
                right: "-8px",
                backgroundColor: "#F1554C",
                height: "15px",
                width: "15px",
                padding: "12px",
                "&:hover": {
                  backgroundColor: "#F1554C",
                },
              }}
              onClick={(e) => {
                e.stopPropagation();
                setTableType("Settlements");
                setId("");
                setShowExtraEarningDetails(false);
                if (showName) {
                  setName("");
                }
              }}
            >
              <CloseIcon
                sx={{
                  fill: "#fff",
                }}
              />
            </IconButton>
          </Button>
        )}
      </Box>
      {tableType === "Settlements" && (
        <DataTable
          loading={isLoading}
          alternativeId={"driver_id"}
          row={settlements?.data || []}
          columns={columns || []}
          handleRowClick={(row) => {
            setId(row?.id ?? "");
            setName(row?.row?.driver_name ?? "");
            setShowExtraEarningDetails((prev) => !prev);
            setTableType("");
          }}
          paginationModel={settlements?.paginationModel ?? {}}
          setPaginationModel={setSttlements}
          totalCount={settlements?.totalCount ?? 0}
          paginationInnerObject={true}
        />
      )}

      {showExtraEarningDetails && !tableType && (
        <DataTable
          loading={isLoading}
          row={earningDetails?.data ?? []}
          columns={settlementDetailsColumn}
          paginationModel={earningDetails?.paginationModel ?? {}}
          totalCount={earningDetails?.totalCount ?? 0}
          setPaginationModel={setEarningDetails}
          paginationInnerObject={true}
        />
      )}

      <SwipeableDrawerComponent
        isOpen={showFilter["right"]}
        anchor="right"
        onClose={toggleFilterDrawer("right", false)}
      >
        <CustomFilter
          filterOptions={filterOptions}
          handleClear={handleClear}
          toggleDrawer={toggleFilterDrawer}
        />
      </SwipeableDrawerComponent>
      {showSort.right && (
        <SwipeableDrawerComponent
          isOpen={showSort["right"]}
          onClose={toggleSortDrawer("right", false)}
          anchor="right"
        >
          <SortPanel
            toggleDrawer={toggleSortDrawer}
            sortOptions={sortFilterConfig?.options}
            handleClear={handleClear}
            setState={setSort}
          />
        </SwipeableDrawerComponent>
      )}

      <SwipeableDrawerComponent
        isOpen={showDetailsPanel["right"]}
        onClose={toggleDetailsSidepanel("right", false)}
        anchor="right"
      >
        <DetailsSidePanel
          DId={driverId}
          setDriverId={setDriverId}
          specialFilter={"both_ticket"}
          anchor="right"
          toggleDrawer={toggleDetailsSidepanel}
        />
      </SwipeableDrawerComponent>
    </Box>
  );
};

export default Settlements;
