import React, { useState, useEffect, useMemo } from "react";
import {
  Box,
  Button,
  Divider,
  MenuItem,
  TextField,
  Typography,
  Stack,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import FlexBetween from "./FlexBetween";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import FileUploadInput from "./FileUploadInput";
import Checkbox from "@mui/material/Checkbox";
import dp from "../assets/dp.jpg";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import { CheckCircleOutlineOutlined } from "@mui/icons-material";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axiosInstance from "../utils/axiosConfig";
import isFileValid, {
  handleCheckboxChange,
  isFileExtensionValid,
} from "../utils/fileValidation";
import calculateAge from "../utils/calculateAge";
import filterObjectsWithProperties from "../utils/filterObjectsWithProperties";
import { showErrorToast } from "../utils/toastUtils";
import DriverBasicDetails from "./DriverBasicDetails";
import { getFileType } from "../utils/fileUtilis";
import GenericFileUpload from "./GenericFileUpload";
import CommonAutoComplete from "./CommonAutoComplete";
import DriverTrainingDetails from "./DriverTrainingDetails";
import axios from "axios";
import usePermission from "../hooks/usePermission";

const carTypeCategory = [
  {
    value: "COMPACT",
    label: "COMPACT",
  },
  {
    value: "TAXI",
    label: "TAXI",
  },
  {
    value: "PLUS",
    label: "PLUS",
  },
];

const initialProfilePictureState = {
  url: "",
  imgId: "",
};
const NewDriver = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [operator, setOpertaor] = useState([]);
  const [driverImage, setDriverImage] = useState("");
  const [profilePicture, setProfilePicture] = useState({
    url: "",
    imgId: "",
  });
  const permission = usePermission();
  const [bankNameOptions, setBankNameOptions] = useState([]);
  const [validateLtfrb, setValidateLtfrb] = useState(null);
  const [basicDetails, setBasicDetails] = useState({
    name: "",
    email: "",
    gender: "",
    dob: "",
    address1: "",
    address2: "",
    address3: "",
    cityCode: "",
    phoneNumber: "",
    is_verified: "",
    operator_full_name: "",
    TIN: "",
    tnvsCheck: "",
  });

  const [slotOptions, setSlotOptions] = useState([]);

  const handleSlotOptions = (e) => {
    const { value } = e.target;

    const renderTime = slotOptions.find((res) => res.slot_key === value);

    setTrainingDetails((prev) => ({
      ...prev,
      slot_key: value,
      reporting_time: renderTime?.render_time,
    }));
  };

  const handleTrainingCenterAndSlotOptions = (value, tranningCenter) => {
    let trainingCenterObject = tranningCenter.filter((res) => res.id === value);
    if (trainingCenterObject.length > 0) {
      trainingCenterObject = trainingCenterObject[0];
      const slot_name_and_default_capacity = Object.entries(
        trainingCenterObject?.metadata?.slot_name_and_default_capacity ?? {}
      );

      if (slot_name_and_default_capacity.length > 0) {
        const slotoptions = slot_name_and_default_capacity.reduce(
          (acc, [key, value]) => {
            const obj = {
              slot_key: key,
              render_name: value["render_name"],
              render_time: value["render_time"],
            };

            acc.push(obj);

            return acc; // Make sure to return the accumulator
          },
          []
        );

        setSlotOptions(slotoptions);
      }
    }

    return trainingCenterObject;
  };

  const handleTraningCenter = (e) => {
    const { value } = e.target;

    const trainingCenterObject = handleTrainingCenterAndSlotOptions(
      value,
      tranningCenter
    );

    setTrainingDetails((prev) => ({
      ...prev,
      training_center_detail_id: trainingCenterObject?.id ?? "",
      // reporting_time: trainingCenterObject?.reporting_time ?? "",
      training_center_address:
        trainingCenterObject?.training_center_address ?? "",
    }));
  };

  const [tranningCenter, setTrainingCenter] = useState([]);

  const [approved, setApproved] = useState({
    id: "",
    is_verified: false,
  });

  const handleApproved = () => {
    setApproved((prev) => ({ ...prev, is_verified: true }));
  };

  const [apiCallMade, setApiCallMade] = useState(false);

  const [tranningDetails, setTrainingDetails] = useState({
    id: "",
    is_verified: false,
    date: "",
    training_center_detail_id: "",
    appintment_detail_id: "",
    reporting_time: "",
    training_center_address: "",
    training_center_name: "",
    auto_appoint: false,
    slot_key: "",
  });

  const titles = [
    "driving_license",
    "nbi_certificate",
    "covid_19_certificate",
    "ltfrb_id",
    "fit_to_work",
  ];

  //   "insurance",

  const currentYear = new Date().getFullYear() + 2;

  const config = useSelector(
    (store) => store.config.configValues.server_base_url
  );

  const [document1, setDocument1] = useState({
    title: "Driving License",
    url: "",
    uploadID: "",
    document_front_extension: "",
  });
  const [document2, setDocument2] = useState({
    title: "NBI",
    url: "",
    uploadID: "",
    document_front_extension: "",
  });
  const [document3, setDocument3] = useState({
    title: "COVID-19",
    url: "",
    uploadID: "",
    document_front_extension: "",
  });
  const [document4, setDocument4] = useState({
    title: "LTFRB (Front)",
    url: "",
    uploadID: "",
    document_front_extension: "",
  });

  const [document5, setDocument5] = useState({
    title: "LTFRB (Back Optional)",
    url: "",
    uploadID: "",
    document_front_extension: "",
  });
  const [document6, setDocument6] = useState({
    title: "Fit to work",
    url: "",
    uploadID: "",
    document_front_extension: "",
  });

  const [carDocument1, setCarDocument1] = useState({
    title: "Official Receipt (OR)",
    url: "",
    uploadId: "",
    document_front_extension: "",
  });
  const [carDocument2, setCarDocument2] = useState({
    title: "Certificate of Registration (CR)",
    url: "",
    uploadId: "",
    document_front_extension: "",
  });

  const [carDocument3, setCarDocument3] = useState({
    title: "INSURANCE",
    url: "",
    uploadID: "",
    document_front_extension: "",
  });

  const [driverId, setDriverId] = useState("");

  // Function To Fetch Training Center
  const getTrainingCenter = async () => {
    let url = "/crm/training-center-v2/";

    try {
      await axiosInstance.get(url).then((res) => {
        setTrainingCenter(res?.data?.data?.records ?? []);
        setTrainingDetails((prev) => ({
          ...prev,
          reporting_time: res?.data?.data?.records[0]?.reporting_time ?? "",
        }));
      });
    } catch (error) {
      console.log("error", error);
    }
  };

  //driver types || operatorName state
  const [driverType, setDriverType] = useState("");
  const [operatorName, setOpertaorName] = useState("");

  // car images state
  const [carImage1, setCarImage1] = useState({
    title: "carImage1",
    id: "",
    image_id: "",
    old_id: "",
    new_id: "",
    url: "",
  });
  const [carImage2, setCarImage2] = useState({
    title: "carImage2",
    id: "",
    image_id: "",
    old_id: "",
    new_id: "",
    url: "",
  });
  const [carImage3, setCarImage3] = useState({
    title: "carImage3",
    id: "",
    old_id: "",
    image_id: "",
    new_id: "",
    url: "",
  });
  const [carImage4, setCarImage4] = useState({
    title: "carImage4",
    id: "",
    old_id: "",
    image_id: "",
    new_id: "",
    url: "",
  });
  const [carImage5, setCarImage5] = useState({
    title: "carImage5",
    id: "",
    old_id: "",
    image_id: "",
    new_id: "",
    url: "",
  });

  const handleTrainingDetailsChanges = (e) => {
    const { name, value } = e.target;
    let val = name === "is_verified" ? e.target.checked : value;
    setTrainingDetails((prev) => ({ ...prev, [name]: val }));
  };

  const age = useMemo(() => calculateAge(basicDetails.dob), [basicDetails.dob]);

  const [basicDetailsCheck, setBasicDetailsCheck] = useState(false);
  const [documentsCheck, setDocumentsCheck] = useState(false);
  const [carDetailsCheck, setCarDetailsCheck] = useState(false);
  const [bankDetailsCheck, setBankDetailsCheck] = useState(false);

  const [carDetails, setCarDetails] = useState({
    carRegNumber: "",
    carColor: "",
    carModel: "",
    carYear: "",
    carCompany: "",
    carType: "",
    caseId: "",
  });
  const [bankDetails, setBankDetails] = useState({
    bankName: "",
    branchBSB: "",
    accountNumber: "",
    accountHolderName: "",
  });
  const [bankDocumentFile, setBankDocumentFile] = useState({
    url: "",
    bankUpoadID: "",
    document_front_extension: "",
  });

  //Funtion to Validate driver
  const DriverDetailsValidationByApi = async (obj) => {
    if (!obj) return;

    const url = "/crm/driver/validation/";

    return axiosInstance
      .post(url, obj)
      .then((_) => {
        return true;
      })
      .catch((err) => {
        showErrorToast(err, {
          autoClose: 8000,
        });
        return false;
      });
  };

  // Function to handle individual document uploads
  const handleDocumentUpload = (file, title, id) => {
    const fileType = getFileType(file);

    switch (title) {
      case "Driving License":
        setDocument1({
          url: URL.createObjectURL(file),
          title: "Driving License",
          uploadID: id ?? "",
          document_front_extension: fileType,
        });
        break;
      case "NBI":
        setDocument2({
          url: URL.createObjectURL(file),
          title: "NBI",
          uploadID: id ?? "",
          document_front_extension: fileType,
        });
        break;
      case "COVID-19":
        setDocument3({
          url: URL.createObjectURL(file),
          title: "COVID-19",
          uploadID: id ?? "",
          document_front_extension: fileType,
        });
        break;
      case "LTFRB (Front)":
        setDocument4({
          url: URL.createObjectURL(file),
          title: "LTFRB (Front)",
          uploadID: id ?? "",
          document_front_extension: fileType,
        });
        break;
      case "LTFRB (Back Optional)":
        setDocument5({
          url: URL.createObjectURL(file),
          title: "LTFRB (Back Optional)",
          uploadID: id ?? "",
          document_front_extension: fileType,
        });
        break;
      case "Fit to work":
        setDocument6({
          url: URL.createObjectURL(file),
          title: "Fit to work",
          uploadID: id ?? "",
          document_front_extension: fileType,
        });
        break;
      default:
        break;
    }
  };

  async function uploadFileToApi(file, title) {
    const apiUrl = "/utils/file/";
    const formData = new FormData();

    formData.append("file", file);
    formData.append("folder", "public/");

    const options = {
      headers: {
        "Content-Type": "multipart/form-data", // This is important for form data
      },
    };
    try {
      return await axiosInstance.post(apiUrl, formData, options).then((res) => {
        return res.data;
      });
    } catch (error) {
      Promise.reject(error);
    }
  }

  const handleDocuments = async (file, title) => {
    if (file) {
      const isValidFile = isFileExtensionValid(file.name);

      if (!isValidFile) {
        toast.error("Allowed file types: PDF, JPEG, JPG, PNG, HEIC, SVG.", {
          autoClose: 6000,
        });

        return;
      }
    } else {
      return;
    }

    handleDocumentUpload(file, title);

    await uploadFileToApi(file)
      .then((data) => {
        handleDocumentUpload(file, title, data?.data?.id ?? "");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleProfilePictureUpload = async (file) => {
    if (!isFileValid(file)) {
      const allowedExtensions = ["pdf", "jpeg", "jpg", "png", "heic", "svg"];
      toast.error(
        "Invalid file type. Allowed extensions are: " +
          allowedExtensions.join(", ")
      );
      setProfilePicture(initialProfilePictureState);

      return;
    }
    setProfilePicture((state) => ({
      ...state,
      url: URL.createObjectURL(file),
    }));
    await uploadFileToApi(file)
      .then((data) => {
        setProfilePicture((state) => ({
          ...state,
          imgId: data?.data?.id ?? "",
        }));
      })
      .catch((error) => {
        console.log(error, "Error while uploading profile picture");
      });
  };

  const handleDriverImageUpload = async (file) => {
    if (!isFileValid(file)) {
      const allowedExtensions = ["pdf", "jpeg", "jpg", "png", "heic", "svg"];
      toast.error(
        "Invalid file type. Allowed extensions are: " +
          allowedExtensions.join(", ")
      );
      setDriverImage("");
      setDriverId("");

      return;
    }
    setDriverImage(URL.createObjectURL(file));
    await uploadFileToApi(file)
      .then((data) => {
        setDriverId(data?.data?.id ?? "");
      })
      .catch((error) => {
        console.log(error, "Error while uploading profile picture");
      });
  };

  // Fetching operator function
  const getOperator = async () => {
    let url = "/driver/operator/";
    try {
      await axiosInstance.get(url).then((res) => {
        setOpertaor(res?.data?.data ? [...res.data.data] : []);
      });
    } catch (error) {
      console.log("Error while fetching operator", error);
    }
  };

  console.log(basicDetails, "basicDetails");

  const validateEmail = (email) => {
    const emailPattern = /^[\w\.-]+@[a-zA-Z\d\.-]+\.[a-zA-Z]{2,}$/;
    const emailIsValid = emailPattern.test(email);
    return emailIsValid;
  };

  const validatePhoneNumber = (phoneNumber) => {
    const phoneNumberPattern = /^\d{10}$/;
    const numberIsValid = phoneNumberPattern.test(phoneNumber);
    return numberIsValid;
  };

  const validatebasicDetail = async (e) => {
    let check = false;
    const checkvalue = e.target.checked;

    if (basicDetailsCheck) {
      setBasicDetailsCheck(false);
      return;
    }

    const ignorefield = ["is_verified", "operator_full_name", "tnvsCheck"];

    Object.entries(basicDetails).forEach(([key, value]) => {
      if (
        driverType === "INDIVIDUAL" &&
        key === "operator_full_name" &&
        value === ""
      ) {
        check = true;
      } else if (value === "" && !ignorefield.includes(key)) {
        check = true;
      }
    });

    if (!driverType) {
      toast.error("select driver type!", {
        position: toast.POSITION.TOP_RIGHT,
      });

      return;
    }

    if (driverType === "INDIVIDUAL" && !basicDetails?.tnvsCheck) {
      showErrorToast("Fill All The Basic Details!");
      return;
    }

    if (check) {
      toast.error("Fill All The Basic Details!", {
        position: toast.POSITION.TOP_RIGHT,
      });

      return;
    }

    if (calculateAge(basicDetails?.dob ?? "") < 18) {
      showErrorToast("Age should be greater than 18");
      return;
    }

    if (driverType === "VIA_OPERATOR" && !operatorName) {
      showErrorToast("Please Select Operator Type");
      return;
    }

    const checkEmail = validateEmail(basicDetails.email);

    const checkNumber = validatePhoneNumber(basicDetails.phoneNumber);

    if (!checkEmail) {
      toast.error("Invalid email address!", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }

    if (!checkNumber) {
      toast.error("Invalid number format!", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }

    const checkUniqness = await DriverDetailsValidationByApi({
      phone: `+63${basicDetails?.phoneNumber ?? ""}`,
      email: basicDetails?.email ?? "",
    });

    if (!checkUniqness) {
      return;
    }
    if (!profilePicture.url) {
      toast.error("Please upload a profile picture!", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }

    setBasicDetailsCheck(checkvalue);
  };

  const validateCarDetails = async (e) => {
    let check = false;
    const checkValue = e.target.checked;
    if (carDetailsCheck) {
      setCarDetailsCheck(false);
      return;
    }

    if (
      carDocument1.url === "" ||
      carDocument2.url === "" ||
      carDocument3.url === ""
    ) {
      toast.error("Upload ALL Car Documents!", {
        position: toast.POSITION.TOP_RIGHT,
      });

      return;
    }

    if (check) {
      toast.error("Upload ALL The Car Images!", {
        position: toast.POSITION.TOP_RIGHT,
      });

      return;
    }

    console.log(validateLtfrb, "before", check);

    Object.entries(carDetails).forEach((res) => {
      if (res[0] === "caseId" && validateLtfrb) {
        return;
      } else {
        if (res[1] === "") {
          check = true;
        }
      }
    });

    console.log(validateLtfrb, "after", check);

    const checkCarType = carTypeCategory.some(
      (category) => category.value === carDetails?.carType
    );

    if (!checkCarType) {
      toast.error("Fill All The Car Details Field!", {
        position: toast.POSITION.TOP_RIGHT,
      });

      return;
    }

    if (check) {
      toast.error("Fill All The Car Details Field!", {
        position: toast.POSITION.TOP_RIGHT,
      });

      return;
    }

    if (!driverImage) {
      toast.error("Upload The Driver Image!", {
        position: toast.POSITION.TOP_RIGHT,
      });

      return;
    }

    setCarDetailsCheck(checkValue);
  };

  const validateDocument = (e) => {
    if (documentsCheck) {
      setDocumentsCheck(false);
      return;
    }

    let check = false;

    const requiredDocuments = [
      document1,
      document2,
      document3,
      document4,
      document6,
    ];

    requiredDocuments.forEach((res, index) => {
      if (
        (res.title === "Fit to work" && age > 60 && !res.url) ||
        (res.title === "LTFRB" &&
          !res.url &&
          (res.url === "" || res.url === null))
      ) {
        check = true;
      }
    });

    if (check) {
      toast.error("Upload all the documnets!", {
        position: toast.POSITION.TOP_RIGHT,
      });

      return;
    }

    setDocumentsCheck(e.target.checked);
  };

  const handleBankDocumentUpload = (file) => {
    if (file) {
      const isValidFile = isFileExtensionValid(file.name);

      if (!isValidFile) {
        toast.error("Allowed file types: PDF, JPEG, JPG, PNG, HEIC, SVG.", {
          autoClose: 6000,
        });

        return;
      }
    } else {
      return;
    }

    const fileType = getFileType(file);

    uploadFileToApi(file).then((res) => {
      setBankDocumentFile((prev) => ({
        ...prev,
        document_front_extension: fileType,
        url: URL.createObjectURL(file),
        bankUpoadID: res.data.id,
      }));
    });
  };

  const validateBankDetails = (e) => {
    if (bankDetailsCheck) {
      setBankDetailsCheck(false);
      return;
    }

    if (!bankDocumentFile?.bankUpoadID) {
      toast.error("Upload Bank image!", {
        position: toast.POSITION.TOP_RIGHT,
      });

      return;
    }

    let check = false;

    Object.entries(bankDetails).forEach((res) => {
      if (res[1] === "") {
        check = true;
      }
    });

    if (check) {
      toast.error("Fill All The Bank Details!", {
        position: toast.POSITION.TOP_RIGHT,
      });

      return;
    }

    setBankDetailsCheck(e.target.checked);
  };

  const validateTranningDetails = (e) => {
    if (tranningDetails.is_verified) {
      setTrainingDetails((prev) => ({ ...prev, is_verified: false }));
      return;
    }

    setTrainingDetails((prev) => ({ ...prev, is_verified: true }));
  };

  const isButtonDisable = !(
    basicDetailsCheck &&
    documentsCheck &&
    bankDetailsCheck &&
    carDetailsCheck &&
    tranningDetails.is_verified
  );

  // Create a function to generate the body for the basic details
  const generateBasicDetailsBody = () => {
    return {
      title_slug: "basic_details",
      is_verified: basicDetailsCheck,
      status: basicDetailsCheck ? "APPROVED" : "PENDING",
      onboarding_step_data: {
        profile_pic_id: profilePicture?.imgId ?? "",
        phone: "+63" + basicDetails.phoneNumber,
        full_name: basicDetails.name,
        gender: basicDetails.gender,
        driver_type: driverType,
        ...(driverType === "VIA_OPERATOR" && { operator_id: operatorName }),
        ...(driverType === "INDIVIDUAL" &&
          basicDetails?.operator_full_name && {
            operator_full_name: basicDetails?.operator_full_name,
          }),
        ...(driverType === "INDIVIDUAL" &&
          basicDetails?.tnvsCheck && {
            is_existing_tnvs: basicDetails?.tnvsCheck === "true",
          }),
        email: basicDetails.email,
        dob: basicDetails.dob,
        tax_identification_number: basicDetails?.TIN,
        address: {
          house_number: basicDetails.address1,
          barangay: basicDetails.address2,
          city: basicDetails.address3,
          postal_code: basicDetails.cityCode,
        },
      },
    };
  };

  // Create a function to generate the body for the documents
  const generateDocumentsBody = () => {
    let documentBody = [
      document1,
      document2,
      document3,
      document4,
      document6,
    ].map((res, index) => {
      if (index === 4 && age >= 60 && age !== "") {
        return {
          document_title_slug: titles[index],
          document_front: res.uploadID,
          document_back: null,
          is_verified: documentsCheck,
        };
      }

      if (index !== 4) {
        return {
          document_title_slug: titles[index],
          document_front: res.uploadID,
          document_back:
            titles[index] === "LTFRB (Front)"
              ? document5?.uploadID ?? null
              : null,
          is_verified: documentsCheck,
        };
      }
    });

    let carSlugTitle = [
      "car_details_or",
      "car_details_cr",
      "car_details_insurance",
    ];
    let cardetails = [carDocument1, carDocument2, carDocument3].map(
      (res, index) => ({
        document_title_slug: carSlugTitle[index],
        document_front: res.uploadId,
        document_back: null,
        is_verified: carDetailsCheck,
      })
    );

    const filterData = filterEmptyPropertiesRecursively([
      ...documentBody,
      ...cardetails,
    ]);
    const checkingDoc = filterObjectsWithProperties(filterData);
    return checkingDoc;
  };

  const statusCheckBox = {
    basicDetailsCheck,
    documentsCheck,
    bankDetailsCheck,
    carDetailsCheck,
    tranningDetails: tranningDetails.is_verified,
  };

  // Create a function to generate the body for the bank details
  const generateBankDetailsBody = () => {
    return {
      account_number: bankDetails.accountNumber,
      account_holder_name: bankDetails.accountHolderName,
      bank_name: bankDetails.bankName,
      branch: bankDetails.branchBSB,
      documents: [{ document_id: bankDocumentFile.bankUpoadID }],
    };
  };

  // Create a function to generate the body for the car details
  const generateCarDetailsBody = () => {
    return {
      registration_number: carDetails.carRegNumber,
      color: carDetails.carColor,
      model_number: carDetails.carModel,
      year_of_manufacture: carDetails.carYear,
      company: carDetails.carCompany,
      car_type: carDetails.carType,
    };
  };

  const handleImageUpload = (file, setCarImage, document) => {
    uploadFileToApi(file)
      .then((res) => {
        setCarImage((prev) => ({
          ...prev,
          document_front: URL.createObjectURL(file),
          new_id: res?.data?.id ?? "",
          old_id: prev?.image_id ?? "",
        }));
      })
      .catch((error) => {
        console.log(error, "Error while Uploading file new driver");
        let message = error?.response?.data?.message;
        if (message) {
          toast.error(message);
        }
      });
  };

  const handleCarImageUpload = (file, setCarImage) => {
    const fileType = getFileType(file);

    uploadFileToApi(file)
      .then((res) => {
        setCarImage((prev) => ({
          ...prev,
          url: URL.createObjectURL(file),
          uploadId: res?.data?.id ?? "",
          document_front_extension: fileType,
        }));
      })
      .catch((error) => {
        console.log(error, "Error while Uploading file new driver");
      });
  };

  const handleCarImageChange = (file, index) => {
    switch (index) {
      case 1:
        handleImageUpload(file, setCarImage1);
        break;
      case 2:
        handleImageUpload(file, setCarImage2);
        break;
      case 3:
        handleImageUpload(file, setCarImage3);
        break;
      case 4:
        handleImageUpload(file, setCarImage4);
        break;
      case 5:
        handleImageUpload(file, setCarImage5);
        break;
      default:
        break;
    }
  };

  const handleCarDocument = (file, index) => {
    if (file) {
      const isValidFile = isFileExtensionValid(file.name);

      if (!isValidFile) {
        toast.error("Allowed file types: PDF, JPEG, JPG, PNG, HEIC, SVG.", {
          autoClose: 6000,
        });

        return;
      }
    } else {
      return;
    }

    switch (index) {
      case 1:
        handleCarImageUpload(file, setCarDocument1);
        break;
      case 2:
        handleCarImageUpload(file, setCarDocument2);
        break;
      case 3:
        handleCarImageUpload(file, setCarDocument3);
        break;
      default:
        break;
    }
  };

  function filterEmptyPropertiesRecursively(obj) {
    if (Array.isArray(obj)) {
      // If obj is an array, recursively filter each element in the array
      return obj
        .map((item) => filterEmptyPropertiesRecursively(item))
        .filter(Boolean);
    }

    if (typeof obj === "object" && obj !== null) {
      // If obj is an object, recursively filter its properties
      return Object.keys(obj).reduce((acc, key) => {
        const value = obj[key];
        if (value !== null && value !== "" && value !== undefined) {
          const filteredValue = filterEmptyPropertiesRecursively(value);
          if (filteredValue !== null) {
            acc[key] = filteredValue;
          }
        }
        return acc;
      }, {});
    }

    return obj;
  }

  const genrateHandleCarImages = () => {
    let data = [carImage1, carImage2, carImage3, carImage4, carImage5];
    let old_id = data
      .filter((prev) => prev.old_id !== "")
      .map((res) => -res.old_id);
    let new_id = data
      .filter((prev) => prev.new_id !== "")
      .map((res) => res.new_id);

    return [...new_id, ...old_id];
  };

  const handleSubmit = async () => {
    let opertorname;

    if (operatorName) {
      const findOpertorNameById = operator?.find(
        (res) => res.id === operatorName
      );

      opertorname = findOpertorNameById?.operator_name;
    }

    if (basicDetails.dob && age < 18 && age !== "") {
      showErrorToast("age should be grater then 18!");
      return;
    }

    if (
      basicDetails?.phoneNumber &&
      basicDetails?.email &&
      carDetails?.caseId
    ) {
      const checkUniqness = await DriverDetailsValidationByApi({
        phone: basicDetails?.phoneNumber ?? "",
        email: basicDetails?.email ?? "",
        ...(opertorname && { operator_name: opertorname }),
      });

      if (!checkUniqness) {
        return;
      }
    }

    const phoneNumberIsvalid = validatePhoneNumber(basicDetails?.phoneNumber);

    if (!phoneNumberIsvalid) {
      showErrorToast("Phone Number is Required!");
      return;
    }

    const checkPhoneNumberIsUnique = await DriverDetailsValidationByApi({
      phone: basicDetails?.phoneNumber ?? "",
      ...(opertorname && { operator_name: opertorname }),
    });

    if (!checkPhoneNumberIsUnique) {
      return;
    }

    let documentsIsVerify =
      documentsCheck && bankDetailsCheck && carDetailsCheck;

    const dataToUpdate = {
      body: [
        generateBasicDetailsBody(),
        //STEP - 2
        {
          title_slug: "verify_documents",
          is_verified: documentsIsVerify,
          status: documentsIsVerify ? "APPROVED" : "PENDING",
          onboarding_step_data: {
            documents: generateDocumentsBody(),
            driver_image_id: driverId ?? "",
            bank_details: generateBankDetailsBody(),
            car_details: generateCarDetailsBody(),
            car_images: genrateHandleCarImages(),
            case_id: carDetails?.caseId ?? "",
          },
        },
        //STEP - 3
        {
          title_slug: "visit_center",
          is_verified: tranningDetails.is_verified,
          status: tranningDetails.is_verified ? "APPROVED" : "PENDING",
          onboarding_step_data: {
            appointment_details: {
              date: !tranningDetails.auto_appoint
                ? tranningDetails?.date ?? ""
                : "",
              slot_key: !tranningDetails.auto_appoint
                ? tranningDetails?.slot_key ?? ""
                : "",
              training_center_id: !tranningDetails.auto_appoint
                ? tranningDetails?.training_center_detail_id ?? ""
                : "",
              auto_appoint: tranningDetails.auto_appoint,
            },
          },
        },
        //STEP - 4
        {
          title_slug: "final_approval",
          is_verified: approved.is_verified,
          status: approved.is_verified ? "APPROVED" : "PENDING",
          onboarding_step_data: {},
        },
      ],
    };

    let filterData = filterEmptyPropertiesRecursively(dataToUpdate);

    // Make a POST request to the API with the form data
    await axiosInstance
      .post(`/crm/driver/`, filterData)
      .then((res) => {
        toast.success("Driver account created successfully!", {
          position: toast.POSITION.TOP_RIGHT,
        });
        navigate("/driver");
      })
      .catch((error) => {
        let message = error?.response?.data?.message;
        if (message) {
          toast.error(message);
          if (apiCallMade) {
            setApiCallMade(false);
            setApproved((prev) => ({ ...prev, is_verified: false }));
          }
        }
      });
  };

  useEffect(() => {
    const getBankOptions = async () => {
      try {
        const response = await axios.get(`${config}/driver/bank/`);
        setBankNameOptions(response?.data?.data ?? []);
      } catch (error) {
        showErrorToast(error);
      }
    };

    getBankOptions();
    getOperator();
    getTrainingCenter();
  }, []);

  useEffect(() => {
    if (approved.is_verified && !apiCallMade) {
      handleSubmit("approved");
    }
  }, [approved.is_verified, apiCallMade]);

  return (
    <>
      {loading ? (
        "Loading..."
      ) : (
        <Box sx={{ m: "1.5rem 4rem" }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Box sx={{ display: "flex" }}>
              <Box sx={{ m: "15px 10px" }}>
                <ArrowBackIcon
                  onClick={() => navigate("/driver")}
                  sx={{ cursor: "pointer" }}
                />
              </Box>
            </Box>
            <Box>
              <Box sx={{ display: "flex" }}>
                <Box>
                  {permission?.CREATE && (
                    <Button
                      variant="contained"
                      sx={{ backgroundColor: "black", m: "20px 0 0 0" }}
                      onClick={handleSubmit}
                      type="submit"
                    >
                      Create
                    </Button>
                  )}
                </Box>
              </Box>

              <Box sx={{ m: "15px 0" }}></Box>
            </Box>
          </Box>
          <Box sx={{ flexGrow: 1, m: "1rem 0", backgroundColor: "white" }}>
            <DriverBasicDetails
              basicDetails={basicDetails}
              profilePicture={profilePicture}
              handleProfilePictureUpload={handleProfilePictureUpload}
              setBasicDetails={setBasicDetails}
              basicDetailsCheck={basicDetailsCheck}
              onChange={validatebasicDetail}
              operator={operator}
              setDriverType={setDriverType}
              setOpertaorName={setOpertaorName}
              operatorName={operatorName}
              driverType={driverType}
              verificationCheckLabel={"Driver Basic details verified"}
              boxHeaderLabel={"Driver Basic Details"}
              profilePictureLabel={"UPLOAD PHOTO"}
              generateBasicDetailsBody={generateBasicDetailsBody}
              statusCheckBox={statusCheckBox}
              handleCheckboxChange={handleCheckboxChange}
              DriverDetailsValidationByApi={DriverDetailsValidationByApi}
              setValidateLtfrb={setValidateLtfrb}
            />
            <Divider />
            <FlexBetween sx={{ m: "1.5rem 1.5rem" }}>
              <Typography gutterBottom variant="h6" component="div" mt="0.5rem">
                Documents
              </Typography>
            </FlexBetween>
            <Box
              sx={{
                flexGrow: 1,
                m: "1.5rem 1.5rem",
                display: "flex",
                justifyContent: "space-around",
                flexWrap: "wrap",
                gap: "50px",
              }}
            >
              {[
                document1,
                document2,
                document3,
                document4,
                document5,
                document6,
              ].map((file, index) => (
                <>
                  {index === 5 && age >= 60 && (
                    <div key={`document-upload-input-${index + 1}`}>
                      <Typography
                        sx={{
                          fontSize: "24px",
                          fontWeight: "600",
                          lineHeight: "normal",
                          marginBottom: "12px",
                        }}
                      >
                        {`${index + 1}. ${file?.title ?? ""}`}
                      </Typography>
                      <GenericFileUpload
                        file={file}
                        inputId={`document-upload-input-${index + 1}`}
                        handleDocumentUpload={(file, index, title) => {
                          handleDocuments(file, title);
                        }}
                        index={index}
                      />
                    </div>
                  )}

                  {index !== 5 && (
                    <div key={`document-upload-input-${index + 1}`}>
                      <Typography
                        sx={{
                          fontSize: "24px",
                          fontWeight: "600",
                          lineHeight: "normal",
                          marginBottom: "12px",
                        }}
                      >
                        {`${index + 1}. ${file?.title ?? ""}`}
                      </Typography>
                      <GenericFileUpload
                        file={file}
                        inputId={`document-upload-input-${index + 1}`}
                        handleDocumentUpload={(file, index, title) => {
                          handleDocuments(file, title);
                        }}
                        index={index}
                        key={`document-upload-input-${index + 1}`}
                      />
                    </div>
                  )}
                </>
              ))}
            </Box>
            <Box
              sx={{
                display: "flex",
                backgroundColor: documentsCheck
                  ? "rgba(0, 157, 15, 0.20)"
                  : "#EAEAEA",
              }}
            >
              <Checkbox
                checked={documentsCheck}
                color="success"
                onChange={(e) => {
                  const obj = {
                    ...statusCheckBox,
                    documentsCheck: e.target.checked,
                  };

                  if (handleCheckboxChange(obj, "documentsCheck")) return;

                  validateDocument(e);
                }}
                sx={{ color: "#009D0F" }}
                inputProps={{ "aria-label": "controlled" }}
              />
              <Typography gutterBottom variant="h6" component="div" mt="0.5rem">
                Documents verified
              </Typography>
            </Box>
            <Divider />
            <FlexBetween sx={{ m: "1.5rem 1.5rem" }}>
              <Typography gutterBottom variant="h6" component="div" mt="0.5rem">
                Car Details
              </Typography>
            </FlexBetween>
            <Box
              sx={{
                flexGrow: 1,
                m: "1.5rem 1.5rem",
                display: "flex",
                justifyContent: "space-around",
                flexWrap: "wrap",
                gap: "50px",
              }}
            >
              {[carDocument1, carDocument2, carDocument3].map((file, index) => (
                <div>
                  <Typography
                    sx={{
                      fontSize: "24px",
                      fontWeight: "600",
                      lineHeight: "normal",
                      marginBottom: "12px",
                    }}
                  >
                    {`${index + 1}. ${file?.title ?? ""}`}
                  </Typography>

                  <GenericFileUpload
                    file={file}
                    inputId={`car-document-upload-input-${index + 1}`}
                    handleDocumentUpload={handleCarDocument}
                    index={index}
                    key={`car-document-upload-input-${index + 1}`}
                  />
                </div>
              ))}
            </Box>

            <Box sx={{ flexGrow: 1, m: "1.5rem 1.5rem" }}>
              <Typography
                sx={{
                  fontSize: "24px",
                  fontWeight: "600",
                  lineHeight: "normal",
                  marginBottom: "12px",
                }}
              >
                3. Car image
              </Typography>

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-around",
                  flexWrap: "wrap",
                  width: "100%",
                  gap: "25px",
                }}
              >
                {[carImage1, carImage2, carImage3, carImage4, carImage5].map(
                  (file, index) => (
                    <>
                      <label htmlFor={`car-image-input-${index + 1}`}>
                        <div
                          className="form"
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                            border: "2px dashed lightgray",
                            height: "250px",
                            width: "470px",
                            cursor: "pointer",
                            borderRadius: "5px",
                            backgroundColor: "#0000000A",
                          }}
                        >
                          {file?.document_front ?? "" ? (
                            <img
                              alt="Profile Picture"
                              width={250}
                              height={150}
                              src={file.document_front}
                            />
                          ) : (
                            ""
                          )}
                          {file?.document_front ? (
                            <Button
                              variant="text"
                              sx={{
                                color: "#009D0F",
                                fontFamily: "Proxima Nova",
                                fontSize: "24px",
                                textTransform: "none",
                              }}
                              startIcon={<FileUploadIcon />}
                              onClick={() =>
                                document
                                  .getElementById(
                                    `car-image-input-${index + 1}`
                                  )
                                  .click()
                              }
                            >
                              Change
                            </Button>
                          ) : (
                            <Button
                              variant="text"
                              sx={{
                                color: "black",
                                fontFamily: "Proxima Nova",
                                fontSize: "24px",
                                textTransform: "none",
                              }}
                              startIcon={<FileUploadIcon />}
                              onClick={() =>
                                document
                                  .getElementById(
                                    `car-image-input-${index + 1}`
                                  )
                                  .click()
                              }
                            >
                              Upload
                            </Button>
                          )}

                          <Typography
                            gutterBottom
                            variant="p"
                            component="span"
                            fontSize="small"
                            sx={{ mt: "5px" }}
                          >
                            Maximum 5 MB. Upload .jpeg, .png, or .pdf files.
                          </Typography>
                        </div>
                      </label>
                      <FileUploadInput
                        id={`car-image-input-${index + 1}`}
                        onFileChange={(file) =>
                          handleCarImageChange(file, index + 1)
                        }
                      />
                    </>
                  )
                )}
              </Box>
            </Box>
            <Box sx={{ width: "80%", m: "1.5rem 1.5rem" }}>
              <Stack spacing={2} direction="row" sx={{ marginBottom: 4 }}>
                <TextField
                  type="text"
                  variant="outlined"
                  color="secondary"
                  label={"Registration Number"}
                  value={carDetails.carRegNumber}
                  onChange={(e) => {
                    setCarDetails({
                      ...carDetails,
                      carRegNumber: e.target.value,
                    });
                  }}
                  sx={{ mb: 4, width: "50%" }}
                />
                <TextField
                  type="text"
                  variant="outlined"
                  color="secondary"
                  label={"Color of the Car"}
                  value={carDetails.carColor}
                  onChange={(e) => {
                    setCarDetails({ ...carDetails, carColor: e.target.value });
                  }}
                  fullWidth
                />
              </Stack>
              <Stack spacing={2} direction="row" sx={{ marginBottom: 4 }}>
                <TextField
                  type="text"
                  variant="outlined"
                  color="secondary"
                  label={"Model Number"}
                  value={carDetails.carModel}
                  onChange={(e) => {
                    setCarDetails({ ...carDetails, carModel: e.target.value });
                  }}
                  sx={{ mb: 4, width: "50%" }}
                />
                <TextField
                  select
                  label={"Make/Year of Manufacture"}
                  value={carDetails.carYear}
                  onChange={(e) => {
                    setCarDetails({ ...carDetails, carYear: e.target.value });
                  }}
                  defaultValue="2023"
                  fullWidth
                >
                  {Array.from(
                    { length: currentYear - 1950 + 1 },
                    (_, index) => (
                      <MenuItem key={index} value={currentYear - index}>
                        {currentYear - index}
                      </MenuItem>
                    )
                  )}
                </TextField>
              </Stack>
              <Stack spacing={2} direction="row">
                <TextField
                  type="text"
                  variant="outlined"
                  color="secondary"
                  label={"Company"}
                  value={carDetails.carCompany}
                  onChange={(e) => {
                    setCarDetails({
                      ...carDetails,
                      carCompany: e.target.value,
                    });
                  }}
                  sx={{ mb: 4, width: "50%" }}
                />
                <TextField
                  select
                  variant="outlined"
                  color="secondary"
                  label={"Car Type"}
                  value={carDetails.carType}
                  fullWidth
                  onChange={(e) => {
                    setCarDetails({
                      ...carDetails,
                      carType: e.target.value,
                    });
                  }}
                  sx={{ mb: 4 }}
                >
                  {carTypeCategory.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Stack>
              {!validateLtfrb && (
                <Stack spacing={2} direction="row" mt="20px">
                  <TextField
                    type="text"
                    variant="outlined"
                    color="secondary"
                    label={"Ltfrb Id"}
                    value={carDetails.caseId}
                    onBlur={async (e) => {
                      let opertorname;

                      if (operatorName) {
                        const findOpertorNameById = operator?.find(
                          (res) => res.id === operatorName
                        );
                        opertorname = findOpertorNameById?.operator_name;
                      }

                      if (e.target.value?.length > 0) {
                        await DriverDetailsValidationByApi({
                          case_id: e.target.value,
                          ...(opertorname && { operator_name: opertorname }),
                        });
                      }
                    }}
                    sx={{
                      width: "33%",
                    }}
                    onChange={(e) => {
                      setCarDetails({
                        ...carDetails,
                        caseId: e.target.value,
                      });
                    }}
                  />
                </Stack>
              )}
            </Box>
            <Box sx={{ flexGrow: 1, m: "1.5rem 1.5rem" }}>
              <Typography
                sx={{
                  fontSize: "24px",
                  fontWeight: "600",
                  lineHeight: "normal",
                  marginBottom: "12px",
                }}
              >
                4. Driver image
              </Typography>

              <Box
                sx={{ flexGrow: 1, m: "1.5rem 1.5rem", position: "relative" }}
              >
                <div
                  className="form"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    border: "2px dashed lightgray",
                    height: "250px",
                    width: "470px",
                    cursor: "pointer",
                    borderRadius: "5px",
                    backgroundColor: "#0000000A",
                  }}
                >
                  {driverImage ? (
                    <img
                      alt="Profile Pic"
                      width={250}
                      height={150}
                      src={driverImage ?? dp}
                    />
                  ) : (
                    ""
                  )}
                  {driverImage ? (
                    <Button
                      variant="text"
                      sx={{
                        color: "#009D0F",
                        fontFamily: "Proxima Nova",
                        fontSize: "24px",
                        textTransform: "none",
                        position: "absolute",
                        bottom: "0",
                        left: "500px",
                      }}
                      startIcon={<FileUploadIcon />}
                      onClick={() =>
                        document.getElementById(`driver-image-input`).click()
                      }
                    >
                      Change
                    </Button>
                  ) : (
                    <Button
                      variant="text"
                      sx={{
                        color: "black",
                        fontFamily: "Proxima Nova",
                        fontSize: "24px",
                        textTransform: "none",
                      }}
                      startIcon={<FileUploadIcon />}
                      onClick={() =>
                        document.getElementById(`driver-image-input`).click()
                      }
                    >
                      Upload
                    </Button>
                  )}

                  <Typography
                    gutterBottom
                    variant="p"
                    component="span"
                    fontSize="small"
                    sx={{ mt: "5px" }}
                  >
                    Maximum 5 MB. Upload .jpeg, .png, or .pdf files.
                  </Typography>
                </div>
                <FileUploadInput
                  id={`driver-image-input`}
                  onFileChange={(file) => handleDriverImageUpload(file)}
                />
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                backgroundColor: carDetailsCheck
                  ? "rgba(0, 157, 15, 0.20)"
                  : "#EAEAEA",
              }}
            >
              <Checkbox
                checked={carDetailsCheck}
                color="success"
                onChange={(e) => {
                  const obj = {
                    ...statusCheckBox,
                    carDetailsCheck: e.target.checked,
                  };

                  if (handleCheckboxChange(obj, "carDetailsCheck")) return;

                  validateCarDetails(e);
                }}
                sx={{ color: "#009D0F" }}
                inputProps={{ "aria-label": "controlled" }}
              />
              <Typography gutterBottom variant="h6" component="div" mt="0.5rem">
                Car details verified
              </Typography>
            </Box>
            <Divider />
            <FlexBetween sx={{ m: "1.5rem 1.5rem" }}>
              <Typography gutterBottom variant="h6" component="div" mt="0.5rem">
                Bank Details
              </Typography>
            </FlexBetween>
            <Box sx={{ m: "1.5rem 1.5rem" }}>
              <GenericFileUpload
                inputId={"bank-document-upload-input"}
                file={bankDocumentFile}
                handleDocumentUpload={handleBankDocumentUpload}
              />
            </Box>
            <Box sx={{ width: "80%", m: "1.5rem 1.5rem" }}>
              <Stack spacing={2} direction="row" sx={{ marginBottom: 4 }}>
                <CommonAutoComplete
                  options={bankNameOptions}
                  bankDetails={bankDetails}
                  setBankDetails={setBankDetails}
                />
                <TextField
                  type="text"
                  variant="outlined"
                  color="secondary"
                  label="Branch BSB"
                  value={bankDetails.branchBSB}
                  onChange={(e) => {
                    setBankDetails({
                      ...bankDetails,
                      branchBSB: e.target.value,
                    });
                  }}
                  fullWidth
                />
              </Stack>
              <Stack spacing={2} direction="row" sx={{ marginBottom: 4 }}>
                <TextField
                  type="number"
                  variant="outlined"
                  color="secondary"
                  label={"Account Number"}
                  value={bankDetails.accountNumber}
                  onChange={(e) => {
                    setBankDetails({
                      ...bankDetails,
                      accountNumber: e.target.value,
                    });
                  }}
                  fullWidth
                />
                <TextField
                  type="text"
                  variant="outlined"
                  color="secondary"
                  label={"Account Holder Name"}
                  value={bankDetails.accountHolderName}
                  onChange={(e) => {
                    setBankDetails({
                      ...bankDetails,
                      accountHolderName: e.target.value,
                    });
                  }}
                  fullWidth
                />
              </Stack>
            </Box>
            <Stack
              direction="row"
              spacing={3}
              sx={{
                backgroundColor: bankDetailsCheck
                  ? "rgba(0, 157, 15, 0.20)"
                  : "#EAEAEA",
              }}
            >
              <Checkbox
                checked={bankDetailsCheck}
                color="success"
                onChange={(e) => {
                  const obj = {
                    ...statusCheckBox,
                    bankDetailsCheck: e.target.checked,
                  };

                  if (handleCheckboxChange(obj, "bankDetailsCheck")) return;

                  validateBankDetails(e);
                }}
                sx={{
                  color: "#009D0F",
                }}
                inputProps={{ "aria-label": "controlled" }}
              />
              <Typography gutterBottom variant="h6" component="div" mt="0.5rem">
                Bank details verified
              </Typography>
            </Stack>
            <br />
            <Divider />
            <DriverTrainingDetails
              tranningDetails={tranningDetails}
              handleTrainingDetailsChanges={handleTrainingDetailsChanges}
              tranningCenter={tranningCenter}
              handleTraningCenter={handleTraningCenter}
              setTrainingDetails={setTrainingDetails}
              validateTranningDetails={validateTranningDetails}
              handleCheckboxChange={handleCheckboxChange}
              statusCheckBox={statusCheckBox}
              slotOptions={slotOptions}
              handleSlotOptions={handleSlotOptions}
            />
            <br />
            <Box
              sx={{
                display: "flex",
                width: "100%",
                boxSizing: "border-box",
                padding: approved.is_verified
                  ? "18px 0"
                  : "20px 24px 20px 200px",
                marginTop: "25px",
                justifyContent: approved.is_verified ? "center" : "flex-end",
                alignItems: "center",
                gap: "38px",
                background: approved.is_verified ? "#009D0F" : "#EAEAEA",
              }}
            >
              {approved.is_verified ? (
                <Stack spacing={1} direction="row" alignItems={"center"}>
                  {/* <GridCheckCircleIcon color="#009D0F"   style={{width:"42" , height:"42px" }}/> */}
                  <CheckCircleOutlineOutlined
                    color="#fff"
                    style={{ fill: "#fff", width: "42", height: "42px" }}
                  />
                  <Typography
                    sx={{
                      fontSize: "24px",
                      fontStyle: "normal",
                      fontWeight: "600",
                      lineHeight: "normal",
                      borderRadius: "4px",
                      color: "#fff",
                    }}
                  >
                    Approved
                  </Typography>
                </Stack>
              ) : (
                <Stack spacing={3} direction="row" alignItems={"center"}>
                  <Typography
                    sx={{
                      fontSize: "20px",
                      fontStyle: "normal",
                      fontWeight: "600",
                      lineHeight: "normal",
                      borderRadius: "4px",
                      color: "#000",
                    }}
                  >
                    Final Approval
                  </Typography>
                  <Button
                    sx={{
                      width: "230px",
                      height: "50px",
                      padding: "13px 142px",
                      borderRadius: "4px",
                      fontSize: "20px",
                      textTransform: "capitalize",
                      background: "#000",
                      color: "#fff",
                      boxSizing: "border-box",
                      cursor: "pointer",
                      ":disabled": {
                        background: "#ACACAC",
                        color: "rgba(0, 0, 0, 0.30)",
                        cursor: "not-allowed",
                      },
                      "&:hover": { backgroundColor: "#000" },
                    }}
                    disabled={isButtonDisable || !permission?.CREATE}
                    onClick={handleApproved}
                  >
                    Approve
                  </Button>
                </Stack>
              )}
            </Box>
            <Divider />
            <br />
            <br />
            <Divider />
          </Box>
        </Box>
      )}
    </>
  );
};

export default NewDriver;
