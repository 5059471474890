import {
  Box,
  Button,
  CircularProgress,
  Stack,
  Typography,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import React from "react";
import { useSearchParams } from "react-router-dom";

export const calculateMinWidth = (columnField, rows = [], formatters = {}) => {
  const headerWidth = calculateTextWidth(columnField.headerName) + 24; // Add some padding
  let maxContentWidth = 0;

  rows.forEach((row) => {
    let cellContent = row[columnField.field];

    // Apply formatter if exists
    if (formatters[columnField.field]) {
      cellContent = formatters[columnField.field](cellContent, row);
    }

    if (cellContent) {
      const contentWidth = calculateTextWidth(cellContent.toString());
      maxContentWidth = Math.max(maxContentWidth, contentWidth);
    }
  });
  return Math.max(headerWidth, maxContentWidth) + 32; // Add some padding
};

const calculateTextWidth = (text, font = "13px Proxima Nova") => {
  const canvas = document.createElement("canvas");
  const context = canvas.getContext("2d");
  context.font = font;
  return context.measureText(text).width;
};

export const CustomLoadingOverlay = ({ message }) => {
  return (
    <Stack
      direction="row"
      height="100%"
      alignItems="center"
      justifyContent="center"
      spacing={1}
    >
      <CircularProgress
        sx={{
          color: "#000",
        }}
      />
    </Stack>
  );
};

const DataTable = ({
  ownPaginationHandler = false,
  paginationModel = {},
  handleRowClick = () => {},
  columnVisibilityModel = {},
  setColumnVisibilityModel = () => {},
  setPaginationModel,
  driverIdBool = false,
  alternativeId = "",
  totalCount = 0,
  columns = [],
  row = [],
  loading = false,
  paginationInnerObject = false,
  ...rest
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const handlePaginationModel = (
    value,
    setPaginationModel,
    paginationInnerObject = false
  ) => {
    // Update the search parameters
    searchParams.set("page", value?.page || 0);
    searchParams.set("limit", value?.pageSize || 10);
    // Set the new search parameters with the replace option
    setSearchParams(searchParams, { replace: true });
    if (paginationInnerObject) {
      setPaginationModel((prev) => ({ ...prev, paginationModel: value }));
    } else {
      setPaginationModel((prev) => ({ ...prev, ...value }));
    }
  };
  return (
    <Box
      height="65vh"
      sx={{
        overflowX: "auto",
        overflowY: "hidden",
        maxWidth: "80vw",
        "& .MuiDataGrid-root": {
          border: "1px solid black",
        },
        "& .MuiDataGrid-cell": {
          borderBottom: "1px solid black",
        },
        "& .MuiDataGrid-columnHeaders": {
          backgroundColor: "black",
          color: "white",
          borderBottom: "none",
        },

        "& .MuiDataGrid-virtualScroller": {
          backgroundColor: "white",
          "&::-webkit-scrollbar": {
            width: "10px",
            height: "10px",
          },
          "&::-webkit-scrollbar-track": {
            background: "#f1f1f1",
          },
          "&::-webkit-scrollbar-thumb": {
            background: "#000",
            borderRadius: "5px",
          },
          "&::-webkit-scrollbar-thumb:hover": {
            background: "#333",
          },
          scrollbarWidth: "thin",
          scrollbarColor: "#000 #f1f1f1",
        },

        "& .MuiDataGrid-footerContainer": {
          backgroundColor: "white",
          color: "purple",
          borderTop: "1px solid gray",
        },
        "& .MuiDataGrid-toolbarContainer  .MuiButton-text": {
          color: `black !important`,
        },
        "& .MuiDataGrid-sortIcon": {
          color: "black",
          backgroundColor: "white",
        },
      }}
    >
      <DataGrid
        getRowId={(row) => {
          return alternativeId ? row?.[alternativeId] : row.id;
        }}
        components={{
          LoadingOverlay: CustomLoadingOverlay,
        }}
        rows={row || []}
        columns={columns.map((col) => ({
          ...col,
          minWidth: col.minWidth ?? calculateMinWidth(col, row, col.formatter),
          // Set a minimum width for each column
        }))}
        sx={{ cursor: "pointer" }}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: paginationModel?.pageSize ?? 0,
              page: paginationModel?.page ?? 0,
            },
          },
        }}
        paginationMode="server"
        loading={loading}
        page={paginationModel.page}
        pageSize={paginationModel.pageSize}
        onPaginationModelChange={
          ownPaginationHandler === false
            ? (value) => {
                handlePaginationModel(
                  value,
                  setPaginationModel,
                  paginationInnerObject
                );
              }
            : (value) => setPaginationModel(value)
        }
        pageSizeOptions={[10, 20, 30]}
        rowCount={Math.ceil(totalCount)}
        columnVisibilityModel={columnVisibilityModel}
        onColumnVisibilityModelChange={(newModel) =>
          setColumnVisibilityModel(newModel)
        }
        onRowClick={handleRowClick}
        {...rest}
      />
    </Box>
  );
};

export default DataTable;
