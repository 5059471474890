import React from "react";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import FileUploadInput from "./FileUploadInput";
import PdfViewer from "./PdfViewer";
const GenericFileUpload = ({ index, inputId, file, handleDocumentUpload }) => {
  return ["pdf"].includes(file?.document_front_extension) ? (
    <>
      <label htmlFor={inputId}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            border: "2px dashed lightgray",
            height: "250px",
            width: "470px",
            cursor: "pointer",
            borderRadius: "5px",
            backgroundColor: "#0000000A",
          }}
          className="form"
        >
          {file?.url ? <PdfViewer file={file} /> : ""}
          {file?.url ? (
            <Button
              variant="text"
              sx={{
                color: "#009D0F",
                fontFamily: "Proxima Nova",
                fontSize: "24px",
                textTransform: "none",
              }}
              startIcon={<FileUploadIcon />}
              onClick={() => document.getElementById(inputId).click()}
            >
              Change
            </Button>
          ) : (
            <Button
              variant="text"
              sx={{
                color: "black",
                fontFamily: "Proxima Nova",
                fontSize: "24px",
                textTransform: "none",
              }}
              startIcon={<FileUploadIcon />}
              onClick={() => document.getElementById(inputId).click()}
            >
              Upload
            </Button>
          )}

          <Typography
            gutterBottom
            variant="p"
            component="span"
            fontSize="small"
            sx={{ mt: "5px" }}
          >
            Maximum 5 MB. Upload .jpeg, .png, or .pdf files.
          </Typography>
        </div>
      </label>
      <FileUploadInput
        id={inputId}
        onFileChange={(fileupload) =>
          handleDocumentUpload(fileupload, index + 1, file?.title ?? "")
        }
      />
    </>
  ) : (
    <>
      <label htmlFor={inputId}>
        <div
          className="form"
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            border: "2px dashed lightgray",
            height: "250px",
            width: "470px",
            cursor: "pointer",
            borderRadius: "5px",
            backgroundColor: "#0000000A",
          }}
        >
          {file.url ? (
            <img alt="Profile Pic" width={250} height={150} src={file.url} />
          ) : (
            ""
          )}
          {file?.url ? (
            <Button
              variant="text"
              sx={{
                color: "#009D0F",
                fontFamily: "Proxima Nova",
                fontSize: "24px",
                textTransform: "none",
              }}
              startIcon={<FileUploadIcon />}
              onClick={() => document.getElementById(inputId).click()}
            >
              Change
            </Button>
          ) : (
            <Button
              variant="text"
              sx={{
                color: "black",
                fontFamily: "Proxima Nova",
                fontSize: "24px",
                textTransform: "none",
              }}
              startIcon={<FileUploadIcon />}
              onClick={() => document.getElementById(inputId).click()}
            >
              Upload
            </Button>
          )}

          <Typography
            gutterBottom
            variant="p"
            component="span"
            fontSize="small"
            sx={{ mt: "5px" }}
          >
            Maximum 5 MB. Upload .jpeg, .png, or .pdf files.
          </Typography>
        </div>
      </label>
      <FileUploadInput
        id={inputId}
        onFileChange={(uploadFile) =>
          handleDocumentUpload(uploadFile, index + 1, file?.title)
        }
      />
    </>
  );
};

export default GenericFileUpload;
