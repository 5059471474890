import { Box, Button, Divider, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";

import { useNavigate, useSearchParams } from "react-router-dom";
import axiosInstance from "../utils/axiosConfig";

import RideDetails from "./RideDetails";
import { useSettings } from "../hooks/useSettings";
import { removeQuery } from "../utils/queryHelpers";

const DetailsSidePanel = ({
  DId = "",
  anchor,
  toggleDrawer,
  specialFilter,
  role = "",
  fetchFunction = () => {},
  setDriverId = () => {},
}) => {
  const [sidePanelData, setSidePanelData] = useState({});
  const [riderId, setRiderId] = useState("");

  const [searchParams, setSearchParams] = useSearchParams();

  const [priceBreakupObj, setPriceBreakupObj] = useState({});

  const [payment, setPayment] = useState({
    payment_mode: "",
    payment_status: "",
  });

  const navigate = useNavigate();

  const handleNavigate = () => {
    let driverId = sidePanelData?.driver_details?.id ?? "";
    if (driverId) {
      navigate(`/driver/${driverId}`);
    }
  };

  const { dateformat, timezone, formatDate, timeFormat } = useSettings();

  // Fetching driver Details
  const getDriverDetails = async () => {
    let url = `/crm/ride/${DId}/`;

    if (specialFilter) {
      url += `?special_filter=${specialFilter}`;
    }
    // passing token in headers

    await axiosInstance
      .get(url)
      .then((res) => {
        setSidePanelData(res?.data?.data ?? {});
        let resData = res?.data?.data ?? {};
        setPriceBreakupObj({
          bookfare: resData?.price_breakup?.booking_fee ?? "",
          baseFare: resData?.price_breakup?.base_fare ?? "",
          distance_fee_per_km:
            resData?.price_breakup?.distance_fee_per_km ?? "",
          ride_time_fee_per_min:
            resData?.price_breakup?.ride_time_fee_per_min ?? "",
          surge_fee: resData?.price_breakup?.surge_fee ?? "",
          coupon_discount_amount:
            resData?.price_breakup?.coupon_discount_amount ?? "",
        });

        setPayment({
          payment_status: resData?.payment_details?.payment_status ?? "",
          payment_mode: resData?.payment_details?.payment_method ?? "",
        });
      })
      .catch((error) => {
        console.error("error while getting driver details by id", error);
      });
  };

  console.log(payment, "payment");

  useEffect(() => {
    if (DId) {
      getDriverDetails();
    }
  }, [DId]);

  return (
    <Box
      sx={{
        width: "446px",
        background: "#FFF",
        padding: "10px",
        boxSizing: "border-box",
        boxShadow: "-4px 4px 18px 0px rgba(0, 0, 0, 0.07)",
      }}
    >
      <Box
        sx={{
          width: "414px",
          margin: "0 auto 15px auto",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography
            sx={{
              color: "#000",
              fontFamily: "Proxima Nova",
              fontStyle: "normal",
              fontWeight: "500",
              lineHeight: "normal",
              letterSpacing: "0.32px",
              fontSize: "16px",
            }}
          >{`Booking ID :${sidePanelData?.booking_id ?? ""}`}</Typography>
          <Button
            sx={{
              width: "28px",
              height: "28px",
              color: "#000",
              flexShrink: "0",
            }}
            onClick={(e) => {
              e.stopPropagation();
              let handleEvevnt = toggleDrawer("right", false);
            removeQuery("customerdrawer",setSearchParams);
              removeQuery("BookingDrawer", setSearchParams)
              removeQuery("id", setSearchParams)

              setSidePanelData({});
              handleEvevnt(e);
            }}
          >
            <CloseIcon />
          </Button>
        </Box>
        <Typography
          sx={{
            fontFamily: "Proxima Nova",
            fontStyle: "normal",
            fontWeight: "500",
            fontSize: "16px",
            color: "#6F747E",
          }}
        >
          {formatDate(sidePanelData?.created_at ?? "", timezone, dateformat)},{" "}
          {formatDate(sidePanelData?.created_at, timezone, timeFormat)}
        </Typography>
      </Box>
      <Divider
        sx={{
          width: "414px",
          margin: "20px auto",
        }}
      />

      <RideDetails
        sidePanelData={sidePanelData}
        payment={payment}
        specialFilter={specialFilter}
        handleNavigate={handleNavigate}
        role={role}
        setRiderId={setRiderId}
        priceBreakupObj={priceBreakupObj}
        toggleDrawer={toggleDrawer}
        fetchFunction={fetchFunction}
        riderId={riderId}
        anchor={anchor}
        DId={DId}
      />
    </Box>
  );
};

export default DetailsSidePanel;
