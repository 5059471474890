import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  ticketStatus: "",
  ticketRaised: "",
  ticketRaiseDate: {
    startDate: null,
    endDate: null,
  },
  ticketResolved: "",
  ticketResolveDate: {
    startDate: null,
    endDate: null
  },
};

const ticketsSlice = createSlice({
  name: "tickets",
  initialState,
  reducers: {
    setTicketStatus: (state, action) => {
      state.ticketStatus = action.payload;
    },
    setTicketRaised: (state, action) => {
      state.ticketRaised = action.payload;
    },
    setTicketRaiseDate: (state, action) => {
      state.ticketRaiseDate = action.payload;
    },
    setTicketResolved: (state, action) => {
      state.ticketResolved = action.payload;
    },
    setTicketResolveDate: (state, action) => {
      state.ticketResolveDate = action.payload;
    },
    clearAllFilters: (state) => {
      return initialState;
    },
  },
});

export const {
  setTicketStatus,
  setTicketRaised,
  setTicketRaiseDate,
  setTicketResolved,
  setTicketResolveDate,
  clearAllFilters,
} = ticketsSlice.actions;

export default ticketsSlice.reducer;
