import React from "react"; // Import your FlexBetween component
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import FlexBetween from "../../FlexBetween";

const FilterHeader = ({ onToggleDrawer, text = "Filter By" }) => {
  return (
    <FlexBetween
      sx={{
        m: "0.5rem 0.5rem",
      }}
    >
      <Typography variant="h6" fontWeight="bold" fontFamily="sans-serif">
        {text}
      </Typography>

      <IconButton
        onClick={(e) => {
          if (onToggleDrawer) {
            onToggleDrawer("right", false, e);
          }
        }}
      >
        <CloseIcon style={{ fill: "#000" }} />
      </IconButton>
    </FlexBetween>
  );
};

export default FilterHeader;
