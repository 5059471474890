// joinedon

export const joinedonSortOptions = [
  { label: "Ascending", value: "asc" },
  { label: "Descending", value: "dsc" },
];

export const joinedonSortObj = {
  summaryText: "Joined On",
  name: "joinedon",
  options: joinedonSortOptions,
};

export const commonSortOptions = [
  { label: "Ascending", value: "asc" },
  { label: "Descending", value: "dsc" },
];

export const bookingIdObj = {
  summaryText: "Booking id",
  name: "bookingId",
  options: commonSortOptions,
};
