import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import authReducer from "./state";
import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { Provider } from "react-redux";
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import storage from "redux-persist/lib/storage";
import { PersistGate } from "redux-persist/integration/react";
import { AxiosInterceptor } from "./utils/AxiosInterceptor";
import ratingAndReviewSidePanelFilterSlice from "./state/ratingAndReviewSidePanelFilterSlice";
import ratingAndReviewSidePanelSlice from "./state/ratingAndReviewSidePanelSlice";
import filtersSlice from "./state/filtersSlice";
import ticketsSlice from "./state/ticketsSlice";
import configSlice from "./state/configSlice";
import rolePermissionsSlice from "./state/rolePermissionsSlice";
import { BrowserRouter } from "react-router-dom";
import thunk from "redux-thunk";
import sosSlice from "./state/sosSlice";
import DateSlice from "./state/DateSlice";

// Combine multiple reducers
const rootReducer = combineReducers({
  auth: authReducer,
  config: configSlice,
  ratingAndReviewSidePanelFilter: ratingAndReviewSidePanelFilterSlice,
  ratingAndReviewSidePanel: ratingAndReviewSidePanelSlice,
  filterSidePanel: filtersSlice,
  ticketsFilter: ticketsSlice,
  rolePermission: rolePermissionsSlice,
  sos: sosSlice,
  date : DateSlice
});

const persistConfig = {
  key: "root",
  storage,
  version: 1,
  blacklist: [
    "ratingAndReviewSidePanelFilter",
    "ratingAndReviewSidePanel",
    "filterSidePanel",
    "ticketsFilter",
    "sos",
  ],
};
const persistedReducer = persistReducer(persistConfig, rootReducer);
const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoreActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(thunk),
});

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistStore(store)}>
      <BrowserRouter>
        <AxiosInterceptor>
            <App />
        </AxiosInterceptor>
        </BrowserRouter>
      </PersistGate>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
