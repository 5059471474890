import React, { useEffect, useState } from "react";
import axiosInstance from "../utils/axiosConfig";
import { showErrorToast } from "../utils/toastUtils";
import { Avatar, Box, Stack, Typography } from "@mui/material";
import AnalyticsCard from "./AnalyticsCard";
import steeringWheelIcon from "../assets/driverAnalytics/steeringwheel.svg";
import calenderIcon from "../assets/driverAnalytics/calender.svg";
import historyIcon from "../assets/driverAnalytics/History.svg";
import carIcon from "../assets/summary/carIcon.svg";
import checkIcon from "../assets/driverAnalytics/check.svg";
import dayjs from "dayjs";
import SharedCard from "./SharedCard";
import downloadIcon from "../assets/customerAnalytics/arrowAction.svg";
import NorthIcon from "@mui/icons-material/North";
import formatNumberShort from "../utils/formatNumberShort";
import CustomSelect from "./reusableComponents/CustomSelect";
import CircularLoader from "./CircularLoader";
import { SelectOptions, initialDateFilter } from "../const/analytics";
import CommonDateFilterPanel from "./CommonDateFilterPanel";
import { formatDateWithDayJs } from "../utils/formateDate";

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1)?.toLowerCase();
}

const RidesCompletedInfoCard = ({ options, titleCss, selectedOption,dateFilter }) => {
  // CSS
  const valueStyle = {
    fontFamily: "Proxima Nova",
    fontSize: { xs: 24, lg: 34 },
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "130%",
    color: "#000",
  };

  const containerStyle = {
    height: "163px",
    padding: "24px",
    width: "100%",
    display: "flex",
    flexDirection: {
      xs: "column", // Column layout for extra-small screens
      lg: "row", // Row layout for large screens
    },
    alignItems: "center",
    justifyContent: "center",
    boxSizing: "border-box",
    position: "relative",
    backgroundColor: "#fff",
    borderRadius: "12px",
    boxShadow: "0px 0px 8px 0px rgba(150, 150, 150, 0.10)",
  };

  const dividerStyle = {
    width: "1px",
    height: "60%",
    backgroundColor: "rgba(0, 0, 0, 0.15)",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    display: { xs: "none", lg: "block" }, // Show for large screens only
  };

  return (
    <Stack sx={containerStyle}>
      <Stack
        justifyContent={"flex-start"}
        width={{ xs: "100%", lg: "50%" }} // Full width for xs, 50% for lg
        alignItems={"center"}
        gap={{ xs: "5px", lg: "9px" }}
      >
        <Typography sx={valueStyle}>
          {formatNumberShort(options?.rides_completed?.value ?? 0)}
        </Typography>
        <Typography   dangerouslySetInnerHTML={{ __html: `${capitalizeFirstLetter(selectedOption)} ${
            options?.rides_completed?.label
            + `${selectedOption !=="CUSTOM" ?"" : `<br/>${dayjs(dateFilter?.from_time).format('DD/MM/YYYY')==="Invalid Date"  || dayjs(dateFilter?.to_time).format('DD/MM/YYYY')==="Invalid Date" ?"": `${dayjs(dateFilter?.from_time).format('DD/MM/YYYY')} - ${dayjs(dateFilter?.to_time).format('DD/MM/YYYY')}`}`}`
          }` }} sx={{ ...titleCss, fontSize: "20px" }}>
        
        </Typography>
      </Stack>
      <Box sx={dividerStyle}></Box>
      <Stack
        width={{ xs: "100%", lg: "50%" }} // Full width for xs, 50% for lg
        alignItems={"center"}
        gap={{ xs: "5px", lg: "9px" }}
      >
        <Typography sx={valueStyle}>
          {options?.distance_travelled?.value + " " + "km" ?? "0 km"}
        </Typography>
        <Typography 
        dangerouslySetInnerHTML={{ __html: `${capitalizeFirstLetter(selectedOption)} ${
          options?.distance_travelled?.label
          + `${selectedOption !=="CUSTOM" ?"" : `<br/>${dayjs(dateFilter?.from_time).format('DD/MM/YYYY')==="Invalid Date"  || dayjs(dateFilter?.to_time).format('DD/MM/YYYY')==="Invalid Date" ?"": `${dayjs(dateFilter?.from_time).format('DD/MM/YYYY')} - ${dayjs(dateFilter?.to_time).format('DD/MM/YYYY')}`}`}`
        }` }}
        sx={{ ...titleCss, fontSize: "20px", marginLeft: "26px" }}>
          
        </Typography>
      </Stack>
    </Stack>
  );
};
const DownloadHeader = ({ svgIcon, text, textCss }) => {
  const containerStyle = {
    display: "flex",
    alignItems: "center",
    gap: "25px",
    mb: "25px",
  };

  return (
    <Box sx={containerStyle}>
      <Box
        display={"flex"}
        alignItems={"center"}
        justifyContent={"center"}
        sx={{
          background: "rgba(0, 0, 0, 0.07)",
          height: "47px",
          width: "46px",
          borderRadius: "50px",
          gap: "15px",
        }}
      >
        <Avatar
          sx={{
            width: "24px",
            height: "24px",
          }}
          src={svgIcon}
        ></Avatar>
      </Box>

      <Typography sx={{ ...textCss, fontSize: "20px" }}>{text}</Typography>
    </Box>
  );
};

const DriverAnalytics = () => {
  const [driverData, setDriverData] = useState({});
  const [loader, setLoader] = useState(false);

  const [selectedOption, setSelectedOption] = useState("TODAY");

  const [dateFilter, setDateFilter] = useState(initialDateFilter);

  const topDriverCardData = {
    // total_earning: {
    //   label: "Total Earnings",
    //   svgIcon: steeringWheelIcon,
    // },
    // avg_earning: {
    //   label: "Average Earnings",
    //   svgIcon: calenderIcon,
    // },

    drivers_onboarded: {
      label: `${capitalizeFirstLetter(dateFilter.select)} Activated Drivers`,
      svgIcon: steeringWheelIcon,
    },
    due_appointment: {
      label: "Due Appointments",
      svgIcon: calenderIcon,
    },
  };

  const analyticsData = {
    avg_on_duty_time_in_min: {
      label: "Avg On-Duty Time",
      svgIcon: carIcon,
      value: driverData?.avg_on_duty_time_in_min ?? 0,
    },
    avg_ride_acceptance_time_in_min: {
      label: "Avg Ride Acceptance time",
      svgIcon: historyIcon,
      value: driverData?.avg_ride_acceptance_time_in_min ?? 0,
    },
    avg_ride_acceptance_rate_in_percentage: {
      label: "Avg Ride Acceptance Rate",
      svgIcon: checkIcon,
      value: driverData?.avg_ride_acceptance_rate_in_percentage ?? 0,
    },
  };

  const downloadAnalyticsData = {};

  const handleDateFilter = (name, value) => {
    if (name === "select" && value !== "CUSTOM") {
      setDateFilter((prev) => ({
        ...prev,
        [name]: value,
        from_time: null,
        to_time: null,
      }));

      return;
    }

    setDateFilter((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  //css
  const containerStyle = {
    display: "flex",
    flexDirection: "column",
    boxSizing: "border-box",
    flexWrap: "wrap",
    gap: "15px",
    position: "relative",
  };

  const flexContainer = {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    boxSizing: "border-box",
    gap: "35px",
  };

  const flexChildContainer = {
    width: "49%",
    mt: "20px",
    boxSizing: "border-box",
  };

  const SharedCardConatiner = {
    borderRadius: "12px",
    border: "1px solid #E3E7EF",
    padding: "10px",
    boxSizing: "border-box",
    width: "100%",
    height: "65px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "Center",
  };

  const SharedCardTitleStyle = {
    color: "rgba(0, 0, 0, 0.70)",
    fontFamily: "Proxima Nova",
    fontSize: { xs: "16px", lg: "20px" },
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "normal",
  };

  const downloadCardCss = {
    width: "45%",
    padding: "36px 33px",
    boxSizing: "border-box",
    background: "rgba(0, 0, 0, 0.04)",
    borderRadius: "12px",
    justifyContent: "center",
    alignItems: "center",
  };

  const downloadCardText = {
    color: "rgba(0, 0, 0, 0.70)",
    fontFamily: "Proxima Nova",
    fontSize: 20,
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "175%",
    textAlign: "center",
    mt: "5px",
  };

  const downloadCardValue = {
    color: "#000",
    fontFamily: "Proxima Nova",
    fontSize: "34px",
    fontStyle: "normal",
    fontWeight: 500,
    textAlign: "center",
    lineHeight: "130%",
  };

  const ridesInfo = {
    rides_completed: {
      label: "Trips Completed By Driver",
      value: driverData?.rides_completed ?? 0,
    },
    distance_travelled: {
      label: "Distance Travelled By Driver",
      value: driverData?.distance_travelled ?? 0,
    },
  };

  const getSummary = async () => {
    const queryBuilder = () => {
      const querys = [];

      const format = "DD-MM-YYYY";

      querys.push(`special=DRIVER`);

      if (dateFilter?.select && dateFilter !== "CUSTOM") {
        querys.push(`range=${dateFilter?.select}`);
      }

      if (
        dateFilter?.select === "CUSTOM" &&
        dateFilter?.from_time &&
        dateFilter?.to_time
      ) {
        const custom_start_date = formatDateWithDayJs(
          dateFilter?.from_time,
          format
        );

        const custom_end_date = formatDateWithDayJs(
          dateFilter?.to_time,
          format
        );

        querys.push(`range=${dateFilter?.select}`);
        querys.push(`custom_start_date=${custom_start_date}`);
        querys.push(`custom_end_date=${custom_end_date}`);
      }

      return querys.length > 0 ? `?${querys.join("&")}` : "";
    };

    const querys = queryBuilder();

    let url = `/crm/v3/summary/${querys}`;
    setLoader(true);
    try {
      let response = await axiosInstance.get(url);

      if (response) {
        let driverSummary = response?.data?.data?.driver_summary ?? null;
        setDriverData(driverSummary);
      }
    } catch (error) {
      showErrorToast(error);
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    // calling summary funtion
    if (dateFilter?.select !== "CUSTOM") {
      getSummary(dateFilter?.select);
    } else if (dateFilter?.from_time && dateFilter?.to_time) {
      getSummary(dateFilter?.select);
    }
  }, [dateFilter]);
  return loader ? (
    <CircularLoader />
  ) : (
    <Box sx={containerStyle}>
      {/* <CustomSelect
        state={selectedOption}
        setState={setSelectedOption}
        options={SelectOptions}
        top={"-40px"}
      /> */}

      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          margin: "14px 0",
          alignContent: "flex-end",
        }}
      >
        <CommonDateFilterPanel
          dateFilter={dateFilter}
          filterSelectOptions={SelectOptions}
          handleDateFilter={handleDateFilter}
        />
      </Box>

      <Box sx={{ ...flexContainer }}>
        <Box sx={{ ...flexChildContainer, width: "100%" }}>
          <Stack direction={{ xs: "column", lg: "row" }} gap={"20px"}>
            <AnalyticsCard
              headerText={`${topDriverCardData.drivers_onboarded.label} ${dateFilter?.select !=="CUSTOM" ?"" : `<br/>${dayjs(dateFilter?.from_time).format('DD/MM/YYYY')==="Invalid Date"  || dayjs(dateFilter?.to_time).format('DD/MM/YYYY')==="Invalid Date" ?"": `${dayjs(dateFilter?.from_time).format('DD/MM/YYYY')} - ${dayjs(dateFilter?.to_time).format('DD/MM/YYYY')}`}`}` }
              svg={topDriverCardData.drivers_onboarded.svgIcon}
              value={driverData?.drivers_onboarded ?? 0}
            />
            {/* <AnalyticsCard
              headerText={topDriverCardData.due_appointment.label}
              svg={topDriverCardData.due_appointment.svgIcon}
              value={driverData?.due_appointment ?? 0}
            /> */}

            <RidesCompletedInfoCard
              options={ridesInfo}
              titleCss={SharedCardTitleStyle}
              selectedOption={dateFilter.select}
              dateFilter={dateFilter}
            />
          </Stack>
        </Box>
      </Box>

      <Box sx={{ ...flexContainer, paddingBottom: "25px" }}>
        {/* <Box
          sx={{
            ...flexChildContainer,
            backgroundColor: "#fff",
            padding: "25px",
            borderRadius: "12px",
            boxShadow: "0px 0px 8px 0px rgba(150, 150, 150, 0.10)",
            height: "430px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              justifyContent: "center",
              height: "100%",
            }}
          >
            <Stack
              width={"100%"}
              height={"100%"}
              justifyContent={"space-evenly"}
            >
              {Object.values(analyticsData).map((res, index) =>
                typeof res.value === "number" ? (
                  <SharedCard
                    conatinerStyleAlt={SharedCardConatiner}
                    key={index}
                    title={res.label}
                    svgicon={res.svgIcon}
                    value={res.value}
                    titleStyleAlt={SharedCardTitleStyle}
                  />
                ) : (
                  false
                )
              )}
            </Stack>
          </Box>
        </Box> */}
        {/* <Box
          sx={{
            ...flexChildContainer,
            height: "430px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            boxSizing: "border-box",
          }}
        >
          <Box
            sx={{
              height: "285px",
              background: "#fff",
              borderRadius: "12px",
              boxShadow: "0px 0px 8px 0px rgba(150, 150, 150, 0.10)",
              boxSizing: "border-box",
              padding: "25px 22px",
            }}
          >
            <DownloadHeader
              svgIcon={downloadIcon}
              text={"Downloads"}
              textCss={SharedCardTitleStyle}
            />

            <Stack
              width={"100%"}
              direction={"row"}
              justifyContent={"space-between"}
            >
              <Stack sx={downloadCardCss}>
                <Box>
                  <Stack direction={"row"} alignItems={"center"}>
                    <Typography sx={downloadCardValue}>{`${formatNumberShort(
                      driverData?.downloads?.new ?? 0
                    )}`}</Typography>{" "}
                    <NorthIcon
                      sx={{
                        fill: "#009D0F",
                      }}
                    />
                  </Stack>
                  <Typography sx={downloadCardText}>New</Typography>
                </Box>
              </Stack>
              <Stack sx={downloadCardCss}>
                <Box>
                  <Typography sx={downloadCardValue}>{`${formatNumberShort(
                    driverData?.downloads?.total ?? 0
                  )}`}</Typography>
                  <Typography sx={downloadCardText}>Total</Typography>
                </Box>
              </Stack>
            </Stack>
          </Box>
        </Box> */}
      </Box>
    </Box>
  );
};

export default DriverAnalytics;
