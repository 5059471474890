import { Typography } from "@mui/material";
import React from "react";

const BoxHeader = ({ title }) => {
  return (
    <Typography gutterBottom variant="h6" component="div" mt="0.5rem">
      {title}
    </Typography>
  );
};

export default BoxHeader;
