import { Box } from "@mui/material";
import React from "react";

const FilterWrapper = ({ children, addCss = {} }) => {
  const filterBoxStyle = {
    boxSizing: "border-box",
    backgroundColor: "#fff",
    width: "405px",
    display: "flex",
    paddingTop: "0px",
    flexDirection: "column",
    borderRadius: "5px",
    boxShadow: "0px 5px 13px 0px rgba(0, 0, 0, 0.08)",
    overflowX: "auto",
    scrollbarWidth: "thin",
    overflowX: "auto",
  };
  return <Box sx={{ ...filterBoxStyle, ...addCss }}>{children}</Box>;
};

export default FilterWrapper;
